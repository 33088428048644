import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import newUser from "../../../components/assets/newUser.svg";
import totalProject from "../../../components/assets/totalProject.svg";
import { } from "../../../components/utils";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import { getRequest } from "../../../components/axiosClient";
import { GET_DASHBOARD_COUNT } from "../../../components/utils";
import { useNavigate } from "react-router-dom";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";

const showDetailCard = (
  { valueScans, images__state, className__text, cardValue },
  index
) => {
  return (
    cardValue != null ? (
      <div className="main" key={index}>
        <div className="py-4 px-3 admin__received__report__first">
          <div className="grid grid-cols-3">
            <div className="col-span-2">
              <h6 className="font-medium">{valueScans}</h6>
              <span className="admin__dashboard__small text-[28px] font-semibold">{cardValue}</span>
            </div>
            <div className="col-span-1 flex justify-content-center">
              <div className={className__text}>
                <img
                  className="admin__imageOne__style"
                  src={images__state}
                  alt="download_image"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
};

const AdminDashboard = () => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [keysArray, setKeysArray] = useState([]);
  useEffect(() => {
    const keys = Object.keys(cardData);
    setKeysArray(keys);
  }, [cardData]);

  const getAllDashboardCard = async () => {
    setLoading(true);
    try {
      const res = await getRequest(GET_DASHBOARD_COUNT, null, navigate);
      setCardData(res.data.data);
      setLoading(false);
    } catch (error) { }
  };

  useEffect(() => {
    getAllDashboardCard();
  }, []);

  const isKeyInArray = (key) => {
    return keysArray.includes(key);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="admin__main__dash h-full p-4">
        <div className="h-full overflow-y-auto">
          <div className="h-full mr-1">
            {
              cardData?.length === 0 ?
                <div className="no__transcation">
                  <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div> :
                <>
                  {
                    isKeyInArray("project_list") && <>

                      <h5 className=" admin__header__dashboard  text-[#292929] text-[20px] mb-2 font-[500]">
                        Project related stats
                      </h5>
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3 ">
                        {[
                          {
                            valueScans: "Total Projects",
                            images__state: totalProject,
                            cardValue: cardData?.project_list?.total_project,
                            icon__style: "admin__iconOne__style ",
                            className__text:
                              "admin__image__roundOne  d-flex justify-content-center align-items-center   ",
                          },

                          {
                            valueScans: "Active Projects",
                            images__state: totalProject,
                            cardValue: cardData?.project_list?.running_project,
                            icon__style: "admin__iconOne__style ",
                            className__text:
                              "admin__image__roundOne  d-flex justify-content-center align-items-center   ",
                          },
                          {
                            valueScans: "Complete Projects",
                            images__state: totalProject,
                            cardValue: cardData?.project_list?.complete_project,
                            icon__style: "admin__iconOne__style ",
                            className__text:
                              "admin__image__roundOne  d-flex justify-content-center align-items-center   ",
                          },
                          {
                            valueScans: "Overdue Projects",
                            images__state: totalProject,
                            cardValue: cardData?.project_list?.over_due_project,
                            icon__style: "admin__iconOne__style ",
                            className__text:
                              "admin__image__roundOne  d-flex justify-content-center align-items-center   ",
                          }
                        ].map((value, index) => showDetailCard(value, index))}
                      </div>
                    </>
                  }

                  {
                    isKeyInArray("employee_list") && <>

                      <h5 className=" admin__header__dashboard text-[#292929]  text-[20px] mb-2 font-[500]">
                        Employee related stats
                      </h5>
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
                        {[
                          {
                            valueScans: "Total Employee",
                            images__state: newUser,
                            cardValue: cardData?.employee_list?.total_employee,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }, {
                            valueScans: "Active Employee",
                            images__state: newUser,
                            cardValue: cardData?.employee_list?.active_employee,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }, {
                            valueScans: "InActive Employee",
                            images__state: newUser,
                            cardValue: cardData?.employee_list?.inactive_employee,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }, {
                            valueScans: "On Notice Employee",
                            images__state: newUser,
                            cardValue: cardData?.employee_list?.on_termination_employee,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }
                        ].map((value, index) => showDetailCard(value, index))}
                      </div>

                    </>
                  }
                  {
                    isKeyInArray("client_list") && <>
                      <h5 className=" admin__header__dashboard text-[#292929]  text-[20px] mb-2 font-[500]">
                        Client related stats
                      </h5>
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
                        {[
                          {
                            valueScans: "Total Client",
                            images__state: newUser,
                            cardValue: cardData?.client_list?.total_client,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          },
                        ].map((value, index) => showDetailCard(value, index))}
                      </div>
                    </>
                  }
                  {
                    isKeyInArray("project_lead_list") && <>
                      <h5 className=" admin__header__dashboard text-[#292929]  text-[20px] mb-2 font-[500]">
                        Project Lead related stats
                      </h5>
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
                        {[
                          {
                            valueScans: "Total Projects Lead",
                            images__state: newUser,
                            cardValue: cardData?.project_lead_list?.total_project_lead,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          },
                          {
                            valueScans: "Open Project Lead",
                            images__state: newUser,
                            cardValue: cardData?.project_lead_list?.open_project_lead,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          },
                          {
                            valueScans: "Confirm Sale Project Lead",
                            images__state: newUser,
                            cardValue: cardData?.project_lead_list?.confirm_sale_project_lead,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          },
                          {
                            valueScans: "Closed Project Lead",
                            images__state: newUser,
                            cardValue: cardData?.project_lead_list?.closed_project_lead,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }
                        ].map((value, index) => showDetailCard(value, index))}
                      </div>
                    </>
                  }
                  {
                    isKeyInArray("candidate_lead_list") && <>
                      <h5 className=" admin__header__dashboard text-[#292929]  text-[20px] mb-2 font-[500]">
                        Candidate Lead related stats
                      </h5>
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
                        {[
                          {
                            valueScans: "Total Candidate Lead",
                            images__state: newUser,
                            cardValue: cardData?.candidate_lead_list?.total_candidate_lead,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }, {
                            valueScans: "Open Candidates Lead",
                            images__state: newUser,
                            cardValue: cardData?.candidate_lead_list?.open_candidate_lead,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }, {
                            valueScans: "Todays Interviews",
                            images__state: newUser,
                            cardValue: cardData?.candidate_lead_list?.todays_interview,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }, {
                            valueScans: "Upcoming Interviews",
                            images__state: newUser,
                            cardValue: cardData?.candidate_lead_list?.upcoming_interview,
                            className__text:
                              "admin__image__roundOne d-flex justify-content-center align-items-center",
                          }
                        ].map((value, index) => showDetailCard(value, index))}
                      </div>
                    </>
                  }

                </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
