import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CloseEye } from "../../../../src/components/assets/eye-icon-close.svg";
import { ReactComponent as OpenEye } from "../../../../src/components/assets/eye-icon-open.svg";
import { boundaryContext } from "../../../App";
import { getRequest, postRequest } from '../../../components/axiosClient';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { GET_EMPLOYEE_NAME, GET_MY_TEAM_PENDING_REQUEST_COUNT, REVIEW_VERIFY_PASSWORD } from '../../../components/utils';
import Modal from '../Modal/Modal';
import MyTeamDailyUpdate from './MyTeamDailyUpdate';
import MyTeamProjects from './MyTeamProjects';
import MyTeamRating from './MyTeamRating';
import MyTeamTask from './MyTeamTask';
import MyTeamTaskRequest from './MyTeamTaskRequest';

function MyTeamDetail() {

    const { setMyTeamEmployeeName, myTeamActiveRoute, setMyTeamActiveRoute } = useContext(boundaryContext);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState((myTeamActiveRoute && myTeamActiveRoute !== 'rating') ? myTeamActiveRoute : 'time-sheet');
    const { id } = useParams();
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [previousTab, setPreviousTab] = useState(activeTab);
    const [count, setCount] = useState();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const goBack = () => {
        navigate(-1);
    };

    const handleTabChange = (e) => {
        const selectedTab = e.target.name;

        if (selectedTab === 'rating' && !passwordVerified) {
            // Store the current active tab before switching to "Rating"
            setPreviousTab(activeTab);
            setShowPasswordModal(true);  // Show the modal when 'Rating' is clicked
            setPassword('');
        } else {
            setPassword('');
            setActiveTab(selectedTab);
            setPasswordVerified(false);
            setMyTeamActiveRoute(selectedTab);
        }
    };

    const handlePasswordSubmit = async () => {
        if (password) {
            await checkPassword(password);
        } else {
            errorNotification("Enter password please");
        }
    };

    const checkPassword = async (password) => {
        try {
            const payload = {
                password: password
            };
            const res = await postRequest(REVIEW_VERIFY_PASSWORD, payload, navigate);
            if (res?.type === 1) {
                setPasswordVerified(true);
                setActiveTab('rating');
                setMyTeamActiveRoute('rating');
                setShowPasswordModal(false);
                setPassword('');
            } else {
                errorNotification(res.errormessage);
            }
        } catch {
            errorNotification("Invalid password");
        }
    }

    const handleModalClose = () => {
        setPassword('');
        // Close the modal and revert back to the previous tab
        setShowPasswordModal(false);
        setActiveTab(previousTab);
        setMyTeamActiveRoute(previousTab);
    };


    const getEmployeeName = async () => {
        try {
            let url = `${GET_EMPLOYEE_NAME}?user_id=${id}`
            const res = await getRequest(url, null, navigate);
            if (res && res.data) {
                let name = res.data.data.first_name + ' ' + res.data.data.last_name;
                setMyTeamEmployeeName(name);
            }

        } catch (error) {
            errorNotification(error);
        }
    };
    const getRequestCount = async () => {
        try {
            let url = `${GET_MY_TEAM_PENDING_REQUEST_COUNT}?user_id=${id}`
            const res = await getRequest(url, null, navigate);
            if (res && res.data) {
                setCount(res.data.data.pendingRequest);
            }

        } catch (error) {
            errorNotification(error);
        }
    };

    useEffect(() => {
        getEmployeeName();
        getRequestCount();
    }, [])

    return (
        <div className='flex flex-col bg-white p-2 rounded-xl h-full box-border'>
            <div className="go__back__btn flex flex-row justify-start items-center my-2 gap-1 pl-4">
                <div className="">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer !w-8" />
                </div>
                <div className='grow px-4'>
                    <ul className="nav nav-pills mb-3 mt-2 border-b-2 flex flex-row" id="pills-tab" role="tablist">
                        <li className="nav-item mb-[-2px]" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "time-sheet" ? "active" : ""}`}
                                id="pills-time-sheet-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-time-sheet"
                                type="button"
                                role="tab"
                                aria-controls="pills-time-sheet"
                                aria-selected="true"
                                onClick={(e) => handleTabChange(e)}
                                name="time-sheet"
                            >
                                Time Sheet
                            </button>
                        </li>
                        <li className="nav-item mb-[-2px]" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "projects" ? "active" : ""}`}
                                id="pills-projects-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-projects"
                                type="button"
                                role="tab"
                                aria-controls="pills-projects"
                                aria-selected="false"
                                name="projects"
                                onClick={(e) => handleTabChange(e)}
                            >
                                Projects
                            </button>
                        </li>
                        <li className="nav-item mb-[-2px]" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "task" ? "active" : ""}`}
                                id="pills-task-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-task"
                                type="button"
                                role="tab"
                                aria-controls="pills-task"
                                aria-selected="false"
                                name="task"
                                onClick={(e) => handleTabChange(e)}
                            >
                                Tasks
                            </button>
                        </li>
                        <li className="nav-item mb-[-2px]" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "task-request" ? "active" : ""}`}
                                id="pills-task-request-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-task-request"
                                type="button"
                                role="tab"
                                aria-controls="pills-task-request"
                                aria-selected="false"
                                name="task-request"
                                onClick={(e) => handleTabChange(e)}
                            >
                                Task Request
                                {
                                    count > 0 &&
                                    <span className="bg-red-500 inline-block text-white rounded-full h-4 w-4 text-center text-xs ml-1">{count}</span>
                                }
                            </button>
                        </li>
                        <li className="nav-item mb-[-2px]" role="presentation">
                            <button
                                className={`nav-link ${(activeTab === "rating" && passwordVerified) ? "active" : ""}`}
                                id="pills-rating-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-rating"
                                type="button"
                                role="tab"
                                aria-controls="pills-rating"
                                aria-selected="false"
                                name="rating"
                                onClick={(e) => handleTabChange(e)}
                            >
                                Rating
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="program_scroll grow overflow-y-auto">
                <div className="h-full tab-content" id="pills-tabContent">
                    <div
                        className={`h-full tab-pane fade ${activeTab === "time-sheet" ? "show active" : ""
                            }`}
                        id="pills-time-sheet"
                        role="tabpanel"
                        aria-labelledby="pills-time-sheet-tab"
                    >
                        {activeTab === "time-sheet" && <MyTeamDailyUpdate />}
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "projects" ? "show active" : ""
                            }`}
                        id="pills-projects"
                        role="tabpanel"
                        aria-labelledby="pills-projects-tab"
                    >
                        {activeTab === "projects" && <MyTeamProjects />}
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "task" ? "show active" : ""
                            }`}
                        id="pills-task"
                        role="tabpanel"
                        aria-labelledby="pills-task-tab"
                    >
                        {activeTab === "task" && <MyTeamTask />}
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "task-request" ? "show active" : ""
                            }`}
                        id="pills-task-request"
                        role="tabpanel"
                        aria-labelledby="pills-task-request-tab"
                    >
                        {activeTab === "task-request" && <MyTeamTaskRequest setCount={setCount} />}
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "rating" ? "show active" : ""
                            }`}
                        id="pills-rating"
                        role="tabpanel"
                        aria-labelledby="pills-rating-tab"
                    >
                        {activeTab === "rating" && passwordVerified && <MyTeamRating />}
                    </div>
                </div>
            </div>
            <Modal
                className="password-modal"
                key="project"
                title={"Verify Your self"}
                onClose={handleModalClose}
                show={showPasswordModal}
            >
                <div className='p-2'>
                    <div className='mb-4'>
                        <h6 className="mb-2 ml-1">Password</h6>
                        <input
                            placeholder="Enter your password"
                            className="h-10 w-full rounded-lg border p-[6px] relative"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            onClick={() => { setShowPassword(!showPassword) }}
                            className="absolute right-8 top-[calc(50%_-_18px)] transform -translate-y-1/2 h-[18px] w-[18px] cursor-pointer"
                        >
                            {!showPassword ? (
                                <CloseEye />
                            ) : (
                                <OpenEye />
                            )}
                        </span>
                    </div>
                    <div className="text-right">
                        <button className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                            onClick={handlePasswordSubmit}
                        >
                            Verify
                        </button>
                    </div>
                </div>
            </Modal>
            <style>
                {
                    `
                        .password-modal .modal-content{
                            width:450px;
                        }
                    `
                }
            </style>
        </div>
    )
}

export default MyTeamDetail