import React, { useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import Vector from "../../../components/assets/Vector.png";
import Group from "../../../components/assets/Group.png";
import wordicon from "../../../components/assets/wordicon.svg";
import PdfIcon from "../../../components/assets/PdfIcon.svg";
import NewModal from "../../../views/admin/Modal/NewModal";
import Excel from "../../../components/assets/Excel.jpg";
import Zip from "../../../components/assets/Zip.png";
import Docx from "../../../components/assets/Docx.png";
import Csv from "../../../components/assets/Csv.png";
import trash from "../../../components/assets/trash.svg";
import dayjs from "dayjs";
import {
  GET_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT,
  UPLOAD_PROJECT_DOCUMENT,
} from "../../../components/utils";
import { useNavigate } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import { deleteRequest, getRequest } from "../../../components/axiosClient";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const icons = {
  word: wordicon,
  pdf: PdfIcon,
  xlsx: Excel,
  zip: Zip,
  docx: Docx,
  csv: Csv,
};

export const Documents = ({ projectId }) => {
  const fileInputRef = useRef(null);
  const [documentName, setDocumentName] = useState("");
  const [listShow, setListShow] = useState(false);
  const [document, setDocument] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);

  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";
  const navigate = useNavigate();

  // for Get ProjectDocument API Call
  const GetProjectDocument = async () => {
    try {
      const res = await getRequest(
        `${GET_PROJECT_DOCUMENT}?project_id=${projectId}`,
        null,
        navigate
      );
      if (res.type === 2) {
        errorNotification(res.errormessage);
        navigate("/404");
      } else {

        const documentList = res.data.data.page_data.map((item) => {
          const type = item.document.split(".").pop();
          return { type: type, ...item };
        });
        setDocument(documentList);
      }

    } catch (error) {
      errorNotification(error);
    }
  };

  useEffect(() => {
    GetProjectDocument();
  }, [projectId]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    setDocumentName(files[0].name);
    setSelectedNames(files[0]);
  };

  // Handle add button click
  const handleAdd = () => {
    setDocumentName("");
    setListShow(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  // for Add Document API Call
  const AddDocument = async () => {
    try {
      const FormData = require("form-data");
      const payload = new FormData();
      payload.append("project_id", projectId);
      payload.append("name", documentName);
      payload.append("document", selectedNames);

      const config = {
        method: "post",
        url: `${UPLOAD_PROJECT_DOCUMENT}`,
        headers: {
          Authorization: secureLocalStorage.getItem("token"),
        },
        data: payload,
      };
      axios(config)
        .then((res) => {
          if (res.data.success) {
            successNotification(res.data.message);
            GetProjectDocument();
            setDocumentName("");
            setListShow(false);
          }
        })
        .catch((err) => {
          errorNotification(err.response.data.message);
        });
    } catch (error) {
      errorNotification("An error occurred while adding the document.");
    }
  };

  // for Delete Document API Call
  const confirmDelete = async () => {
    try {
      const res = await deleteRequest(
        `${DELETE_PROJECT_DOCUMENT}?project_id=${projectId}&document_id=${docToDelete.id}`,
        null,
        navigate
      );
      if (res.type === 1) {
        setDocument((prevDocs) =>
          prevDocs.filter((doc) => doc.id !== docToDelete.id)
        );
        successNotification(res.response.data.message);
        setShowModal(false);
        setDocToDelete(null);
      } else if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const handleDeleteClick = (doc) => {
    setDocToDelete(doc);
    setShowModal(true);
  };

  return (
    <div className="hunter_audit_dashboard">
      <div>
        {role_accesses &&
          <button className="client__save__btn mx-3 " onClick={handleAdd}>
            + Add Document
          </button>
        }
        <div className="flex-wrap w-full gap-2 mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
          {document.map((doc, index) => (
            <div
              key={index}
              className="border w-full border-gray-300 rounded-lg p-1 text-center bg-[#FAFAFA]"
            >
              <div className="p-2 bg-white flex flex-col justify-center rounded-lg items-end relative h-[196px]">
                <div className="flex justify-end p-[6px] rounded-md w-fit bg-[#EFEFEF] absolute top-2 right-2">
                  <a href={doc.document} download target="_blank">
                    <img
                      src={Group}
                      className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                      alt="group-icon"
                    />
                  </a>
                </div>
                <div className="mb-2 w-full">
                  <img
                    src={
                      icons[doc.type] ||
                      doc.document ||
                      "https://upload.wikimedia.org/wikipedia/commons/2/2d/File.svg"
                    }
                    alt={doc.type}
                    className="w-12 h-12 mx-auto"
                  />
                </div>
              </div>

              <div className="flex justify-between mt-4 w-full px-2">
                <div className="mb-2 w-[90%]">
                  <p className="text-[16px] text-start leading-4 font-semibold text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full mb-1">
                    {doc.name}
                  </p>
                  <div className="text-[12px] text-[#6F767E] text-start">
                    {dayjs(doc.createdAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                {
                  role_accesses &&
                  <img
                    src={trash}
                    className="text-gray-700 cursor-pointer hover:text-red-500 w-[22px] h-[22px]"
                    alt="trash-icon"
                    onClick={() => handleDeleteClick(doc)}
                  />
                }
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        className="client-modal"
        title="Add Document"
        onClose={() => {
          setListShow(false);
        }}
        show={listShow}
      >
        <div className="modal-body add__client__form">
          <div className="">
            <div
              className="flex items-center justify-center w-full"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img src={Vector} alt="vector-icon" />
                  <p className="text-sm text-gray-500 my-3">
                    Drag and Drop your Document
                  </p>
                  <p className="cursor-pointer bg-[#f47e53] text-white py-2 px-4 rounded-md">
                    Choose File
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  multiple={false}
                />
              </label>
            </div>
          </div>
          <div className="mt-3">
            <h6 className="modal-heading">Document Name</h6>
            <input
              placeholder="Document Name"
              className="client__name text-capitalize"
              type="text"
              autoComplete="off"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
          </div>

          <div className="add__close__section mt-3">
            <button
              type="button"
              className="client__save__btn w-100"
              onClick={AddDocument}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <NewModal
        title="Are you Sure?"
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <div className="modal-body add__cmp__form">
          <h6>Do you really want to delete this document?</h6>
          <div className="add__close__section">
            <button
              type="button"
              className="bg-[#F5F5F5] text-[#5D5D5D] w-[fit-content] px-4 py-2 rounded-[12px] me-3"
              onClick={() => setShowModal(false)}
            >
              No
            </button>
            <button
              type="button"
              className="bg-[#f47e53] text-[#fff] border-[1px] flex items-center  w-[fit-content] px-3 py-2 rounded-[12px] whitespace-nowrap"
              onClick={confirmDelete}
            >
              Yes
            </button>
          </div>
        </div>
      </NewModal>
    </div>
  );
};
