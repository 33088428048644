import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRequest, postRequest } from '../../../components/axiosClient';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { GET_ISSUED_BOOK, RETURN_BOOK } from '../../../components/utils';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import dayjs from 'dayjs';

const IssuedBookList = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const [issuedBookList, setIssuedBookList] = useState([]);

  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState();
  const [pageInformation, setPageInformation] = useState([]);
  const pageSize = 30;
  const listRef = useRef(null);

  useEffect(() => {
    fetchIssuedBooks();
  }, [])

  const fetchIssuedBooks = async () => {
    try {
      setFirstLoading(true);
      let API_URL = `${GET_ISSUED_BOOK}?page=${1}&limit=${pageSize}`;
      const res = await getRequest(API_URL, null, navigate);
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setIssuedBookList(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
        setPage(2);
      } else {
        setHasMoreData(false);
        errorNotification(res.errormessage);
      }
      setFirstLoading(false);
    } catch (error) {
      errorNotification(error);
      setFirstLoading(false);
    }
  };


  const fetchData = useCallback(async () => {
    if (loading || (pageInformation?.current_page >= pageInformation?.last_page)) return;
    setLoading(true);
    try {
      let API_URL = `${GET_ISSUED_BOOK}?page=${page}&limit=${pageSize}`;

      const res = await getRequest(API_URL, null, navigate);
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        const newPageData = res.data.data.page_data;
        setIssuedBookList((preData) => [...preData, ...newPageData]);
        setPageInformation(res.data.data.page_information);
        setPage((prevPage) => prevPage + 1);
      }

      if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
        setHasMoreData(false);
      }

      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  }, [page, loading, hasMoreData]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          fetchData();
        }
      }
    };

    const divElement = listRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    } 

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchData, listRef]);

  const handleBookReturn = async (id) => {
    setFirstLoading(true);
    if (id === undefined || id === null) {
      errorNotification("Please provide a valid id");
      return;
    }

    try {
      const res = await postRequest(RETURN_BOOK + `?book_tran_id=${id}`, {}, navigate);
      if (res?.response?.data) {
        successNotification(res.response.data.message);
        fetchIssuedBooks();
      } else {
        errorNotification(res.errormessage);
      }
    } catch (e) {
      errorNotification(e.message);
      setFirstLoading(false);
    } finally {
      setFirstLoading(false);
    }
  }

  return firstLoading ? (
    <LoadingSpinner />
  ) : (
    <div className='h-full bg-white rounded-xl'>

      <div className='h-full'>
        {issuedBookList?.length === 0 ? (
          <div className="daily_updates_section bg-white ">
            <div className="no__transcation">
              <img src={noDataFound} alt={noDataFound} loading="lazy" />
            </div>
          </div>
        ) : (
          <div className='h-full flex flex-col justify-between overflow-x-auto' ref={listRef}>
            <table className="table max-w-full">
              <thead className="">
                <tr className="align-middle">
                  <th scope="col" className="!border-b-[#EFEFEF] font-medium ">
                    Borrowed By
                  </th>
                  <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                    Book Title
                  </th>
                  <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                    Borrowed Date
                  </th>
                  <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                    Return Date
                  </th>
                  <th scope="col" className="!border-b-[#EFEFEF] font-medium">

                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  issuedBookList?.map((item, index) => {
                    return (
                      <tr className={`align-middle border-b-0 overflow-hidden`}
                        key={index}
                      >
                        <td className="border-b-0 !border-b-[#EFEFEF] truncate">
                          {item?.user?.first_name && item?.user?.last_name
                            ? `${item.user.first_name} ${item.user.last_name}`
                            : "--"}
                        </td>

                        <td className="border-b-0 !border-b-[#EFEFEF] p-2 !max-w-[30vw] overflow-hidden">
                          <div className='flex items-center w-full gap-1'>
                            <div className="truncate">
                              {item?.book?.title || "--"}
                            </div>
                          </div>
                        </td>
                        <td className="border-b-0 !border-b-[#EFEFEF] truncate">
                          {item?.borrow_date ? dayjs(item?.borrow_date).format("DD-MM-YYYY") : "--"}
                        </td>
                        <td className="border-b-0 !border-b-[#EFEFEF]">
                          {item?.due_date ? (
                            <span
                              className={
                                dayjs(item?.due_date).isBefore(dayjs(), "day")
                                  ? "text-red-500"
                                  : ""
                              }
                            >
                              {dayjs(item?.due_date).format("DD-MM-YYYY")}
                            </span>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td className="border-b-0 !border-b-[#EFEFEF]">
                          <div className='flex gap-2 items-center justify-end'>
                            <button className='border-0 text-white rounded-md py-2 px-3.5 font-medium bg-[#f47e53] cursor-pointer'
                              onClick={() => {
                                handleBookReturn(item?.id);
                              }}
                            >
                              Return
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            {
              loading &&
              <div className='w-full my-2 flex items-center justify-center'>
                Loading...
              </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}

export default IssuedBookList