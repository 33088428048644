import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import OrgTree from "../../../components/organization/OrgTree";
import "./Organization.css";
import { getRequest } from "../../../components/axiosClient";
import { useNavigate } from "react-router-dom";
import { GET_ORGANIZATION_TREE } from "../../../components/utils";
import { errorNotification, successNotification } from "../../../components/toast-notification/common-toast";
import avatar from "../../../components/assets/avatar.svg";

function Organization() {
  const [loading, setLoading] = useState(false);
  const [ORGData, setORGData] = useState([]);
  const navigate = useNavigate();


  // const data = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     position: "CEO",
  //     parentId: null,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://img.freepik.com/free-photo/young-handsome-arab-man-wearing-blue-tshirt-standing-isolated-white-background-making-fish-face-with-lips-crazy-comical-gesture-funny-expression_839833-4462.jpg",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     position: "CTO",
  //     parentId: 1,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://www.pngitem.com/pimgs/m/130-1300380_female-user-image-icon-hd-png-download.png",
  //   },
  //   {
  //     id: 3,
  //     name: "Bob Johnson",
  //     position: "CFO",
  //     parentId: 1,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://i.pinimg.com/originals/83/7e/77/837e7792a9ce436f98295e992a93321a.png",
  //   },
  //   {
  //     id: 4,
  //     name: "Alice Williams",
  //     position: "Developer",
  //     parentId: 2,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg",
  //   },
  //   {
  //     id: 5,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 2,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659652_640.png",
  //   },
  //   {
  //     id: 6,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 3,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://p0.pxfuel.com/preview/276/301/198/avatar-people-person-business.jpg",
  //   },
  //   {
  //     id: 7,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 3,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl: "https://cdn-icons-png.flaticon.com/512/560/560175.png",
  //   },
  //   {
  //     id: 8,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 4,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUZr8J_NnQJMD6bl8-AdMIwE0eP_3jOmCv6xL59PRTuwllTH4uiiU-9h0YdR31H2c09jc&usqp=CAU",
  //   },
  //   {
  //     id: 9,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 3,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPrTBPxjgQtxbR_H3BQ_QhM0DVz9eaHSoVv-WGiklBOS2X4heHr1WqAawX2RTqv2J2SNI&usqp=CAU",
  //   },
  //   {
  //     id: 10,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 3,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPrTBPxjgQtxbR_H3BQ_QhM0DVz9eaHSoVv-WGiklBOS2X4heHr1WqAawX2RTqv2J2SNI&usqp=CAU",
  //   },
  //   {
  //     id: 11,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 3,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPrTBPxjgQtxbR_H3BQ_QhM0DVz9eaHSoVv-WGiklBOS2X4heHr1WqAawX2RTqv2J2SNI&usqp=CAU",
  //   },
  //   {
  //     id: 12,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 2,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPrTBPxjgQtxbR_H3BQ_QhM0DVz9eaHSoVv-WGiklBOS2X4heHr1WqAawX2RTqv2J2SNI&usqp=CAU",
  //   },
  //   {
  //     id: 13,
  //     name: "Charlie Brown",
  //     position: "Designer",
  //     parentId: 2,
  //     location: "Vadodara",
  //     department: "Development",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPrTBPxjgQtxbR_H3BQ_QhM0DVz9eaHSoVv-WGiklBOS2X4heHr1WqAawX2RTqv2J2SNI&usqp=CAU",
  //   },
  // ];

  const addOrganizationTreeData = async (payload) => {
    setLoading(true);
    try {
      const res = await getRequest(GET_ORGANIZATION_TREE, payload, navigate);
      if (res.type !== 2) {
        if (res?.data?.success) {
          const data = res.data?.data?.map((item, index) => {
            return {
              "id": item?.id,
              "employee_id": item?.employee_id,
              "name": `${item?.first_name + " " + item?.last_name}`,
              "position": item?.role?.name || null,
              "parentId": item?.reporting_person_id || null,
              "location": item?.branch?.branch_name || null,
              "department": item?.department?.department_name || null,
              "imageUrl": item?.profile_image || avatar,
              "isTerminated": item?.status === "terminated" ? true : false,
              "isInactive": item?.status === 'inactive' ? true : false
            };
          });

          setORGData(data);
        } else {
          errorNotification(res.response.data.message);
        }
      } else {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    addOrganizationTreeData();
  }, []);


  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="org-section h-full">
      <div className="org-subsection h-full flex flex-col overflow-y-hidden">
        <Tabs
          defaultActiveKey="organization-tree"
          id="profile-tabs"
          className="nav-pills program__manage mb-3"
          onSelect={(k) => console.log(k)}
        >
          <Tab
            eventKey="organization-tree"
            title="Organization tree"
            className="nav-link h-full"
          >
            {ORGData.length > 0 ? <OrgTree data={ORGData} /> : "NO DATA"}
          </Tab>
          {/* <Tab
            eventKey="employee-directory"
            title="Employee directory"
            className="nav-link"
          >
            Employee Directory
          </Tab> */}
        </Tabs>
      </div>
    </div>
  );
}

export default Organization;
