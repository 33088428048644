

import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import Inprogress from "../../../components/assets/highdash.svg";
import Remaining from "../../../components/assets/mediumdash.svg";
import Complete from "../../../components/assets/lowdash.svg";
import None from "../../../components/assets/nonedash.svg";
import "./Dashbord.css";
Chart.register(CategoryScale);

const pieOptions = {
    plugins: {
        legend: {
            display: false,
            position: "right"
        },
    },
    elements: {
        arc: {
            borderWidth: 0
        }
    },
    responsive: true,
    maintainAspectRatio: true,
};

function CompanyDashboardChart({ reportCountByCompany }) {
    let chartInstance = null;
    const [showLegend, setShowLegend] = useState(true);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 920 && showLegend) {
                setShowLegend(false);
            } else if (window.innerWidth >= 920 && showLegend === false) {
                setShowLegend(true);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [showLegend]);

    // for display chart value, color and legend images
    const data = [
        { name: "Complete", value: reportCountByCompany?.severityCount?.complete, color: 'bg-[#32AD60]', images: Complete },
        { name: "Remaining", value: reportCountByCompany?.severityCount?.remaining, color: 'bg-[#EA3323]', images: Remaining }
    ];

    //count data for chart
    const newData = {
        maintainAspectRatio: false,
        responsive: true,
        labels: ["Complete", "Remaining"],
        datasets: [
            {
                data: [reportCountByCompany?.severityCount?.complete, reportCountByCompany?.severityCount?.remaining],
                backgroundColor: ["#32AD60", "#EA3323"]
            }
        ]
    }

    // for show placeholder data on graph
    const placeholderData = {
        "labels": "",
        "datasets": [
            {
                "label": ["No Data"],
                "data": [100],
                "backgroundColor": "#F2EEE8"
            }
        ]
    }
    return (

        <div>
            <div className="charjsData">
                <div>
                    {newData.datasets[0].data[0] == '00' && newData.datasets[0].data[1] == '00' &&
                        newData.datasets[0].data[2] == '00' && newData.datasets[0].data[3] == '00' ?
                        <Pie
                            data={placeholderData}
                            options={pieOptions}
                            ref={input => {
                                chartInstance = input;
                            }}
                        /> :
                        <Pie
                            data={newData}
                            options={pieOptions}
                            ref={input => {
                                chartInstance = input;
                            }}
                        />
                    }
                </div>
                <div>
                    {data.map((item, index) => {
                        return <div className="chartLabels" key={index}><div className={`${item.color} h-[20px] w-[20px]`} /> <p>{item.name}</p></div>
                    })}
                </div>
            </div>
        </div>
    );
}
export default CompanyDashboardChart;
