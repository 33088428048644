import React, { useEffect, useState } from "react";
import Modal from "../../views/admin/Modal/Modal";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import bin from "../assets/bin.svg";
import "./AddUpdate.css";
import { ADD_DAILY_UPDATE, GET_PROJECT_DROPDOWN_LIST } from "../utils";
import {
  redirect,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "../toast-notification/common-toast";
import { getRequest, postRequest } from "../axiosClient";

function AddUpdate({ open, setOpen, getMyProfile, reload }) {
  const { pathname } = useResolvedPath();
  const [tasks, setTasks] = useState([{ task: "", hours: "" }]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleTaskChange = (index, event) => {
    const values = [...tasks];
    const { name, value } = event.target;

    if (name === "hours") {
      const formattedValue = formatTimeInput(value);
      values[index][name] = formattedValue;
      const isValid = /^([01]\d|2[0-3]):([0-5]\d)$/.test(formattedValue);
      const newErrors = [...errors];
      newErrors[index] = !isValid;
      setErrors(newErrors);
    } else {
      values[index][name] = value;
    }
    setTasks(values);
    adjustTextareaHeight(event.target);
  };

  const formatTimeInput = (value) => {
    const cleanedValue = value.replace(/[^0-9]/g, "");
    if (cleanedValue.length <= 2) {
      return cleanedValue;
    }
    if (cleanedValue.length <= 4) {
      return `${cleanedValue.slice(0, 2)}:${cleanedValue.slice(2)}`;
    }
    return `${cleanedValue.slice(0, 2)}:${cleanedValue.slice(2, 4)}`;
  };

  const handleAddTask = () => {
    setTasks([...tasks, { task: "", hours: "" }]);
    setErrors([...errors, false]);
  };

  const handleRemoveTask = (index) => {
    const values = [...tasks];
    values.splice(index, 1);
    setTasks(values);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedProject) {
      errorNotification("Please Select a Project");
      return;
    }

    for (let i = 0; i < tasks.length; i++) {
      if (!tasks[i].task.trim()) {
        errorNotification("Please Enter Task ");
        return;
      }

      const hours = tasks[i].hours.trim();
      if (!hours) {
        errorNotification("Please Enter Hours ");
        return;
      }

      if(hours === "00:00"){
        errorNotification("Task hours cannot be 00:00");
        return;
      }

      const isValidHours = /^([01]\d|2[0-3]):([0-5]\d)$/.test(hours);
      if (!isValidHours) {
        errorNotification("Please enter valid hours in HH:MM format");
        return;
      }
    }

    const payload = {
      project_id: selectedProject?.value,
      updates: tasks,
    };

    setLoading(true);

    try {
      const res = await postRequest(ADD_DAILY_UPDATE, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          reload();
          successNotification(res.response.data.message);
          resetForm();
          setOpen(false);
        } else {
          errorNotification(res.response.data.message);
        }
      } else if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getProjectOptions = async () => {
    try {
      const res = await getRequest(GET_PROJECT_DROPDOWN_LIST, null, navigate);
      if (res.data && Array.isArray(res.data.data)) {
        const options = res.data.data.map((project) => ({
          label: project.name,
          value: project.id,
        }));
        setClientOptions(options);
      } else {
        setClientOptions([]);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  const resetForm = () => {
    setTasks([{ task: "", hours: "" }]);
    setSelectedProject(null);
    setErrors([]);
  };

  useEffect(() => {
    if (open) {
      getProjectOptions();
    } else {
      resetForm();
    }
  }, [open]);

  return (
    <Modal
      id="add update"
      className="update-modal "
      key="project"
      title="Add Hour "
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      show={open}
    >
      <Form className="modal-body add__update__form " onSubmit={handleSubmit}>
        <h6 className="modal-heading ">Project</h6>
        <Select
          options={clientOptions}
          className="basic-single text-capitalize add_update"
          classNamePrefix="select"
          value={selectedProject}
          placeholder="Select Project"
          name="name"
          onChange={handleProjectChange}
        />

        {tasks.map((task, index) => (
          <Row key={index} className="my-3 row">
            <Col sm={9} md={10}>
              {index === 0 && (
                <Form.Label htmlFor="basic-url  text-left">Task</Form.Label>
              )}
              <textarea
                key={index}
                className="update__name "
                placeholder="Enter task"
                name="task"
                rows={1}
                value={task.task}
                onChange={(event) => handleTaskChange(index, event)}
                style={{ overflow: "hidden" }}
              />
            </Col>
            <Col sm={3} md={2} className={"d-flex  justify-between gap-3"}>
              <div className="w-[80px]">
                {index === 0 && (
                  <Form.Label htmlFor="basic-url">Hours</Form.Label>
                )}
                <input
                  type="text"
                  className={` task-input ${errors[index] ? "is-invalid" : ""}`}
                  placeholder="00:00"
                  name="hours"
                  value={task.hours}
                  onChange={(event) => handleTaskChange(index, event)}
                />
              </div>
              {index !== 0 && (
                <div className="d-flex align-items-center mb-2">
                  <span className="d-flex align-items-center">
                    <img
                      onClick={() => handleRemoveTask(index)}
                      className="w-[15px] h-[18px] object-cover cursor-pointer"
                      src={bin}
                      alt="Delete"
                    />
                  </span>
                </div>
              )}
            </Col>
          </Row>
        ))}

        <div className="mt-4 add-new-client d-flex justify-end  ">
          <span className="Add_Task" onClick={handleAddTask}>
            + Add Task
          </span>
        </div>

        <div className="add__close__section sticky bottom-0 pb-4 w-full bg-white ">
          <button type="submit" className="update__save__btn" disabled={loading}>
            Submit
          </button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddUpdate;
