import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import Timesheet from "../../../components/report/Timesheet";
import "./Report.css";
import Sales from "../../../components/report/Sales";

function Report() {
  const [loading, setLoading] = useState(false);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="report-section">
      <div className="report-subsection flex flex-col">
        <Tabs
          defaultActiveKey="timesheet"
          id="report-tabs"
          className="nav-pills program__manage mb-0 "
        >
          <Tab eventKey="timesheet" title="Timesheet" className="nav-link">
            <Timesheet />
          </Tab>
          <Tab eventKey="sales" title="Sales" className="nav-link">
            <Sales/>
          </Tab>
          <Tab
            eventKey="candidate-lead"
            title="Candidate lead"
            className="nav-link"
          >
            candidate lead
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Report;
