import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { CgDetailsMore } from "react-icons/cg";
import { TbListDetails } from "react-icons/tb";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import "./DailyUpdates.css";
import DailyUpdatesDetailView from "./DailyUpdatesDetailView";
import DailyUpdatesNormalView from "./DailyUpdatesNormalView";
import CommonDatePicker from "../../CommonDatePicker";

const DailyUpdates = () => {

  const [firstLoading, setFirstLoading] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [detailView, setDetailView] = useState(true);

  return firstLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="daily_updates_section flex flex-col h-full bg-white rounded-[12px] p-3">
      <div className="w-full flex justify-end items-center px-[14px] mb-3 gap-3">
        <Tooltip title={detailView ? 'Logs' : 'Timers'}>
          <button onClick={() => setDetailView(!detailView)} className='border-1 border-[#cccccc] py-[7px] px-2 rounded-md'>
            {detailView ? <TbListDetails /> : <CgDetailsMore />}
          </button>
        </Tooltip>
        <input
          type="date"
          placeholder="Select a date"
          name="filter"
          className="h-10 min-w-44 w-rounded-lg border rounded-lg p-2"
          value={filterDate}
          onChange={(e) => {
            setFilterDate(e.target.value);
          }}
        />
        {/* <div className="w-fit min-w-44">
        <CommonDatePicker
          value={filterDate}
          onChange={(value) => setFilterDate(value)}
        />
        </div> */}
      </div>
      <div className="grow overflow-y-auto">
        {
          detailView ?
            <DailyUpdatesDetailView filterDate={filterDate} />
            : <DailyUpdatesNormalView filterDate={filterDate} />
        }
      </div>
    </div>
  );
};

export default DailyUpdates;
