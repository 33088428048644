import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Modal from "../../views/admin/Modal/Modal";
import NewModal from "../../views/admin/Modal/NewModal";
import { deleteRequest, getRequest } from "../axiosClient";
import {
  errorNotification,
  successNotification,
} from "../toast-notification/common-toast";
import { ADD_DOCUMENT, DELETE_DOCUMENT, GET_DOCUMENT } from "../utils";

import { OverlayTrigger, Popover } from "react-bootstrap";
import Group from "../../components/assets/Group.png";
import trash from "../../components/assets/trash.svg";
import Vector from "../../components/assets/Vector.png";

export default function DocumentData({ categoryId }) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [listShow, setListShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [docToDelete, setDocToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const role_accesses =
    JSON.parse(secureLocalStorage.getItem("role_accesses")).find(
      (access) => access.module.name === "Documents"
    )?.access_type !== "Read";

  useEffect(() => {
    getProjectDocuments();
  }, [categoryId]);

  const getProjectDocuments = async () => {
    try {
      const res = await getRequest(
        `${GET_DOCUMENT}?category_id=${categoryId}`,
        null,
        navigate
      );
      if (res.type == 2) {
        errorNotification(res.errormessage);
        navigate("/404");
      } else {
        const documentList = res?.data?.data.map((item) => {
          const type = item?.file.split(".").pop();
          return { type: type, ...item };
        });
        setDocumentList(documentList);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  const handleAddDocumentModal = () => {
    setDocumentName("");
    setDocumentDescription("");
    setListShow(true);
  };

  const handleFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];
      setDocumentName(file.name);
      setSelectedFile(file);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleAddDocument = async () => {
    try {
      if (!selectedFile) {
        errorNotification("Please select a file to upload.");
        return;
      }
      if (!documentName) {
        errorNotification("The name field is required.");
        return;
      }

      setLoading(true);

      const FormData = require("form-data");
      const payload = new FormData();
      payload.append("category_id", categoryId);
      payload.append("name", documentName);
      payload.append("description", documentDescription);
      payload.append("document", selectedFile);

      const config = {
        method: "post",
        url: ADD_DOCUMENT,
        headers: {
          Authorization: secureLocalStorage.getItem("token"),
        },
        data: payload,
      };

      axios(config)
        .then((res) => {
          if (res?.data?.success) {
            successNotification(res?.data?.message);
            getProjectDocuments();
            setDocumentName("");
            setDocumentDescription("");
            setSelectedFile(null);
            setListShow(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          errorNotification(err?.response?.data?.message);
        });
    } catch (error) {
      errorNotification(
        error.response?.data?.message ||
          "An error occurred while uploading the document."
      );
    }
  };

  const confirmDelete = async () => {
    try {
      const res = await deleteRequest(
        `${DELETE_DOCUMENT}?document_id=${docToDelete?.id}`,
        null,
        navigate
      );

      if (res?.type === 1) {
        setDocumentList((prevDocs) =>
          prevDocs.filter((doc) => doc.id !== docToDelete?.id)
        );
        successNotification(res?.response.data.message);
        setShowModal(false);
        setDocToDelete(null);
      } else if (res?.type === 2) {
        errorNotification(res?.errormessage || res?.errors);
      }
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const handleDeleteClick = (doc) => {
    setDocToDelete(doc);
    setShowModal(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-end">
        {role_accesses && (
          <button
            className="add__category mb-1 me-3"
            onClick={handleAddDocumentModal}
          >
            Add Document
          </button>
        )}
      </div>
      {documentList.length > 0 ? (
        <div className="p-2 grow overflow-y-auto">
          <table className="table project__list__table table-responsive">
            <thead className="report__header__border">
              <tr className="border-style">
                <th scope="col">No</th>
                <th scope="col">Document Name</th>
                <th scope="col" className="align-left">
                  Description
                </th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody className="report__header__border ">
              {documentList?.map((doc, index) => (
                <tr key={index}>
                  <td className="align-middle w-[85px]">{index + 1}</td>
                  <td className=" align-middle text-capitalize">{doc?.name}</td>
                  <td className=" align-middle text-capitalize">
                    {doc?.description}
                  </td>

                  <td className=" align-middle !border-b-0 d-flex justify-content-end align-items-center">
                    <OverlayTrigger
                      placement="auto"
                      overlay={<Popover className="p-2">Download</Popover>}
                    >
                      <a
                        href={doc?.file}
                        className="p-[8px] cursor-pointer ms-1 w-[44px] h-[44px]"
                        target="blank"
                        download
                      >
                        <img
                          src={Group}
                          className="cursor-pointer w-[22px] h-[22px]"
                          alt="group-icon"
                        />
                      </a>
                    </OverlayTrigger>
                    {role_accesses && (
                      <OverlayTrigger
                        placement="auto"
                        overlay={<Popover className="p-2">Delete</Popover>}
                      >
                        <button
                          disabled={!role_accesses}
                          className="p-[8px] cursor-pointer ms-1 me-3 w-[44px] h-[44px]"
                          onClick={() => handleDeleteClick(doc)}
                        >
                          <img
                            src={trash}
                            className=" cursor-pointer w-[22px] h-[22px]"
                            alt="trash-icon"
                          />
                        </button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center align-items-center w-full grow">
          <p className="text-lg text-black">No such file or documents found</p>
        </div>
      )}

      <Modal
        className="client-modal"
        title="Add Document"
        onClose={() => {
          setListShow(false);
        }}
        show={listShow}
      >
        <div className="modal-body add__client__form">
          <div
            className="flex items-center justify-center w-full"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <img src={Vector} alt="vector-icon" />
                <p className="text-sm text-gray-500 my-3">
                  Drag and Drop your Document
                </p>
                <p className="cursor-pointer bg-[#f47e53] text-white py-2 px-4 rounded-md">
                  Choose File
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </label>
          </div>
          <div className="mt-3">
            <h6>Name</h6>
            <input
              placeholder="Enter name"
              className="client__name text-capitalize"
              type="text"
              autoComplete="off"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
          </div>

          <div className="mt-3">
            <h6>Description</h6>
            <input
              placeholder="Enter description"
              className="client__name text-capitalize"
              type="text-area"
              autoComplete="off"
              value={documentDescription}
              onChange={(e) => setDocumentDescription(e.target.value)}
            />
          </div>

          <div className="add__close__section mt-3">
            <button
              type="button"
              className="client__save__btn w-100"
              onClick={handleAddDocument}
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm  mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <NewModal
        title="Are you Sure?"
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <div className="modal-body add__cmp__form">
          <h6>Do you really want to delete this document?</h6>
          <div className="add__close__section">
            <button
              type="button"
              className="bg-[#F5F5F5] text-[#5D5D5D] w-[fit-content] px-4 py-2 rounded-[12px] me-3"
              onClick={() => setShowModal(false)}
            >
              No
            </button>
            <button
              type="button"
              className="bg-[#f47e53] text-[#fff] border-[1px] flex items-center  w-[fit-content] px-3 py-2 rounded-[12px] whitespace-nowrap"
              onClick={confirmDelete}
            >
              Yes
            </button>
          </div>
        </div>
      </NewModal>
    </div>
  );
}
