import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { patchRequest } from '../../../components/axiosClient';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { UPDATE_TASK, UPDATE_PROJECT_LEAD_INDEX, UPDATE_PROJECT_TASKS_INDEX } from '../../../components/utils';
import ProjectLeadCard from './ProjectLeadCard';

// import { Select, MenuItem } from '@mui/material'; // MUI for dropdown

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
  height : calc(100vh - 270px) !important;
  overflow-y: auto;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
`;
const ProjectLeadKanabanView = ({ projectLeadList, getLeads, handleView, handleLeadComplete, taskStatusList }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [projectLeadList, setTaskList] = useState([]);
    const [columns, setColumns] = useState({});

    useEffect(() => {
        getTaskList();
    }, [projectLeadList, taskStatusList]);

    const getTaskList = async () => {
            const columnsFromBackend = taskStatusList?.reduce((acc, item) => {
                acc[item.id] = {
                    title: item.title,
                    items: projectLeadList?.filter(task => task?.task_status?.title === item?.title) || []
                };
                return acc;
            }, {})
            setColumns(columnsFromBackend);
    };

    const updateTaskStatus = async (payload) => {
        // setLoading(true);

        try {
            const res = await patchRequest(
                `${UPDATE_TASK}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                return true;
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    const updateIndexOfProjectLead = async (data) => {
        const payload = {
            tasks: data
        }
        setLoading(true);
        try {
            const res = await patchRequest(
                `${UPDATE_PROJECT_LEAD_INDEX}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }

    }


    const onDragEnd = async (result) => {

        if (!result.destination) return;

        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
            const updatedColumnsData = {
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            }

            const updatedTask = {
                task_id: removed.id.toString(),
                status_id: taskStatusList?.filter(task => task?.title == columns[destination.droppableId].title)[0]?.id,
            }
            const res = await updateTaskStatus(updatedTask);
            if (res) {
                const transformedData = Object.keys(updatedColumnsData).map((key) => {
                    const statusObj = updatedColumnsData[key];
                    return {
                        title: statusObj.title || statusObj.items[0]?.task_status?.title, // Use title or extract from items
                        taskIds: statusObj.items.map((task) => task.id), // Create array of task IDs
                    };
                });
                await updateIndexOfProjectLead(transformedData);
                await getLeads();
            }
        } else {
            // Handle moving within the same column
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
            const updatedColumnsData = {
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            }
            const transformedData = Object.keys(updatedColumnsData).map((key) => {
                const statusObj = updatedColumnsData[key];
                return {
                    title: statusObj.title || statusObj.items[0]?.task_status?.title, // Use title or extract from items
                    taskIds: statusObj.items.map((task) => task.id), // Create array of task IDs
                };
            });
            await updateIndexOfProjectLead(transformedData);
            await getLeads();
        }

    };


    return (
        <div className='overflow-x-auto'>
            {(projectLeadList?.length === 0 || (columns === null || columns === undefined)) ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Container>
                        <TaskColumnStyles>
                            {Object.entries(columns).map(([columnId, column]) => {
                                return (
                                    <Droppable key={columnId} droppableId={columnId}>
                                        {(provided) => (
                                            <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                                                <div className={`${column?.title === "Closed"
                                                    ? "text-[#EE404C] bg-[#ff00001a]"
                                                    : column?.title === "Open"
                                                        ? "text-[#83BF6E] bg-[#83bf6e24]"
                                                        : column?.title === "Confirm-Sale"
                                                            ? "text-[#6F767E] bg-[#6F767E24]"
                                                            : column?.title === "In Meeting"
                                                                ? "text-[#8E59FF] bg-[#8e59ff1a]"
                                                                : "text-[#6F767E] bg-[#6F767E24]"
                                                    } px-[10px] py-[2px] rounded self-start`}>
                                                    {column.title}
                                                </div>
                                                {column.items.map((item, index) => {
                                                    return (
                                                        <ProjectLeadCard key={item.id} item={item} index={index}
                                                            onDoubleClick={(e) => {
                                                                if (window.getSelection().toString() === '') {
                                                                    handleView(item.id);
                                                                }
                                                            }}
                                                            handleLeadComplete = {handleLeadComplete}
                                                            getTaskList={getLeads}
                                                        />
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </TaskList>
                                        )}
                                    </Droppable>

                                )
                            })}
                        </TaskColumnStyles>
                    </Container>
                </DragDropContext>
            )}
        </div>
    );
};

export default ProjectLeadKanabanView;
