import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Popover from '@mui/material/Popover';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { SlOptions } from "react-icons/sl";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from "react-select";
import validator from 'validator';
import { boundaryContext } from '../../../App';
import csvIcon from "../../../components/assets/Csv.png";
import docxIcon from "../../../components/assets/Docx.png";
import excelIcon from "../../../components/assets/Excel.jpg";
import Group from "../../../components/assets/Group.png";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import Upload_Icon from '../../../components/assets/Upload-icon.svg';
import zipIcon from "../../../components/assets/Zip.png";
import deleteIcon from '../../../components/assets/bin.svg';
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import videoIcon from "../../../components/assets/video-files-icon.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import { deleteRequest, getRequest, patchRequest } from '../../../components/axiosClient';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { MY_TRAINING_DELETE_TASK_DOCUMENT, GET_MY_TRAINING_TASK_BY_ID, MY_TRAINING_UPDATE_TASK, MY_TRAINING_UPLOAD_TASK_DOCUMENT, GET_MY_REPORTING_PERSON } from '../../../components/utils';
import Comments from "../ViewProject/ProjectTask/Comments";
import TimeLine from "../ViewProject/ProjectTask/TimeLine";
import Activity from "../ViewProject/ProjectTask/Activity";

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from "dayjs";

function MyTrainingDetails() {

    const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type === "Write";
    const { userData } = useContext(boundaryContext);

    const icons = {
        'application/msword': wordIcon,
        'application/pdf': pdfIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
        'application/x-zip-compressed': zipIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
        'text/csv': csvIcon,
        'word': wordIcon,
        'pdf': pdfIcon,
        'xlsx': excelIcon,
        'zip': zipIcon,
        'docx': docxIcon,
        'csv': csvIcon,
        'mp4': videoIcon,
    };

    const [activeTab, setActiveTab] = useState("comments");
    const [isDragging, setIsDragging] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const location = useLocation();
    const hasMounted = useRef(false);
    const titleRef = useRef();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('user_id');
    const [taskDetails, setTrainingDetails] = useState({
        task_id: id
    });
    const [updatedTrainingDetails, setUpdatedTrainingDetails] = useState({
        task_id: id,
    });

    const [fileList, setFileList] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [isValid, setIsValid] = useState(true);


    useEffect(() => {
        getTrainingDetails();
        getProjectMembers();
    }, [])

    const debouncedUpdateTask = debounce((prop) => {
        // Your API call logic here, for example:
        updateTrainingDetails(prop);
    }, 1000);

    const previousTrainingDetails = useRef(taskDetails);
    const previousUpdatedTrainingDetails = useRef(updatedTrainingDetails);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            return;
        }

        // Check if both taskDetails and updatedTrainingDetails have changed
        const taskDetailsChanged = previousTrainingDetails.current !== taskDetails;
        const updatedTrainingDetailsChanged = previousUpdatedTrainingDetails.current !== updatedTrainingDetails;

        if (taskDetailsChanged && updatedTrainingDetailsChanged) {
            debouncedUpdateTask(updatedTrainingDetails);
        }

        // Update refs to current values
        previousTrainingDetails.current = taskDetails;
        previousUpdatedTrainingDetails.current = updatedTrainingDetails;

        return () => {
            debouncedUpdateTask.cancel();
        };
    }, [taskDetails, updatedTrainingDetails]);

    const updateTrainingDetails = async (payload) => {

        //Don't make API call when still focus is true
        if (titleRef.current && document.activeElement === titleRef.current) {
            return;
        } else {
            previousTrainingDetails.current = taskDetails;
            previousUpdatedTrainingDetails.current = updatedTrainingDetails;
        }

        if (payload?.title !== undefined && validator.isEmpty(payload.title)) {
            getTrainingDetails();
            setUpdatedTrainingDetails({
                task_id: id
            })
            errorNotification("Task title can not be empty");
            return;
        }
        const updatedPayload = { ...payload };

        if (payload?.due_date !== undefined) {
            updatedPayload.due_date = typeof payload.due_date === 'string' && payload.due_date.trim() === ""
                ? null
                : payload.due_date;
        }

        try {
            const res = await patchRequest(
                `${MY_TRAINING_UPDATE_TASK}`,
                updatedPayload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
            } else {
                getTrainingDetails();
                errorNotification(res?.errormessage);
            }
            setUpdatedTrainingDetails({
                task_id: id
            })
        } catch (error) {
            getTrainingDetails();
            console.error(error);
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    };

    const getTrainingDetails = async () => {
        setLoading(true);
        try {
            const res = await getRequest(
                `${GET_MY_TRAINING_TASK_BY_ID}?task_id=${id}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                setTrainingDetails((prevTrainingDetails) => ({
                    ...prevTrainingDetails,
                    ...res.data.data
                }));
                setUpdatedTrainingDetails({
                    task_id: id
                })

                setDateValue(res.data.data.due_date ? dayjs(res.data.data.due_date).toDate() : null);
                setFileList(res.data.data.task_documents);
            }
            else {
                errorNotification(res.errormessage);
                navigate(-1);
                setTrainingDetails();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
            errorNotification(error);
        }
    }

    const handleTabChange = (e) => {
        setActiveTab(e.target.name);
    };

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const getProjectMembers = async () => {
        try {
            setLoading(true);
            const res = await getRequest(
                `${GET_MY_REPORTING_PERSON}?task_id=${id}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const dataList = res.data.data.map((item) => {
                    return { value: item?.id, label: item?.first_name + ' ' + item?.last_name, profile_image: item?.profile_image };
                });
                // dataList.unshift({ value: null, label: "None", profile_image: null });
                setMemberList(dataList);
            } else {
                errorNotification(res.errormessage);
                setMemberList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleFileUpload = async (e) => {
        const selectedFile = Array.from(e.target.files);
        handleFileOperation(selectedFile);
    }


    const handleFileOperation = async (file) => {
        if (!file || file.length > 1) {
            errorNotification('You can only upload one file at a time.');
            return;
        }

        const singleFile = file[0];
        if (!singleFile.type) {
            errorNotification('Folders are not allowed. Please drag and drop files only.');
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('document', singleFile);
        formData.append('task_id', id);
        try {
            const config = {
                method: "post",
                url: `${MY_TRAINING_UPLOAD_TASK_DOCUMENT}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        getTrainingDetails();
                        hasMounted.current = false;
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedItems = Array.from(event.dataTransfer.files);
        handleFileOperation(droppedItems);
    };

    const removeFile = async (id) => {
        setLoading(true);
        try {
            const res = await deleteRequest(`${MY_TRAINING_DELETE_TASK_DOCUMENT}?document_id=${id}`, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    getTrainingDetails();
                    hasMounted.current = false;
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }
    };

    const [value, setValue] = useState('');
    const [showInput, setShowInput] = useState(false);

    const handleAddTag = () => {
        setShowInput(true);
        setValue('');
    }

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleBlur = () => {
        if (value.length > 2) {
            submitValue();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents default behavior (like form submission)
            if (value.length > 2) {
                submitValue();
            }
        }
    };

    const submitValue = () => {

        // Create a new array with the current tags plus the new value
        let updatedArray = [...(taskDetails.tags || []), value];


        // Update both states with the new array
        setUpdatedTrainingDetails(prevState => ({
            ...prevState,
            tags: updatedArray
        }));

        setTrainingDetails(prevState => ({
            ...prevState,
            tags: updatedArray
        }));
        setShowInput(false);
        // Clear the input value
        setValue('');
    };

    const handleTagRemove = (index) => {
        let array = [...taskDetails.tags];
        array.splice(index, 1);
        setTrainingDetails({ ...taskDetails, tags: array });
        setUpdatedTrainingDetails({ ...updatedTrainingDetails, tags: array });
    }

    const customFormatOptionLabel = ({ label, icon }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{label}</span>
        </div>
    );

    const DocumentTypeExtractor = (url) => {
        const getFileExtension = (url) => {
            return url.split('.').pop().split(/\#|\?/)[0];
        };
        const fileExtension = getFileExtension(url);
        return (fileExtension);
    };

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };

    const priorityOptionsList = ["Low", "Medium", "High"];
    const priorityOption = priorityOptionsList.map((props) => ({
        label: props,
        value: props,
        icon: customIcons[props]
    }));

    const statusOptionList = ["To do", "In progress", "To be verified", "Completed"];
    const statusOption = statusOptionList.map((props) => ({
        label: props,
        value: props
    }));

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const pop_id = open ? 'simple-popover' : undefined;

    const handleDateChange = (newValue) => {
        setDateValue(newValue);
        const formattedDate = newValue && dayjs(newValue).isValid()
            ? dayjs(newValue).format('YYYY-MM-DD')
            : null;
        if (formattedDate !== null && isValid === true) {
            setTrainingDetails((prevDetails) => ({
                ...prevDetails,
                due_date: formattedDate,
            }));

            setUpdatedTrainingDetails((prevUpdatedDetails) => ({
                ...prevUpdatedDetails,
                due_date: formattedDate,
            }));
        }
    };

    const handleDateError = (error) => {
        setIsValid(!error);
        if (error) {
            return;
        }
        const formattedDate = dateValue && dayjs(dateValue).isValid()
            ? dayjs(dateValue).format('YYYY-MM-DD')
            : null;


        setTrainingDetails((prevDetails) => ({
            ...prevDetails,
            due_date: formattedDate,
        }));

        setUpdatedTrainingDetails((prevUpdatedDetails) => ({
            ...prevUpdatedDetails,
            due_date: formattedDate,
        }));
    };


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className={`p-4 bg-white rounded-lg flex flex-col h-[calc(100vh_-_160px)] overflow-y-hidden box-border`}>
            <div className={`go__back__btn flex flex-row gap-3 items-center justify-between mb-2`}>
                <div className='flex items-center gap-3'>
                    <span className='cursor-pointer'>
                        <ArrowBackIcon onClick={goBack} loading="lazy" />
                    </span>
                    <div>
                        <button className={`border cursor-pointer p-2 rounded text-sm ${taskDetails.is_completed ? 'bg-[#dcf7d9]' : ''} hover:bg-[#dcf7d9] hover:border-green-500`}
                            onClick={(e) => {
                                const change = taskDetails.is_completed ? false : true;
                                setTrainingDetails({
                                    ...taskDetails,
                                    is_completed: change,
                                });
                                setUpdatedTrainingDetails({
                                    ...updatedTrainingDetails,
                                    is_completed: change,
                                });
                            }}
                        >
                            <span className='flex flex-row items-center gap-1'>{<IoMdCheckmark />}{!taskDetails.is_completed ? "Mark complete" : "Completed"}</span>
                        </button>
                    </div>
                </div>
                <div>
                </div>

            </div>
            <div className='flex flex-col lg:flex-row h-[calc(100%_-_38px)] pb-2 box-border'>
                <div className={`${isDragging ? 'border-4 border-blue-500 bg-blue-100' : ''} pr-3 lg:border-r-2 w-full lg:w-[40%] overflow-y-auto hide-scrollbar`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className='border-[#cfcbcb] w-full'>
                        <input
                            type='text'
                            ref={titleRef}
                            placeholder='Enter title of task'
                            className={`focus:border-2 border-[#cfcbcb] w-full rounded-md px-2 py-2 font-medium text-xl`}
                            value={taskDetails.title}
                            onChange={(e) => {
                                setTrainingDetails({ ...taskDetails, title: e.target.value });
                                setUpdatedTrainingDetails({
                                    ...updatedTrainingDetails,
                                    title: e.target.value
                                });
                            }}
                            onBlur={() => {
                                updateTrainingDetails(updatedTrainingDetails);
                            }}
                        // readOnly={!role_access}
                        />
                    </div>
                    <div className='border-b-2 mb-3'></div>
                    <div className='flex flex-col gap-3'>
                        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center">
                            <p className="text-[#000000] font-medium min-w-[110px]">Assignee</p>
                            <Select className="rounded-lg text-capitalize min-w-40"
                                options={memberList}
                                name="assignee to"
                                value={memberList.find(option => option.value === taskDetails.assigned_to)}
                                onChange={(selectedOption) => {
                                    setTrainingDetails({ ...taskDetails, assigned_to: selectedOption ? selectedOption.value : null });
                                    setUpdatedTrainingDetails({
                                        ...updatedTrainingDetails,
                                        assigned_to: selectedOption ? selectedOption.value : null
                                    });

                                }}
                            // isDisabled={!role_access}

                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="text-[#000000] font-medium min-w-[110px]">Due Date</span>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    toolbarPlaceholder="Please enter date"
                                    className="h-[40px] date-picker rounded-md"
                                    id="date-picker"
                                    value={dateValue}
                                    onError={handleDateError}
                                    onChange={handleDateChange}
                                    inputFormat="dd-MM-yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        {/* <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center">
                            <p className="text-[#000000] font-medium min-w-[110px]">Project</p>
                            <input
                                placeholder="Enter OS version"
                                className="p-2 h-[40px] w-fit rounded-lg  border-1 border-[#cfcbcb]"
                                type="text"
                                name="os_version"
                                value={taskDetails?.project?.name}
                                readOnly
                            />
                        </div> */}
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[110px] ">Status</span>
                            <Select
                                className="basic-single text-capitalize min-w-40 border-1"
                                classNamePrefix="select"
                                placeholder="Status"
                                name="selectedClient"
                                options={statusOption}
                                value={taskDetails.status ?
                                    { label: taskDetails.status, value: taskDetails.status } : null}
                                onChange={(e) => {
                                    setTrainingDetails({
                                        ...taskDetails,
                                        status: e.value,
                                    });
                                    setUpdatedTrainingDetails({
                                        ...updatedTrainingDetails,
                                        status: e.value,
                                    });
                                }}
                            // isDisabled={!role_access}

                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[110px]">Priority</span>
                            <Select
                                className="basic-single text-capitalize min-w-40 border-1"
                                classNamePrefix="select"
                                formatOptionLabel={customFormatOptionLabel}
                                placeholder="Priority"
                                name="selectedClient"
                                options={priorityOption}
                                value={taskDetails.priority ?
                                    { label: taskDetails.priority, value: taskDetails.priority, icon: customIcons[taskDetails.priority] } : null}
                                onChange={(e) => {
                                    setTrainingDetails({
                                        ...taskDetails,
                                        priority: e.value,
                                    });
                                    setUpdatedTrainingDetails({
                                        ...updatedTrainingDetails,
                                        priority: e.value,
                                    });
                                }}
                            // isDisabled={!role_access}
                            />

                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="text-[#000000] font-medium mt-[12px] min-w-[110px]">Tag</span>
                            <div className="mt-2 flex flex-wrap gap-2">

                                {
                                    taskDetails.tags &&
                                    taskDetails.tags.map((tags, index) => (
                                        <span className="flex justify-center items-center bg-green-200 text-green-700 px-2 py-1 rounded" key={index}>
                                            {tags}
                                            <span className="ml-1 cursor-pointer" onClick={(e) => handleTagRemove(index)}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    ))
                                }
                                {
                                    showInput ?
                                        <input
                                            className='px-2 py-1 min-w-[40px] w-auto border max-w-[100px]'
                                            value={value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            autoFocus
                                        />

                                        : <button className='cursor-pointer h-8 w-8 rounded border'
                                            onClick={() => handleAddTag()}
                                        >
                                            +</button>
                                }
                            </div>
                        </div>

                        <div className="pt-2 pb-1 rounded-lg">
                            <div className='rounded-lg'>
                                <h6 className='mb-2 text-[#000000] font-medium min-w-[110px]'>Description</h6>
                                <textarea
                                    type="text"
                                    name="description"
                                    className='min-h-10 h-32 w-full rounded-lg border hover:border-black p-[6px] bg-transparent'
                                    value={taskDetails.description}
                                    onChange={(e) => {
                                        setTrainingDetails({
                                            ...taskDetails,
                                            description: e.target.value,
                                        });
                                        setUpdatedTrainingDetails({
                                            ...updatedTrainingDetails,
                                            description: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className=' flex flex-col justify-start gap-2'>
                                {/* Tab navigation and content */}
                                <h6 className='text-[#000000] font-medium mt-3 min-w-[110px]'>Documents</h6>
                                <div className="">
                                    <div className='flex flex-wrap gap-2 mt-2'>
                                        <div className={`relative flex flex-row justify-center items-center h-[62px] w-[290px] rounded-xl border-2 border-dashed bg-[#F8F8F8] `}>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                multiple
                                                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                                onChange={(e) => handleFileUpload(e)}
                                            />
                                            <img src={Upload_Icon} className="h-3.5 mr-2" alt="Upload" />
                                            <p>Upload File</p>
                                        </div>
                                        {fileList.map((docs, index) => {

                                            const type = DocumentTypeExtractor(docs.document);

                                            return (
                                                <div className='relative  w-[290px] group'>
                                                    <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                                        onClick={() => removeFile(docs?.id)}
                                                    >
                                                        <RxCross2 />
                                                    </div>
                                                    <div
                                                        className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                                    >
                                                        <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                            <div className="w-[40px] h-[40px]">
                                                                <img
                                                                    src={
                                                                        icons[type] || docs?.document
                                                                    }
                                                                    alt={type}
                                                                    className="w-[40px] h-[40px]"
                                                                />
                                                            </div>
                                                            <div className="w-[190px]">
                                                                <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                                    {docs?.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row justify-end gap-4  items-center w-full">
                                                            <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                                                                <a href={docs?.document} download target="_blank">
                                                                    <img
                                                                        src={Group}
                                                                        className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                                                        alt="group-icon"
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:pl-3 w-full lg:w-[60%]'>
                    <div className='flex flex-col justify-start gap-4'>
                        <ul className="nav nav-pills mb-3 mt-2" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "comments" ? "active" : ""}`}
                                    id="pills-comments-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-comments"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-comments"
                                    aria-selected="true"
                                    onClick={(e) => handleTabChange(e)}
                                    name="comments"
                                >
                                    Comments
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "time-line" ? "active" : ""}`}
                                    id="pills-time-line-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-time-line"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-time-line"
                                    aria-selected="false"
                                    name="time-line"
                                    onClick={(e) => handleTabChange(e)}
                                >
                                    Time Line
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "activity" ? "active" : ""}`}
                                    id="pills-activity-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-activity"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-activity"
                                    aria-selected="false"
                                    name="activity"
                                    onClick={(e) => handleTabChange(e)}
                                >
                                    Activity
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="program_scroll h-[calc(100%_-_70px)]">
                        <div className="tab-content h-full" id="pills-tabContent">
                            <div
                                className={`h-full tab-pane fade ${activeTab === "comments" ? "show active" : ""
                                    }`}
                                id="pills-comments"
                                role="tabpanel"
                                aria-labelledby="pills-comments-tab"
                            >
                                {activeTab === "comments" && <Comments task_id={id} call_id={5} membersList={memberList} />}
                            </div>
                            <div
                                className={`tab-pane fade h-full ${activeTab === "time-line" ? "show active" : ""
                                    }`}
                                id="pills-time-line"
                                role="tabpanel"
                                aria-labelledby="pills-time-line-tab"
                            >
                                {activeTab === "time-line" && <TimeLine task_id={id} call_id={5} />}
                            </div>
                            <div
                                className={`tab-pane fade h-full ${activeTab === "activity" ? "show active" : ""
                                    }`}
                                id="pills-activity"
                                role="tabpanel"
                                aria-labelledby="pills-activity-tab"
                            >
                                {activeTab === "activity" && <Activity task_id={id} call_id={5} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style>
                {`
                    .hide-scrollbar::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for Firefox */
                    .hide-scrollbar {
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */
                    }

                    .date-picker input{
                        padding: 0.5rem;
                        width: 120px;
                    }

                    .date-picker svg {
                        font-size: 1.2rem;
                    }
                `}
            </style>
        </div>
    )
}

export default MyTrainingDetails
