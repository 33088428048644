import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import DayWiseReport from "./DayWiseReport";
import Connect from "./Connect";

export default function Sales({ data }) {
  return (
    <Tabs defaultActiveKey="Connect" id="report-tabs" className="nav-pills mb-0">
      <Tab eventKey="Connect" title="Connect" className="text-capitalize">
        <Connect />
      </Tab>
      {/* <Tab eventKey="employee" title="Employee" className="nav-link">
      </Tab> */}
    </Tabs>
  );
}
