import React, { useContext, useEffect, useState } from "react";
import "./Setting.css";
import { ReactComponent as CloseEye } from "../../../../src/components/assets/close_eye.svg";
import { ReactComponent as OpenEye } from "../../../../src/components/assets/open_eye.svg";
import EditPen from "../../../../src/components/assets/EditPen.svg";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import { Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getRequest, patchRequest } from "../../../components/axiosClient";
import { GET_PROFILE, UPDATE_USER_PROFILE } from "../../../components/utils";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Avatar, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { boundaryContext } from "../../../App";


function Setting() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState([]);
  const { profileImage, setProfileImage } = useContext(boundaryContext);
  const navigate = useNavigate();

  const [values, setValues] = useState({
    currentpassword: "",
    showCurrentPassword: false,
  });
  const [newvalues, setNewValues] = useState({
    newPassword: "",
    showNewPassword: false,
  });
  const [confirmvalues, setConfirmValues] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleNewPasswordChange = (prop) => (event) => {
    setNewValues({ ...newvalues, [prop]: event.target.value });
  };

  const handleConfirmPasswordChange = (prop) => (event) => {
    setConfirmValues({ ...confirmvalues, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
  };

  const handleClickNewShowPassword = () => {
    setNewValues({ ...newvalues, showNewPassword: !newvalues.showNewPassword });
  };

  const handleClickConfirmShowPassword = () => {
    setConfirmValues({
      ...confirmvalues,
      showConfirmPassword: !confirmvalues.showConfirmPassword,
    });
  };

  const GetProjectDocument = async () => {
    setLoading(true);
    try {
      const res = await getRequest(GET_PROFILE, null, navigate);
      setDocument(res.data);
      setImageUrl(res.data.data.profile_image);
      setProfileImage(res.data.data.profile_image);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  const setNewpassword = async (payload) => {
    setLoading(true);
    try {
      const res = await patchRequest(UPDATE_USER_PROFILE, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          successNotification(res.response.data.message);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
      setLoading(false);
    } catch (error) {
      errorNotification(error.message);
      setLoading(false);
    }
  };

  const AddDocument = async () => {
    try {
      setLoading(true);
      const FormData = require("form-data");
      const payload = new FormData();
      payload.append("profile_image", selectedFile);

      const config = {
        method: "patch",
        url: `${UPDATE_USER_PROFILE}`,
        headers: {
          Authorization: secureLocalStorage.getItem("token"),
        },
        data: payload,
      };
      axios(config)
        .then((res) => {
          if (res.data.success) {
            successNotification(res.data.message);
            GetProjectDocument();
          }
        })
        .catch((err) => {
          errorNotification(err.response.data.message);
        });
    } catch (error) {
      errorNotification(error);
    }
  };

  const handleSaveChanges = async () => {
    if (newvalues.newPassword !== confirmvalues.confirmPassword) {
      errorNotification("New password and confirm password do not match");
      return;
    }

    const payload = {
      current_password: values.currentpassword,
      new_password: newvalues.newPassword,
    };

    await setNewpassword(payload);
  };

  useEffect(() => {
    selectedFile && AddDocument();
  }, [selectedFile]);

  useEffect(() => {
    GetProjectDocument();
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="setting__section h-full">
      <div className="setting__subsection h-full">
        <Tabs
          defaultActiveKey="profile"
          id="profile-tabs"
          className="nav-pills program__manage mb-3"
        >
          <Tab eventKey="profile" title="Profile" className="nav-link">
            <h5 className="font-[700] text-[22px] mb-4">Profile Information</h5>
            <div className="row mt-1">
              <div className="col-md-5">
                <>
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    className="d-none"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <div
                    mt={2}
                    mb={2}
                    textalign="center"
                    className="profile__img"
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={
                        <label htmlFor="select-image">
                          <img
                            className="rounded-full !w-[30px] !h-[30px] shadow-xl"
                            alt="Remy Sharp"
                            src={EditPen}
                          />
                        </label>
                      }
                    >
                      <Avatar
                        src={imageUrl}
                        className="h-32 w-32 border-[1px] border-[#d3d3d3] shadow-xl"
                        alt={selectedFile}
                        sx={{ height: "128px", width: "128px" }}
                      />
                    </Badge>
                  </div>

                </>
                <div className="account__details mt-5 pe-4">
                  <form>
                    <div className="form-group mb-4">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={document?.data?.email}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="department_name">Department</label>
                      <input
                        type="text"
                        className={` form-control `}
                        id="department_name"
                        value={document?.data?.department?.department_name}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="name">Role</label>
                      <input
                        type="text"
                        className={`form-control `}
                        id="name"
                        value={document?.data?.role?.name}
                        disabled
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="change-password"
            title="Change Password"
            className="nav-link"
          >
            <h5 className="font-[700] text-[22px] mb-4">Change Password</h5>
            <div className="row mt-1">
              <div className="col-md-5">
                <>
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    className="d-none"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <div
                    mt={2}
                    mb={2}
                    textalign="center"
                    className="profile__img"
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={
                        <label htmlFor="select-image">
                          <img
                            className="rounded-full !w-[30px] !h-[30px] !shadow-xl"
                            alt="Remy Sharp"
                            src={EditPen}
                          />
                        </label>
                      }
                    >
                      <Avatar
                        src={imageUrl}
                        className="h-32 w-32 border-[1px] border-[#d3d3d3] shadow-xl "
                        alt={selectedFile?.name}
                        sx={{ height: "128px", width: "128px" }}
                      />
                    </Badge>
                  </div>

                </>
                <div className="account__details mt-5 pe-4">
                  <form>
                    <div className="form-group mb-4 position-relative">
                      <label htmlFor="current-password">Current Password</label>
                      <input
                        type={values.showCurrentPassword ? "text" : "password"}
                        className="form-control"
                        name="current-password"
                        id="current-password"
                        autoComplete="off"
                        placeholder="Enter current password"
                        value={values.currentpassword}
                        onChange={handlePasswordChange("currentpassword")}
                      />
                      <span
                        onClick={handleClickShowPassword}
                        className="password-toggle"
                      >
                        {!values.showCurrentPassword ? (
                          <CloseEye />
                        ) : (
                          <OpenEye />
                        )}
                      </span>
                    </div>
                    <div className="form-group mb-4 position-relative">
                      <label htmlFor="new-password">New Password</label>
                      <input
                        type={newvalues.showNewPassword ? "text" : "password"}
                        name="new-password"
                        className="form-control"
                        id="new-password"
                        autoComplete="off"
                        placeholder="Enter new password"
                        value={newvalues.newPassword}
                        onChange={handleNewPasswordChange("newPassword")}
                      />
                      <span
                        onClick={handleClickNewShowPassword}
                        className="password-toggle"
                      >
                        {!newvalues.showNewPassword ? <CloseEye /> : <OpenEye />}
                      </span>
                    </div>
                    <div className="form-group mb-4 position-relative">
                      <label htmlFor="confirm-password">Confirm Password</label>
                      <input
                        type={
                          confirmvalues.showConfirmPassword
                            ? "text"
                            : "password"
                        }
                        name="confirm-password"
                        className="form-control"
                        id="confirm-password"
                        autoComplete="off"
                        placeholder="Enter confirm password"
                        value={confirmvalues.confirmPassword}
                        onChange={handleConfirmPasswordChange(
                          "confirmPassword"
                        )}
                      />
                      <span
                        onClick={handleClickConfirmShowPassword}
                        className="password-toggle"
                      >
                        {!confirmvalues.showConfirmPassword ? (
                          <CloseEye />
                        ) : (
                          <OpenEye />
                        )}
                      </span>
                    </div>
                  </form>
                </div>
                <button
                  className="btn edit__program__btn"
                  type="button"
                  onClick={handleSaveChanges}
                >
                  Change Password
                </button>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Setting;
