import dashboard_icon_active from "../../assets/activeDashboardIconSidebar.svg";
import assets_icon from "../../assets/assetsIcon.svg";
import assets_icon_active from "../../assets/assetsIcon_Active.svg";
import candidate_leads_active from "../../assets/candidateLeads_active.svg";
import candidate_leads_inactive from "../../assets/candidateLeads_deactive.svg";
import client_icon from "../../assets/client_icon.svg";
import client_icon_active from "../../assets/client_icon_active.svg";
import dashboard_icon from "../../assets/dashboardIconSidebar.svg";
import document_icon_active from "../../assets/document-icon-active.svg";
import document_icon from "../../assets/document-icon.svg";
import inbox_icon from "../../assets/inbox_icon.svg";
import inbox_icon_active from "../../assets/inbox_icon_active.svg";
import leading_board_icon from "../../assets/leadingBoard.svg";
import leading_board_icon_active from "../../assets/leadingBoardActive.svg";
import my_task_active from "../../assets/my_task-active.svg";
import my_task from "../../assets/my_task.svg";
import my_team_icon from "../../assets/my_team_icon.svg";
import my_team_icon_active from "../../assets/my_team_icon_active.svg";
import organization_icon from "../../assets/organization_icon.svg";
import organization_icon_active from "../../assets/organization_icon_active.svg";
import ProjectLeads from "../../assets/projectLead_active.svg";
import ProjectLeads_deactive from "../../assets/projectLead_inactive.svg";
import report_icon_active from "../../assets/reportIconActiveSidebar.svg";
import setting_icon_active from "../../assets/settingiconActiveSidebar.svg";
import setting_icon from "../../assets/settingiconSidebar.svg";
import report_icon from "../../assets/stickynoteIcon.svg";
import time_sheet from "../../assets/time_sheet.svg";
import time_sheet_active from "../../assets/time_sheet_active.svg";
import training_icon_active from "../../assets/training_icon_active.svg";
import training_icon_inactive from "../../assets/training_icon_inactive.svg";
import {
  ADMIN_DASHBOARD,
  ASSETS,
  ASSETS_DETAILS,
  BOOKS,
  CANDIDATE_LEAD,
  CANDIDATE_LEADS_DETAILS,
  CLIENT_LIST,
  CONNECT,
  DAILY_UPDATES,
  DOCUMENT,
  EMPLOYEE_DETAILS,
  EMPLOYEE_LISTING,
  EMPLOYEE_RATING_DETAILS,
  INBOX,
  MY_TASK,
  MY_TEAM,
  MY_TEAM_DETAILS,
  MY_TEAM_RATING_PAGE,
  MY_TEAM_TASK_DETAILS,
  MY_TRAINING,
  ORGANIZATION,
  PROJECT_DETAILS,
  PROJECT_LEAD,
  PROJECT_LEAD_DETAILS,
  PROJECT_LIST,
  REPORT,
  SETTING,
  TASK_DETAIL,
  TASK_DETAILS,
  TRAINING,
  TRAINING_DETAILS,
  TRAINING_LIST
} from "../../constans/Route";

import { useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { boundaryContext } from "../../../App";

// dropdown custome style
export const styles = {
  control: (provided, state) => ({
    ...provided,
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: state.selectProps.menuIsOpen ? "0px" : "10px",
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0px" : "10px",
    backgroundColor: "transprant",
    color: "#000",
    transition: "none",
    cursor: "pointer",
    display: "none",
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: "#fff",
    color: isSelected ? "#000" : "#000",
    zIndex: 1,
    cursor: "pointer",
    borderBottom: "1px solid #FAFAFA",
    transition: "none",
    width: "100%",
    whiteSpace: "nowrap",
    ":hover": {
      backgroundColor: isSelected ? "#D3D3D3" : "#D3D3D3",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    backgroundColor: "#fff",
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "10px solid black",
    width: "fit-content",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : null,
    color: "#000",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    marginLeft: "10px",
  }),

  menuList: (provided) => ({
    ...provided,
    paddingTop: "0px",
    paddingBottom: "0px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
    backgroundColor: "#fff",
    borderRadius: "8px", // Example border radius
    padding: "4px 0px",
  }),
};

export const dropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    borderTopRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderBottomRightRadius: state.selectProps.menuIsOpen ? "0px" : "12px",
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0px" : "12px",
    backgroundColor: "#F4F4F4",
    color: "#9A9FA5",
    border: "none",
    height: "40px",
    boxShadow: "none",
    transition: "none",
    fontSize: "17px",
    fontWeight: 400,
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "transparent" : "transparent",
      color: "#9A9FA5",
      zIndex: 1,
      ":hover": {
        backgroundColor: isSelected ? "#D3D3D3" : "#D3D3D3",
      },
      padding: "0px 0px 0px 22px",
      borderTop: "1px solid #D3D3D3",
      transition: "none",
      cursor: "pointer",
      height: "40px",
      display: "flex",
      alignItems: "center",
    };
  },
  menu: (base) => ({
    ...base,
    zIndex: 100,
    marginTop: "0px",
    paddingTop: "0px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    borderBottomRightRadius: "25px",
    borderBottomLeftRadius: "25px",
    border: "none",
    // height: "125px",
    transition: "none",
    backgroundColor: "#F4F4F4",
    boxShadow: "none",
    borderTop: "1px solid #D3D3D3",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : null,
    color: "",
    marginRight: "15px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#9A9FA5",
    marginLeft: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9A9FA5",
    marginLeft: "15px",
    whiteSpace: "noWrap",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0px",
    borderBottomRightRadius: "25px",
    borderBottomLeftRadius: "25px",
    maxHeight: "auto",
  }),
};

const SidebarListItem = () => {
  const { projectName, employeeName, myTeamEmployeeName } =
    useContext(boundaryContext);
  const role = secureLocalStorage.getItem("role");
  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses"));

  const sidebarItem = {
    [role]: [
      {
        title: "Dashboard",
        to: ADMIN_DASHBOARD,
        icon: dashboard_icon,
        activeIcon: dashboard_icon_active,
        show: true,
      },
      {
        title: "Employee List",
        to: EMPLOYEE_LISTING,
        icon: leading_board_icon,
        activeIcon: leading_board_icon_active,
        show:
          role_accesses.find((access) => access.module.name === "Employee List")
            ?.access_type !== "None",
      },
      {
        title: "Projects",
        to: PROJECT_LIST,
        icon: report_icon,
        activeIcon: report_icon_active,
        show:
          role_accesses.find((access) => access.module.name === "Projects")
            ?.access_type !== "None",
      },
      {
        title: "Client",
        to: CLIENT_LIST,
        icon: client_icon,
        activeIcon: client_icon_active,
        show:
          role_accesses.find((access) => access.module.name === "Client")
            ?.access_type !== "None",
      },
      {
        title: "Inbox",
        to: INBOX,
        icon: inbox_icon,
        activeIcon: inbox_icon_active,
        show: role_accesses.find((access) => access.module.name === "Inbox")?.access_type !== "None",
      },
      {
        title: "My Task",
        to: MY_TASK,
        icon: my_task,
        activeIcon: my_task_active,
        show:
          role_accesses.find((access) => access.module.name === "My Task")
            ?.access_type !== "None",
      },
      {
        title: "Time Sheet",
        to: DAILY_UPDATES,
        icon: time_sheet,
        activeIcon: time_sheet_active,
        show:
          role_accesses.find((access) => access.module.name === "Daily Updates")
            ?.access_type !== "None",
      },
      {
        title: "My Team",
        to: MY_TEAM,
        icon: my_team_icon,
        activeIcon: my_team_icon_active,
        show:
          role_accesses.find((access) => access.module.name === "My Team")
            ?.access_type !== "None",
      },
      {
        title: "Project Lead",
        to: PROJECT_LEAD,
        icon: ProjectLeads_deactive,
        activeIcon: ProjectLeads,
        show:
          role_accesses.find((access) => access.module.name === "Project Lead")
            ?.access_type !== "None",
      },
      {
        title: "Training",
        to: TRAINING,
        icon: training_icon_inactive,
        activeIcon: training_icon_active,
        show: role_accesses.find((access) => access.module.name === "Training")?.access_type !== "None",
      },
      {
        title: "My Training",
        to: MY_TRAINING,
        icon: training_icon_inactive,
        activeIcon: training_icon_active,
        show: role_accesses.find((access) => access.module.name === "My Training")?.access_type !== "None", //
      },
      {
        title: "Candidate Leads",
        to: CANDIDATE_LEAD,
        icon: candidate_leads_inactive,
        activeIcon: candidate_leads_active,
        show:
          role_accesses.find(
            (access) => access.module.name === "Candidate Lead"
          )?.access_type !== "None",
      },
      {
        title: "Assets",
        to: ASSETS,
        icon: assets_icon,
        activeIcon: assets_icon_active,
        show:
          role_accesses.find((access) => access.module.name === "Assets")
            ?.access_type !== "None",
      },
      {
        title: "Organization",
        to: ORGANIZATION,
        icon: organization_icon,
        activeIcon: organization_icon_active,
        show:
          role_accesses.find((access) => access.module.name === "Organization")
            ?.access_type !== "None",
      },
      {
        title: "Report",
        to: REPORT,
        icon: report_icon,
        activeIcon: report_icon_active,
        show: role_accesses.find((access) => access.module.name === "Report")?.access_type !== "None",
      },
      {
        title: "Connect",
        to: CONNECT,
        icon: my_team_icon,
        activeIcon: my_team_icon_active,
        show: role_accesses.find((access) => access.module.name === "Connect")?.access_type !== "None",
      },
      {
        title: "Document",
        to: DOCUMENT,
        icon: document_icon,
        activeIcon: document_icon_active,
        show: role_accesses.find((access) => access.module.name === "Documents")?.access_type !== "None",
      },
      {
        title: "Books",
        to: BOOKS,
        icon: training_icon_inactive,
        activeIcon: training_icon_active,
        show: role_accesses.find((access) => access.module.name === "Books")?.access_type !== "None", //
      },
      {
        title: "Setting",
        to: SETTING,
        icon: setting_icon,
        activeIcon: setting_icon_active,
        show: true,
      },
    ],
  };

  const headerListItem = {
    [role]: [
      {
        title: "Dashboard",
        to: ADMIN_DASHBOARD,
      },
      {
        title: "Projects",
        to: PROJECT_LIST,
      },
      {
        title: projectName || "Project Details",
        to: PROJECT_DETAILS,
      },
      {
        title: employeeName || "Employee Details",
        to: EMPLOYEE_DETAILS,
      },
      {
        title: employeeName || "Employee Ratings",
        to: EMPLOYEE_RATING_DETAILS,
      },
      {
        title: "Employee",
        to: EMPLOYEE_LISTING,
      },
      {
        title: "Client",
        to: CLIENT_LIST,
      },
      {
        title: "Time Sheet",
        to: DAILY_UPDATES,
      },
      {
        title: "My Task",
        to: MY_TASK,
      },
      {
        title: "My Task Details",
        to: TASK_DETAILS,
      },
      {
        title: "Task Details",
        to: TASK_DETAIL,
      },
      {
        title: "Inbox",
        to: INBOX,
      },
      {
        title: "My Team",
        to: MY_TEAM,
      },
      {
        title: myTeamEmployeeName || "My Team Details",
        to: MY_TEAM_DETAILS,
      },
      {
        title: myTeamEmployeeName || "My Team task Details",
        to: MY_TEAM_TASK_DETAILS,
      },
      {
        title: myTeamEmployeeName || "My Team Rating",
        to: MY_TEAM_RATING_PAGE,
      },
      {
        title: "Project Lead",
        to: PROJECT_LEAD,
      },
      {
        title: "Project Lead Details",
        to: PROJECT_LEAD_DETAILS,
      },
      {
        title: "Candidate Leads",
        to: CANDIDATE_LEAD,
      },
      {
        title: "Candidate Leads Details",
        to: CANDIDATE_LEADS_DETAILS,
      },
      {
        title: "Assets Details",
        to: ASSETS_DETAILS,
      },
      {
        title: "Assets",
        to: ASSETS,
      },
      {
        title: "Organization",
        to: ORGANIZATION,
      },
      {
        title: "Report",
        to: REPORT,
      },
      {
        title: "Connect",
        to: CONNECT,
      },
      {
        title: "Training",
        to: TRAINING,
      },
      {
        title: "Training Details",
        to: TRAINING_DETAILS,
      },
      {
        title: "Training List",
        to: TRAINING_LIST,
      },
      {
        title: "Document",
        to: DOCUMENT,
      },
      {
        title: "Books",
        to: BOOKS,
      },
      {
        title: "My Training",
        to: MY_TRAINING,
      },
      {
        title: "Setting",
        to: SETTING,
      },
    ],
  };

  return { sidebarItem, headerListItem };
};

export default SidebarListItem;
