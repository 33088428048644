import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { Avatar, Popover } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import { EditorState, Modifier, RichUtils, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import Picker from "emoji-picker-react";
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BiListOl } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { boundaryContext } from '../../../App';
import csvIcon from "../../../components/assets/Csv.png";
import docxIcon from "../../../components/assets/Docx.png";
import excelIcon from "../../../components/assets/Excel.jpg";
import Group from "../../../components/assets/Group.png";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import zipIcon from "../../../components/assets/Zip.png";
import deleteIcon from '../../../components/assets/bin.svg';
import bold_icon from '../../../components/assets/bold_icon.svg';
import Default_Avatar from '../../../components/assets/defualt_avtar.svg';
import menuIcon from '../../../components/assets/dots-vertical.svg';
import EmojiPickerIcon from '../../../components/assets/emoji_picker.svg';
import FileUploadIcon from '../../../components/assets/file_upload.png';
import italic_icon from '../../../components/assets/italic_icon.svg';
import list_icon from '../../../components/assets/list_icon.svg';
import sendIcon from "../../../components/assets/message-send-icon.svg";
import underline_icon from '../../../components/assets/underline_icon.svg';
import videoIcon from "../../../components/assets/video-files-icon.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import fileALT from "../../../components/assets/file-alt-solid.svg";
import { deleteRequest, getRequest } from '../../../components/axiosClient';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { DELETE_TASK_COMMENTS, GET_TASK_COMMENTS, SEND_TASK_COMMENTS } from '../../../components/utils';
import './taskDetails.css';


const icons = {
    'application/msword': wordIcon,
    'application/pdf': pdfIcon,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
    'application/x-zip-compressed': zipIcon,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
    'text/csv': csvIcon,
    'word': wordIcon,
    'pdf': pdfIcon,
    'xlsx': excelIcon,
    'zip': zipIcon,
    'docx': docxIcon,
    'csv': csvIcon,
    'mp4': videoIcon,
};


const mentionPlugin = createMentionPlugin()
const { MentionSuggestions } = mentionPlugin
const plugins = [mentionPlugin]

const TaskComments = ({ task_id, call_id, membersList }) => {
    const [showPicker, setShowPicker] = useState(false);
    const [file, setFile] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [hasMoreData, setHasMoreData] = useState(true);
    const [commentsList, setCommentsList] = useState([]);
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [isCommentsFetched, setIsCommentsFetched] = useState(false); // State to track function call
    const [pageInformation, setPageInformation] = useState({});
    const [mentionUserList, setMentionUserList] = useState([]);

    const pickerRef = useRef();
    const emojiButtonRef = useRef();
    const commentSectionRef = useRef();
    const isMounted = useRef(false);
    const editor = useRef(null);
    const { userData } = useContext(boundaryContext);

    const mentions = (membersList || []).map((member) => {
        if (member.value !== null) {
            return {
                userId: member.value,
                name: member.label,
                avatar: member?.profile_image || Default_Avatar,
            };
        }
        return null;
    }).filter(Boolean);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [suggestions, setSuggestions] = useState(mentions);
    const isEditorEmpty = editorState.getCurrentContent().hasText();

    const [open, setOpen] = useState(false); // To manage open/close state



    useEffect(() => {
        if (!isMounted.current) {
            getComments();
            isMounted.current = true;
        }
    }, []);


    const handleKeyDown = (event) => {
        // Check if Shift + Enter is pressed
        if (event.key === 'Enter' && event.ctrlKey) {
            event.preventDefault(); // Prevent default behavior (e.g., new line in text area)
            handleSendMessage(); // Call the send message function
        }
    };

    useEffect(() => {
        // Add event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [editorState]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                pickerRef.current &&
                !pickerRef.current.contains(event.target) &&
                emojiButtonRef.current &&
                !emojiButtonRef.current.contains(event.target) &&
                showPicker === true
            ) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef, emojiButtonRef, showPicker]);

    const getComments = async () => {
        try {
            setLoading(true);
            let API_URL;
            API_URL = `${GET_TASK_COMMENTS}?task_id=${task_id}&page=${1}&limit=${pageSize}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setCommentsList(res.data.data.page_data.slice().reverse());
                setPageInformation(res.data.data.page_information);

                setPage(2);
            } else {
                // No more data available
                // hasMoreData(false);
                errorNotification("No data found");
            }
            setLoading(false);
            setIsCommentsFetched((prev) => !prev);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (commentSectionRef.current) {
            commentSectionRef.current.scrollTop = commentSectionRef.current.scrollHeight;
        }
    }, [isCommentsFetched]);

    const fetchData = useCallback(async () => {
        if (loading || pageInformation.current_page >= pageInformation.last_page) return;

        setLoading(true);

        // Save the current scroll position before loading new data
        const commentSection = commentSectionRef.current;
        const previousScrollHeight = commentSection.scrollHeight;
        const previousScrollTop = commentSection.scrollTop;

        try {
            let API_URL;
            // if (call_id == 1) {
                API_URL = `${GET_TASK_COMMENTS}?task_id=${task_id}&page=${page}&limit=${pageSize}`;
            // } else if (call_id == 2) {
            //     API_URL = `${GET_USER_TASK_COMMENTS}?task_id=${task_id}&page=${page}&limit=${pageSize}`;
            // } else if (call_id == 3) {
            //     API_URL = `${GET_MY_TEAM_TASK_COMMENTS}?task_id=${task_id}&page=${page}&limit=${pageSize}`;
            // }

            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data.slice().reverse();
                setCommentsList((prevData) => [...newPageData, ...prevData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);

                // Restore scroll position relative to new content height
                requestAnimationFrame(() => {
                    commentSection.scrollTop = commentSection.scrollHeight - previousScrollHeight + previousScrollTop;
                });
            }

            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false);
            }

            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }, [page, loading, hasMoreData]);


    const handleScroll = useCallback(
        debounce(() => {
            if (commentSectionRef.current.scrollTop <= 20 && !loading) {
                fetchData();
            }
        }, 300), // 300ms debounce time
        [fetchData, loading]
    );

    useEffect(() => {
        const commentSection = commentSectionRef.current;

        if (commentSection) {
            commentSection.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (commentSection) {
                commentSection.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);



    const onChange = useCallback((newEditorState) => {
        const contentState = newEditorState.getCurrentContent();

        // If all content is cleared (e.g., after Ctrl + A and delete)
        if (!contentState.hasText()) {
            // Reset editor state to prevent stale entities
            const resetEditorState = EditorState.createEmpty();
            setEditorState(resetEditorState);
        } else {
            setEditorState(newEditorState);
        }
    }, []);

    const onToggle = useCallback((style) => {
        onChange(RichUtils.toggleInlineStyle(editorState, style));
    }, [editorState, onChange]);

    const onBlockToggle = useCallback((blockType) => {
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    }, [editorState, onChange]);

    const onOpenChange = (newOpenState) => {
        setOpen(newOpenState);
    };

    const onSearchChange = ({ value }) => {
        if (!editorState.getCurrentContent().hasText()) {
            // Clear suggestions when content is deleted
            setSuggestions([]);
            return;
        }

        // Filter and update suggestions
        const filteredSuggestions = defaultSuggestionsFilter(value, mentions);
        setSuggestions(filteredSuggestions);
    };

    // Focus on editor window
    const focusEditor = () => {
        editor.current.focus()
    }

    const onAddMention = (mentionId) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE', { id: mentionId });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        const newState = Modifier.replaceText(newEditorState.getCurrentContent(), newEditorState.getSelection(), `@${mentionId}`, null, entityKey);
        setEditorState(EditorState.push(newEditorState, newState, 'insert-characters'));
    }

    const onExtractMentions = () => {
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        let mentionedUsers = [];
        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key];
            if (ent.type === "mention") {
                mentionedUsers.push(ent.data.mention);
            }
        }
        setMentionUserList(mentionedUsers);
        return mentionedUsers;
    };

    const onEmojiClick = (emojiObject) => {
        const emoji = emojiObject.emoji;

        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        const contentStateWithEmoji = Modifier.insertText(
            contentState,
            selectionState,
            emoji
        );

        const newEditorState = EditorState.push(
            editorState,
            contentStateWithEmoji,
            'insert-characters'
        );
        setShowPicker(false);
        setEditorState(newEditorState);
    };

    const handleSendMessage = () => {
        const messageContent = convertMessageToText();

        // Remove HTML tags, &nbsp; and trim the result to check for empty message
        const strippedMessage = messageContent
            .replace(/<[^>]*>?/gm, '')  // Remove HTML tags
            .replace(/&nbsp;/g, '')     // Remove non-breaking spaces
            .trim();                    // Trim any remaining whitespace

        if (file.length === 0 && strippedMessage === '') {
            // errorNotification('Please enter a message or select a file');
            return;
        }

        const mentionUsers = onExtractMentions();
        sendMessage(messageContent, mentionUsers);
    };


    const convertMessageToText = () => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);

        const htmlMessage = draftToHtml(rawContent);  // Or use custom logic to convert to string

        return htmlMessage.trim();
    };


    const sendMessage = async (messageContent, mentionedUsers) => {

        setLoading(true);
        const formData = new FormData();
        file.forEach((file, index) => {
            formData.append(`file${index}`, file?.file);
        });
        formData.append('task_id', task_id);
        formData.append('message', messageContent);
        formData.append('mention_users', JSON.stringify(mentionedUsers));

        let API_URL;
        // if (call_id === 1) {
            API_URL = `${SEND_TASK_COMMENTS}`;
        // } else if (call_id === 2) {
        //     API_URL = `${SEND_USER_TASK_COMMENTS}`;
        // } else if (call_id === 3) {
        //     API_URL = `${SEND_MY_TEAM_TASK_COMMENTS}`;
        // } else if (call_id === 4) {
        //     API_URL = `${SEND_TRAINEE_TEAM_TASK_COMMENTS}`;
        // } else if (call_id === 5) {
        //     API_URL = `${SEND_MY_TRAINING_TASK_COMMENTS}`;
        // }

        try {
            const config = {
                method: "post",
                url: API_URL,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        getComments();
                        setFile([]);
                        setEditorState(EditorState.createEmpty());
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }

    }

    const removeFile = (fileToRemove) => {
        setFile(prevFiles => prevFiles.filter(file => file !== fileToRemove));
        // Optionally revoke the object URL
        URL.revokeObjectURL(fileToRemove.preview);
    };

    const handleFileChange = (event) => {
        const files = [...event.target.files];
        const newFiles = files.map(file => ({
            name: file.name,
            type: file.type,
            file: file,
            preview: URL.createObjectURL(file)
        }));

        newFiles.forEach((fileObj, index) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                setFile(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[prevFiles.length + index] = { ...fileObj, content };
                    return updatedFiles;
                });
            };
            reader.readAsText(fileObj.file);
        });
    };

    const handlePickerModal = () => {
        setShowPicker(val => !val);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedItems = Array.from(event.dataTransfer.files);
        const newFiles = [];

        droppedItems.forEach(file => {
            if (file.type) {  // Check if file has a valid type
                newFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    preview: URL.createObjectURL(file),
                    file: file
                });
            } else {
                errorNotification('Folders are not allowed. Please drag and drop files only.');
                return;
            }
        });

        setFile(prevFiles => [...prevFiles, ...newFiles]);

        return () => newFiles.forEach(file => URL.revokeObjectURL(file.preview));

    };

    const DocumentTypeExtractor = (url) => {
        const getFileExtension = (url) => {
            return url.split('.').pop().split(/\#|\?/)[0];
        };
        const fileExtension = getFileExtension(url);
        return (fileExtension);
    };

    const renderMessageWithMentions = (message, mention_users) => {
        if (mention_users === null || mention_users.length === 0) {
            return message;
        }

        return Array.isArray(mention_users)
            ? mention_users.reduce((msg, mention) => {
                const regex = new RegExp(`${mention.name}`, 'g');
                return msg.replace(regex, `<span style="color:#0067ff;">${mention.name}</span>`);
            }, message)
            : message;
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const handleMenu = (event, id) => {
        setSelectedCommentId(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open2 = Boolean(anchorEl);
    const id = open2 ? "simple-popover" : undefined;

    const handleCommentDelete = async () => {
        try {
            if (!selectedCommentId) {
                return;
            }
            let API_URL;
            // if (call_id === 1) {
                API_URL = `${DELETE_TASK_COMMENTS}?comment_id=${selectedCommentId}`;
            // } else if (call_id === 2) {
            //     API_URL = `${DELETE_USER_TASK_COMMENTS}?comment_id=${selectedCommentId}`;
            // } else if (call_id === 3) {
            //     API_URL = `${DELETE_MY_TEAM_TASK_COMMENT}?comment_id=${selectedCommentId}`;
            // } else if (call_id === 4) {
            //     API_URL = `${DELETE_TRAINEE_TASK_COMMENT}?comment_id=${selectedCommentId}`;
            // } else if (call_id === 5) {
            //     API_URL = `${DELETE_MY_TRAINING_TASK_COMMENT}?comment_id=${selectedCommentId}`;
            // }
            const res = await deleteRequest(
                API_URL,
                null,
                navigate
            );
            if (res?.type === 1) {
                await getComments();
                successNotification(res.response.data.message);
            } else {
                errorNotification(res.errormessage);
            }
        } catch (error) {
            console.error("error", error);
        }
    }


    return (
        <div className='comment-section flex flex-col justify-end px-2 pt-2 mb-[-2px] h-[550px] lg:h-full'
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <div ref={commentSectionRef} className='overflow-y-scroll h-auto pb-3'>
                {
                    loading &&
                    <div className="flex flex-row justify-center items-center h-[30px]">
                        loading...
                    </div>
                }
                <div className='flex flex-col gap-3'>

                    {
                        commentsList.length > 0 && commentsList.map((comment, index) => {

                            const currentDate = dayjs(comment.createdAt).format('DD-MM-YYYY');
                            const nextCommentDate = index <= commentsList.length - 1 ? (dayjs(commentsList[index - 1]?.createdAt).format('DD-MM-YYYY') || null) : null;
                            return (
                                <React.Fragment key={comment?.id}>

                                    {(index === 0 || (nextCommentDate && currentDate !== nextCommentDate)) && (
                                        <div className="relative flex items-center my-2">
                                            <div className="flex-grow border-t border-gray-400"></div>
                                            <span className="mx-4 px-4 py-1 text-sm font-medium text-gray-500 border border-gray-400 rounded-full whitespace-nowrap">
                                                {currentDate}
                                            </span>
                                            <div className="flex-grow border-t border-gray-400"></div>
                                        </div>
                                    )
                                    }

                                    <div className='w-full flex flex-row gap-[10px]' key={index}>
                                        {/* {(index === 0 || nextUserId && currentUserId !== nextUserId || (nextCommentDate && currentDate !== nextCommentDate)) ?  */}
                                        <Avatar src={comment.user.profile_image}
                                            alt='profile'
                                            className='!h-8 !w-8 rounded-full border mt-2 '
                                        />
                                        <div className='w-fit flex flex-col justify-start items-start border p-3 rounded-xl'>
                                            {/* {(index === 0 || nextUserId && currentUserId !== nextUserId || (nextCommentDate && currentDate !== nextCommentDate)) && */}
                                            <div className='flex flex-row w-full justify-between items-center mb-2'>
                                                <span className='flex flex-row justify-center items-center'>
                                                    <p className='font-semibold leading-[18px]'>{comment.user.first_name} {comment.user.last_name}</p>
                                                </span>
                                                <div className='ml-3 flex flex-row items-center justify-end'>
                                                    <span className=' font-medium text-[#6F767E] text-sm'>{dayjs(comment.createdAt).format('hh:mm A')}
                                                    </span>
                                                    {(userData.id === comment?.user.id) &&
                                                        <span className='ml-2 flex items-center cursor-pointer'
                                                            onClick={(e) => handleMenu(e, comment.id)}
                                                        >
                                                            <button
                                                                // onClick={handleMenu}
                                                                className='h-full w-full'
                                                                aria-describedby={id}
                                                            >
                                                                <img src={menuIcon} className='h-6 w-4' />
                                                            </button>
                                                        </span>}
                                                </div>

                                            </div>
                                            {/* } */}
                                            {comment.message && <div>
                                                <span dangerouslySetInnerHTML={{ __html: renderMessageWithMentions(comment.message, comment.mention_users) }} />
                                            </div>
                                            }

                                            {comment.task_comm_files.length > 0 &&
                                                <div className='w-fit mt-2 flex flex-wrap gap-2'>
                                                    {comment.task_comm_files.map((docs, newIndex) => {

                                                        const type = DocumentTypeExtractor(docs.file);

                                                        return (
                                                            <>

                                                                <div className='relative  w-[290px] group' key={newIndex}>
                                                                    <div
                                                                        className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                                                    >
                                                                        <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                                            <div className="w-[40px] h-[40px]">
                                                                                <img
                                                                                    src={ icons[type] || docs?.file }
                                                                                    alt={type}
                                                                                    onError={(e) => {
                                                                                        e.target.onerror = null;
                                                                                        e.target.src = fileALT;
                                                                                    }}
                                                                                    className="w-[40px] h-[40px]"
                                                                                />
                                                                            </div>
                                                                            <div className="w-[190px]">
                                                                                <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                                                    {docs?.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex flex-row justify-end gap-4  items-center w-full">
                                                                            <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                                                                                <a href={docs?.file} download={docs?.name} target="_blank">
                                                                                    <img
                                                                                        src={Group}
                                                                                        className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                                                                        alt="group-icon"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>

            </div>

            <div
                className={`rounded-xl border-1 bg-white border-[#E6E6E6] sticky bottom-0 ${isDragging ? 'border-4 border-blue-500 !bg-blue-100' : ''}`}
            >
                <div className="bg-[#F4F4F4] px-3 flex justify-between items-center rounded-t-[inherit]">
                    <div id="toolbar" className='!border-none  flex justify-between items-center '>
                        <span className="flex justify-between md:justify-start gap-6 py-2">
                            <button onMouseDown={(e) => { e.preventDefault(); onToggle('BOLD'); }}><img src={bold_icon} /></button>
                            <button onMouseDown={(e) => { e.preventDefault(); onToggle('ITALIC'); }}><img src={italic_icon} /></button>
                            <button onMouseDown={(e) => { e.preventDefault(); onToggle('UNDERLINE'); }}><img src={underline_icon} /> </button>
                            <button onMouseDown={(e) => { e.preventDefault(); onBlockToggle('unordered-list-item'); }}><img src={list_icon} /> </button>
                            <button onMouseDown={(e) => { e.preventDefault(); onBlockToggle('ordered-list-item'); }}><BiListOl className='w-[20px] h-[21px]' />
                            </button>
                            <button className="flex items-center">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    id="file-upload-input"
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-upload-input">
                                    <img src={FileUploadIcon} className='w-5 h-5 cursor-pointer' alt="File Upload Icon" />
                                </label>
                            </button>
                            <button className="">
                                <img src={EmojiPickerIcon}
                                    onClick={handlePickerModal}
                                    ref={emojiButtonRef}
                                    className='w-5 h-5' alt="File Upload Icon" />
                            </button>
                        </span>
                    </div>
                    <button onClick={handleSendMessage} className="p-1"><img src={sendIcon} alt="sendIcon" /></button>
                </div>
                {showPicker &&
                    (
                        <div ref={pickerRef} className="z-50 absolute left-[35px] top-[-370px] bg-white border border-gray-200 rounded-lg shadow-lg p-2">
                            <Picker
                                className="!h-[350px]"
                                onEmojiClick={onEmojiClick}
                            />
                        </div>
                    )
                }
                {
                    <div className='z-50 absolute left-8 top-[-270px]'>
                        <MentionSuggestions
                            onSearchChange={onSearchChange}
                            suggestions={suggestions}
                            onAddMention={onAddMention}
                            onOpenChange={onOpenChange}  // Pass onOpenChange function here
                            open={open}
                        />
                    </div>
                }
                <div className="flex items-center !bg-white space-x-3 mt-2 sticky rounded-b-xl max-h-80 overflow-y-scroll hide-scrollbar">

                    <div className="relative flex-1 bg-white">
                        <div className="editor input-area relative w-full bg-white rounded-xl focus:outline-none px-3 py-1" onClick={() => focusEditor()}>
                            {!isEditorEmpty && (
                                <div className="absolute left-4 top-1 text-gray-400">
                                    Enter your message...
                                </div>
                            )}
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                plugins={plugins}
                                onChange={editorState => setEditorState(editorState)}
                            />
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2 max-h-32 overflow-y-scroll hide-scrollbar px-2">
                            {
                                file.map((docs, index) => {
                                    return (
                                        <div className='relative  w-[270px] group' key={index}>
                                            <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                                onClick={() => removeFile(docs)}
                                            >
                                                <RxCross2 />
                                            </div>
                                            <div
                                                className="flex flex-row justify-between items-center border p-2.5 border-gray-300 rounded-lg text-center w-[270px]"
                                            >
                                                <div className="flex justify-start gap-2 items-center">
                                                    <div className="w-[40px] h-[40px]">
                                                        <img
                                                            src={
                                                                icons[docs.type] || docs.preview
                                                            }
                                                            alt={"abcdefghijklmnopqrstuvwxyz"}
                                                            className="w-[40px] h-[40px]"
                                                        />
                                                    </div>
                                                    <div className="max-w-[220px]">
                                                        <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                            {docs?.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
            <Popover
                id={id}
                open={open2}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        borderRadius: "8px",
                        marginTop: "4px",
                        boxShadow:
                            "0px 4px 6px -2px rgba(16, 24, 40, 0.08), 0px 12px 16px -4px rgba(0, 66, 86, 0.14)",
                    },
                }}
            >
                <div className="w-[170px] min-w-[170px] py-1 bg-[#FFFFFF]">
                    <div
                        className="flex px-[14px] py-2 hover:bg-[#F8F8F8] cursor-pointer items-center"
                        onClick={(e) => {
                            handleCommentDelete();
                            handleClose(); // Close popover after click
                        }}
                    >
                        <img
                            src={deleteIcon} // Replace with actual image path or import
                            alt="delete"
                            className="mr-[14px] h-4 w-4"
                        />
                        <p className="font-medium text-[#3C556B] text-[14px] align-middle">Delete</p>
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default TaskComments