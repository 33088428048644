import React, { useState } from 'react'
import AllBookList from './AllBookList';
import IssuedBookList from './IssuedBookList';

const BookList = () => {

    const [activeTab, setActiveTab] = useState('all-books');

    const handleTabChange = (e) => {
        setActiveTab(e.target.name);
    };

    return (
        <div className='h-full flex flex-col bg-white rounded-lg p-4'>
            <div className='flex flex-row mb-3'>
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "all-books" ? "active" : ""}`}
                            id="pills-all-books-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-all-books"
                            type="button"
                            role="tab"
                            aria-controls="pills-all-books"
                            aria-selected="true"
                            onClick={(e) => handleTabChange(e)}
                            name="all-books"
                        >
                            All Books
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "issued-books" ? "active" : ""}`}
                            id="pills-issued-books-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-issued-books"
                            type="button"
                            role="tab"
                            aria-controls="pills-issued-books"
                            aria-selected="false"
                            name="issued-books"
                            onClick={(e) => handleTabChange(e)}
                        >
                            Issued Books
                        </button>
                    </li>
                </ul>
            </div>
            <div className='grow overflow-y-auto'>
                <div className="tab-content h-full" id="pills-tabContent">
                    <div
                        className={`h-full tab-pane fade ${activeTab === "all-books" ? "show active" : ""
                            }`}
                        id="pills-all-books"
                        role="tabpanel"
                        aria-labelledby="pills-all-books-tab"
                    >
                        {activeTab === "all-books" && <AllBookList />}
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "issued-books" ? "show active" : ""
                            }`}
                        id="pills-issued-books"
                        role="tabpanel"
                        aria-labelledby="pills-issued-books-tab"
                    >
                        {activeTab === "issued-books" && <IssuedBookList />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookList