import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from "react-select";
import validator from 'validator';
import csvIcon from "../../../components/assets/Csv.png";
import docxIcon from "../../../components/assets/Docx.png";
import excelIcon from "../../../components/assets/Excel.jpg";
import Group from "../../../components/assets/Group.png";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import Upload_Icon from '../../../components/assets/Upload-icon.svg';
import zipIcon from "../../../components/assets/Zip.png";
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import videoIcon from "../../../components/assets/video-files-icon.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import { deleteRequest, getRequest, patchRequest } from '../../../components/axiosClient';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { DELETE_PROJECT_LEAD_DOCUMENT, GET_EMPLOYEE_LEAST_FOR_PROJECT_LEAD, GET_PROJECT_LEAD_BY_ID, UPDATE_TASK, UPLOAD_PROJECT_LEAD_DOCUMENT } from '../../../components/utils';
import ProjectLeadActivity from './ProjectLeadActivity';
import ProjectLeadComment from './ProjectLeadComment';
import ProjectLeadTimeLine from './ProjectLeadTimeLine';
import { IoMdCheckmark } from "react-icons/io";

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from "dayjs";

const ProjectLeadDetails = () => {

    const role_access = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Project Lead")?.access_type !== "Read";
    const icons = {
        'application/msword': wordIcon,
        'application/pdf': pdfIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
        'application/x-zip-compressed': zipIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
        'text/csv': csvIcon,
        'word': wordIcon,
        'pdf': pdfIcon,
        'xlsx': excelIcon,
        'zip': zipIcon,
        'docx': docxIcon,
        'csv': csvIcon,
        'mp4': videoIcon,

    };

    const [activeTab, setActiveTab] = useState("comments");
    const [isDragging, setIsDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const { id } = useParams(); // This will get the task id from the URL
    const location = useLocation();
    const hasMounted = useRef(false);
    const titleRef = useRef();
    const queryParams = new URLSearchParams(location.search);
    const [projectLeadDetails, setProjectLeadDetails] = useState({
        task_id: id
    });
    const [updatedProjectLeadDetails, setUpdatedProjectLeadDetails] = useState({
        task_id: id,
    });
    const [fileList, setFileList] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [isValid, setIsValid] = useState(true);
    useEffect(() => {
        getProjectLeadDetails();
        getEmployeeList();
    }, [])

    const debouncedUpdateTask = debounce((prop) => {
        updateProjectLeadDetails(prop);
    }, 1000);

    const previousProjectLeadDetails = useRef(projectLeadDetails);
    const previousUpdatedProjectLeadDetails = useRef(updatedProjectLeadDetails);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            return;
        }

        // Check if both projectLeadDetails and updatedProjectLeadDetails have changed
        const taskDetailsChanged = previousProjectLeadDetails.current !== projectLeadDetails;
        const updatedTaskDetailsChanged = previousUpdatedProjectLeadDetails.current !== updatedProjectLeadDetails;

        if (taskDetailsChanged && updatedTaskDetailsChanged) {
            if (!role_access) {
                return;
            }

            debouncedUpdateTask(updatedProjectLeadDetails);
        }

        // Update refs to current values
        previousProjectLeadDetails.current = projectLeadDetails;
        previousUpdatedProjectLeadDetails.current = updatedProjectLeadDetails;

        return () => {
            debouncedUpdateTask.cancel();
        };
    }, [projectLeadDetails, updatedProjectLeadDetails]);

    const updateProjectLeadDetails = async (payload) => {

        if (titleRef.current && document.activeElement === titleRef.current) {
            return;
        } else {
            previousProjectLeadDetails.current = projectLeadDetails;
            previousUpdatedProjectLeadDetails.current = updatedProjectLeadDetails;
        }

        if (payload?.title !== undefined && validator.isEmpty(payload.title)) {
            getProjectLeadDetails();
            setUpdatedProjectLeadDetails({
                task_id: id
            })
            errorNotification("Task title can not be empty");
            return;
        }

        const updatedPayload = { ...payload };

        if (payload?.due_date !== undefined) {
            updatedPayload.due_date = typeof payload.due_date === 'string' && payload.due_date.trim() === ""
                ? null
                : payload.due_date;
        }

        try {
            const res = await patchRequest(
                `${UPDATE_TASK}`,
                updatedPayload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                // successNotification("Lead assigned successfully");
            } else {
                getProjectLeadDetails();
                errorNotification(res?.errormessage);
            }
            setUpdatedProjectLeadDetails({
                task_id: id
            })
        } catch (error) {
            getProjectLeadDetails();
            console.error(error);
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    };

    const getProjectLeadDetails = async () => {
        setLoading(true);
        try {
            const res = await getRequest(
                `${GET_PROJECT_LEAD_BY_ID}?task_id=${id}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                setProjectLeadDetails((prevTaskDetails) => ({
                    ...prevTaskDetails,
                    ...res.data.data
                }));

                setDateValue(res.data.data.due_date ? dayjs(res.data.data.due_date).toDate() : null);
                setFileList(res.data.data.task_documents);
            }
            else {
                errorNotification(res.errormessage);
                setProjectLeadDetails();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
            errorNotification(error);
        }
    }

    const getEmployeeList = async () => {
        try {
            setLoading(true);
            const res = await getRequest(
                `${GET_EMPLOYEE_LEAST_FOR_PROJECT_LEAD}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const dataList = res.data.data.map((item) => {
                    return { value: item?.id, label: item?.first_name + ' ' + item?.last_name, profile_image: item?.profile_image };
                });
                dataList.unshift({ value: null, label: "None", profile_image: null });
                setEmployeeList(dataList);
            } else {
                errorNotification(res.errormessage);
                setEmployeeList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleTabChange = (e) => {
        setActiveTab(e.target.name);
    };

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const handleFileUpload = async (e) => {
        const selectedFile = Array.from(e.target.files);
        handleFileOperation(selectedFile);
    }


    const handleFileOperation = async (file) => {
        if (!file || file.length > 1) {
            errorNotification('You can only upload one file at a time.');
            return;
        }

        const singleFile = file[0];
        if (!singleFile.type) {
            errorNotification('Folders are not allowed. Please drag and drop files only.');
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('document', singleFile);
        formData.append('task_id', id);
        try {
            const config = {
                method: "post",
                url: `${UPLOAD_PROJECT_LEAD_DOCUMENT}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        getProjectLeadDetails();
                        hasMounted.current = false;
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedItems = Array.from(event.dataTransfer.files);
        handleFileOperation(droppedItems);
    };

    const removeFile = async (id) => {
        setLoading(true);
        try {
            const res = await deleteRequest(`${DELETE_PROJECT_LEAD_DOCUMENT}?document_id=${id}`, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    getProjectLeadDetails();
                    hasMounted.current = false;
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }
    };

    const [value, setValue] = useState('');
    const [showInput, setShowInput] = useState(false);

    const handleAddTag = () => {
        setShowInput(true);
        setValue('');
    }

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleBlur = () => {
        if (value.length >= 2) {
            submitValue();
        } else {
            setValue('');
            setShowInput(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents default behavior (like form submission)
            if (value.length > 2) {
                submitValue();
            }
        }
    };

    const submitValue = () => {

        // Create a new array with the current tags plus the new value
        let updatedArray = [...(projectLeadDetails.tags || []), value];


        // Update both states with the new array
        setUpdatedProjectLeadDetails(prevState => ({
            ...prevState,
            tags: updatedArray
        }));

        setProjectLeadDetails(prevState => ({
            ...prevState,
            tags: updatedArray
        }));
        setShowInput(false);
        // Clear the input value
        setValue('');
    };

    const handleTagRemove = (index) => {
        let array = [...projectLeadDetails.tags];
        array.splice(index, 1);
        setProjectLeadDetails({ ...projectLeadDetails, tags: array });
        setUpdatedProjectLeadDetails({ ...updatedProjectLeadDetails, tags: array });
    }

    const DocumentTypeExtractor = (url) => {
        const getFileExtension = (url) => {
            return url.split('.').pop().split(/\#|\?/)[0];
        };
        const fileExtension = getFileExtension(url);
        return (fileExtension);
    };


    const customFormatOptionLabel = ({ label, icon }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{label}</span>
        </div>
    );

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };

    const priorityOptionsList = ["Low", "Medium", "High"];
    const priorityOption = priorityOptionsList.map((props) => ({
        label: props,
        value: props,
        icon: customIcons[props]
    }));

    const statusOptionList = ["Open", "In Meeting", "Confirm-Sale", "Closed"];
    const statusOption = statusOptionList.map((props) => ({
        label: props,
        value: props
    }));

    const handleDateChange = (newValue) => {
        setDateValue(newValue);
        const formattedDate = newValue && dayjs(newValue).isValid()
            ? dayjs(newValue).format('YYYY-MM-DD')
            : null;
        if (formattedDate !== null && isValid === true) {
            setProjectLeadDetails((prevDetails) => ({
                ...prevDetails,
                due_date: formattedDate,
            }));

            setUpdatedProjectLeadDetails((prevUpdatedDetails) => ({
                ...prevUpdatedDetails,
                due_date: formattedDate,
            }));
        }
    };

    const handleDateError = (error) => {
        setIsValid(!error);
        if (error) {
            return;
        }
        const formattedDate = dateValue && dayjs(dateValue).isValid()
            ? dayjs(dateValue).format('YYYY-MM-DD')
            : null;


        setProjectLeadDetails((prevDetails) => ({
            ...prevDetails,
            due_date: formattedDate,
        }));

        setUpdatedProjectLeadDetails((prevUpdatedDetails) => ({
            ...prevUpdatedDetails,
            due_date: formattedDate,
        }));
    };


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className={`p-4 bg-white rounded-lg flex flex-col lg:h-[calc(100dvh_-_165px)] h-full`}>
            <div className={`go__back__btn flex flex-row gap-3 items-center justify-between mb-2`}>
                <div className='flex items-center gap-3'>
                    <span className='cursor-pointer'>
                        <ArrowBackIcon onClick={goBack} loading="lazy" />
                    </span>
                    <div>
                        <button className={`border cursor-pointer p-2 rounded text-sm ${projectLeadDetails.is_completed ? 'bg-[#dcf7d9]' : ''} hover:bg-[#dcf7d9] hover:border-green-500`}
                            onClick={(e) => {
                                const change = projectLeadDetails.is_completed ? false : true;
                                setProjectLeadDetails({
                                    ...projectLeadDetails,
                                    is_completed: change,
                                });
                                setUpdatedProjectLeadDetails({
                                    ...updatedProjectLeadDetails,
                                    is_completed: change,
                                });
                            }}
                        >
                            <span className='flex flex-row items-center gap-1'>{<IoMdCheckmark />}{!projectLeadDetails.is_completed ? "Mark complete" : "Completed"}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row pb-2 grow overflow-y-auto'>
                <div className={`${isDragging ? 'border-4 border-blue-500 bg-blue-100' : ''} pr-3 lg:border-r-2 w-full lg:w-[40%] h-full overflow-y-scroll hide-scrollbar`}
                    onDragOver={role_access ? handleDragOver : null}
                    onDragLeave={role_access ? handleDragLeave : null}
                    onDrop={role_access ? handleDrop : null}
                >
                    <div className='border-[#cfcbcb] w-full'>
                        <input
                            type='text'
                            ref={titleRef}
                            placeholder='Enter title of task'
                            className={`${role_access ? 'focus:border-2' : 'border-0'} border-[#cfcbcb] w-full rounded-md px-2 py-2 font-medium text-xl`}
                            value={projectLeadDetails.title || ""}
                            onChange={(e) => {
                                setProjectLeadDetails({ ...projectLeadDetails, title: e.target.value })
                                setUpdatedProjectLeadDetails({
                                    ...updatedProjectLeadDetails,
                                    title: e.target.value
                                })
                            }}
                            onBlur={() => {
                                updateProjectLeadDetails(updatedProjectLeadDetails);
                            }}
                            readOnly={!role_access}
                        />
                    </div>
                    <div className='border-b-2 mb-3'></div>
                    <div className='flex flex-col gap-3'>
                        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center">
                            <p className="text-[#000000] font-medium min-w-[110px]">Assignee</p>
                            <Select className="rounded-lg text-capitalize min-w-40"
                                options={employeeList}
                                name="assignee to"
                                value={employeeList.find(option => option.value === projectLeadDetails.assigned_to)}
                                onChange={(selectedOption) => {
                                    setLoading(true);
                                    setProjectLeadDetails({ ...projectLeadDetails, assigned_to: selectedOption ? selectedOption.value : null })
                                    setUpdatedProjectLeadDetails({
                                        ...updatedProjectLeadDetails,
                                        assigned_to: selectedOption ? selectedOption.value : null
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="text-[#000000] font-medium min-w-[110px]">Due Date</span>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    toolbarPlaceholder="Please enter date"
                                    className="h-[40px] date-picker rounded-md"
                                    id="date-picker"
                                    value={dateValue}
                                    onError={handleDateError}
                                    onChange={handleDateChange}
                                    inputFormat="dd-MM-yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[110px] ">Status</span>
                            <Select
                                className="basic-single text-capitalize min-w-40 border-1"
                                classNamePrefix="select"
                                placeholder="Status"
                                name="selectedClient"
                                options={statusOption}
                                value={projectLeadDetails.status ?
                                    { label: projectLeadDetails.status, value: projectLeadDetails.status } : null}
                                onChange={(e) => {
                                    setProjectLeadDetails({
                                        ...projectLeadDetails,
                                        status: e.value,
                                    });
                                    setUpdatedProjectLeadDetails({
                                        ...updatedProjectLeadDetails,
                                        status: e.value,
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[110px]">Priority</span>
                            <Select
                                className="basic-single text-capitalize min-w-40 border-1"
                                classNamePrefix="select"
                                formatOptionLabel={customFormatOptionLabel}
                                placeholder="Priority"
                                name="selectedClient"
                                options={priorityOption}
                                value={projectLeadDetails.priority ?
                                    { label: projectLeadDetails.priority, value: projectLeadDetails.priority, icon: customIcons[projectLeadDetails.priority] } : null}
                                onChange={(e) => {
                                    setProjectLeadDetails({
                                        ...projectLeadDetails,
                                        priority: e.value,
                                    });
                                    setUpdatedProjectLeadDetails({
                                        ...updatedProjectLeadDetails,
                                        priority: e.value,
                                    })
                                }}
                            />

                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="text-[#000000] font-medium mt-[12px] min-w-[110px]">Tag</span>
                            <div className="mt-2 flex flex-wrap gap-2">

                                {
                                    projectLeadDetails.tags &&
                                    projectLeadDetails.tags.map((tags, index) => (
                                        <span className="flex justify-center items-center bg-green-200 text-green-700 px-2 py-1 rounded" key={index}>
                                            {tags}
                                            <span className="ml-1 cursor-pointer" onClick={(e) => handleTagRemove(index)}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    ))
                                }
                                {
                                    showInput ?
                                        <input
                                            className='px-2 py-1 min-w-[40px] w-auto border  max-w-[100px] focus:border-[#cfcbcb] border-gray-300'
                                            value={value || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            autoFocus
                                        />
                                        : <button className='cursor-pointer h-8 w-8 rounded border'
                                            onClick={() => handleAddTag()}
                                        >
                                            +</button>
                                }
                            </div>
                        </div>

                        <div className="pt-2 pb-1 rounded-lg">
                            <div className='rounded-lg'>
                                <h6 className='mb-2 text-[#000000] font-medium min-w-[110px]'>Description</h6>
                                <textarea
                                    type="text"
                                    name="description"
                                    className='min-h-10 h-32 w-full rounded-lg border hover:border-black p-[6px] bg-transparent'
                                    value={projectLeadDetails.description}
                                    onChange={(e) => {
                                        setProjectLeadDetails({
                                            ...projectLeadDetails,
                                            description: e.target.value,
                                        });
                                        setUpdatedProjectLeadDetails({
                                            ...updatedProjectLeadDetails,
                                            description: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className=' flex flex-col justify-start gap-2'>
                                <h6 className='text-[#000000] font-medium mt-3 min-w-[110px]'>Documents</h6>
                                <div className="">
                                    <div className='flex flex-wrap gap-2 mt-2'>
                                        <div className="relative flex flex-row justify-center items-center h-[62px] w-[290px] rounded-xl border-2 border-dashed bg-[#F8F8F8]">
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                multiple
                                                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                                onChange={(e) => handleFileUpload(e)}
                                            />
                                            <img src={Upload_Icon} className="h-3.5 mr-2" alt="Upload" />
                                            <p>Upload File</p>
                                        </div>
                                        {fileList.map((docs, index) => {

                                            const type = DocumentTypeExtractor(docs.document);

                                            return (
                                                <div className='relative  w-[290px] group'>
                                                    <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                                        onClick={() => removeFile(docs?.id)}
                                                    >
                                                        <RxCross2 />
                                                    </div>
                                                    <div
                                                        className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                                    >
                                                        <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                            <div className="w-[40px] h-[40px]">
                                                                <img
                                                                    src={
                                                                        icons[type] || docs?.document
                                                                    }
                                                                    alt={type}
                                                                    className="w-[40px] h-[40px]"
                                                                />
                                                            </div>
                                                            <div className="w-[190px]">
                                                                <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                                    {docs?.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row justify-end gap-4  items-center w-full">
                                                            <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                                                                <a href={docs?.document} download={docs?.name} target="_blank">
                                                                    <img
                                                                        src={Group}
                                                                        className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                                                        alt="group-icon"
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pl-3 w-full lg:w-[60%] h-full flex flex-col'>
                    <div className='flex flex-col justify-start gap-4'>
                        <ul className="nav nav-pills mb-3 mt-2" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "comments" ? "active" : ""}`}
                                    id="pills-comments-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-comments"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-comments"
                                    aria-selected="true"
                                    onClick={(e) => handleTabChange(e)}
                                    name="comments"
                                >
                                    Comments
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "time-line" ? "active" : ""}`}
                                    id="pills-time-line-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-time-line"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-time-line"
                                    aria-selected="false"
                                    name="time-line"
                                    onClick={(e) => handleTabChange(e)}
                                >
                                    Time Line
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "activity" ? "active" : ""}`}
                                    id="pills-activity-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-activity"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-activity"
                                    aria-selected="false"
                                    name="activity"
                                    onClick={(e) => handleTabChange(e)}
                                >
                                    Activity
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="grow overflow-y-auto">
                        <div className="tab-content h-full" id="pills-tabContent">
                            <div
                                className={`h-full tab-pane fade ${activeTab === "comments" ? "show active" : ""}`}
                                id="pills-comments"
                                role="tabpanel"
                                aria-labelledby="pills-comments-tab"
                            >
                                {activeTab === "comments" && <ProjectLeadComment task_id={id} membersList={employeeList} />}
                            </div>
                            <div
                                className={`tab-pane fade h-full ${activeTab === "time-line" ? "show active" : ""
                                    }`}
                                id="pills-time-line"
                                role="tabpanel"
                                aria-labelledby="pills-time-line-tab"
                            >
                                {activeTab === "time-line" && <ProjectLeadTimeLine task_id={id} />}
                            </div>
                            <div
                                className={`tab-pane fade h-full ${activeTab === "activity" ? "show active" : ""
                                    }`}
                                id="pills-activity"
                                role="tabpanel"
                                aria-labelledby="pills-activity-tab"
                            >
                                {activeTab === "activity" && <ProjectLeadActivity task_id={id} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
};

export default ProjectLeadDetails;