import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./CandidateLeads.css";
import Select from "react-select";
import Group from "../../../components/assets/Group.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import excelIcon from "../../../components/assets/Excel.jpg";
import zipIcon from "../../../components/assets/Zip.png";
import docxIcon from "../../../components/assets/Docx.png";
import csvIcon from "../../../components/assets/Csv.png";
import trash from "../../../components/assets/trash.svg";
import { deleteRequest, getRequest, patchRequest } from "../../../components/axiosClient";
import { DELETE_CANDIDATE_RESUME, GET_CANDIDATE_LEAD_BY_ID, UPDATE_CANDIDATE_LEAD } from "../../../components/utils";
import { errorNotification, successNotification } from "../../../components/toast-notification/common-toast";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";


const CandidateLeadsDetails = () => {
    const navigate = useNavigate();
    const id = useParams();
    const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Candidate Lead")?.access_type !== "Read";
    const [resumeFile, setResumeFile] = useState(null);
    const [startDate, setStartData] = useState(null);
    const [resumeFileType, setResumeFileType] = useState(null);

    let canId = id?.id;
    const [formFields, setFormFields] = useState({
        first_name: null,
        last_name: null,
        email: null,
        contact_info: null,
        applied_position: null,
        experience_year: null,
        interview_date: null,
        interview_time: null,
        current_salary: null,
        expected_salary: null,
        resume: null,
        status: "Open",
        stage: null,
        notice_period: null,
        resume_source: null,
        lead_id: null,
        can_lead_id: canId,
        note: null
    });

    const noteRef = useRef(null);
    useEffect(() => {
        if (noteRef.current) {
            noteRef.current.style.height = "auto";
            noteRef.current.style.height = `${noteRef.current.scrollHeight}px`;
        }
    }, [formFields]);

    const handleEdit = () => {
        const { first_name, last_name, applied_position, contact_info, experience_year, interview_date } = formFields;

        if (!first_name.trim()) {
            errorNotification("Please enter the candidate's first name.");
        } else if (!last_name.trim()) {
            errorNotification("Please enter the candidate's last name.");
        } else if (!applied_position) {
            errorNotification("Please select the applied position.");
        } else if (!contact_info.trim()) {
            errorNotification("Please enter the candidate's contact information.");
        } else if (!experience_year) {
            errorNotification("Please select the candidate's experience.");
        } else {
            editCandidateLead(formFields);
        }
    }

    const removeFile = () => {
        setResumeFile(null);
    };

    const deleteResume = async () => {
        let canId = id?.id;
        if (isNaN(canId)) {
            errorNotification("Invalid id passed")
            navigate(-1);
        }
        try {
            const res = await deleteRequest(`${DELETE_CANDIDATE_RESUME}?can_lead_id=${canId}`, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    setResumeFile(null);
                    setResumeFileType(null);
                    getCandidateById();
                    successNotification(res.response.data.message);
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "image/jpeg",
                "image/png",
            ];
            if (validTypes.includes(file.type)) {
                setResumeFile(file);
            } else {
                errorNotification("Please upload a valid PDF, Word document, or image file.");
                e.target.value = null;
            }
        }
    };


    const editCandidateLead = async (payload) => {
        const FormData = require("form-data");
        const formData = new FormData();

        formData.append("resume_file", resumeFile);


        for (const key in payload) {
            if (payload[key] != "" && payload[key] != null) {
                formData.append(key, payload[key]);
            }
        }

        try {
            const config = {
                method: "patch",
                url: `${UPDATE_CANDIDATE_LEAD}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };

            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        getCandidateById();
                        successNotification(res.data.message);
                    } else {
                        errorNotification(res.data.message);
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        }
    }



    useEffect(() => {
        getCandidateById();
    }, [])


    const DocumentTypeExtractor = (url) => {
        const getFileExtension = (url) => {
            return url.split('.').pop().split(/\#|\?/)[0];
        };

        // Extract file extension
        const fileExtension = getFileExtension(url);
        setResumeFileType(fileExtension);
    };

    const getCandidateById = async () => {
        let canId = id?.id;
        if (isNaN(canId)) {
            errorNotification("Invalid id passed")
            navigate(-1);
        }
        try {
            const res = await getRequest(
                `${GET_CANDIDATE_LEAD_BY_ID}?can_lead_id=${canId}`,
                null,
                navigate
            );
            const data = res?.data?.data;
            if (data?.resume != null) {
                DocumentTypeExtractor(data?.resume);
                setResumeFile(data?.resume);
            }

            if (data?.interview_date === "0000-00-00") {
                data.interview_date = null;
            }

            setStartData(formatDate(data.createdAt));
            setFormFields(prevFields => ({
                ...prevFields,
                ...Object.keys(prevFields).reduce((acc, key) => {
                    if (data[key] !== undefined) {
                        acc[key] = data[key];
                    }
                    return acc;
                }, {})
            }));
        } catch (error) {
        }
    }

    const positionArray = [
        "Android",
        "iOS",
        "PHP",
        "Python",
        "BDE",
        "HR",
        "React Native",
        "Flutter",
        "Designer",
        "QA",
        "Mobile",
        "ReactJS",
        "NodeJS",
        "Dot net",
        "Angular.js",
        "Android intership",
        "iOS intership",
        "PHP intership",
        "Python intership",
        "React Native intership",
        "Flutter intership",
        "QA-internship",
        "NodeJS intership",
        "ReactJS internship",
        "Other"
    ]

    const positionOptions = positionArray.map((position) => ({
        label: position,
        value: position,
    }));

    const resumeSourceArray = [
        "Linkedin",
        "Indeed",
        "Roma",
        "Website",
        "Email",
        "TimesJob",
        "Developer Ref",
        "Campus recruitment",
        "Apna",
        "Other"
    ]

    const resumeSourceOptions = resumeSourceArray.map((source) => ({
        label: source,
        value: source
    }));

    const statusArray = [
        "Open", "Closed"
    ]

    const statusOptions = statusArray.map((source) => ({
        label: source,
        value: source
    }));

    const noticPeriodArray = [
        "Immediate",
        "15 days",
        "1 months",
        "2 months"
    ]

    const noticPeriodOptions = noticPeriodArray.map((source) => ({
        label: source,
        value: source
    }));

    const experienceArray = [
        "Fresher",
        "Internship finished",
        "3 month Intership",
        "0",
        "6+ months",
        "1", "1.5", "2", "2.5", "3", "3.5", "4", "5", "5+"
    ]

    const experienceOptions = experienceArray.map((source) => ({
        label: source,
        value: source
    }));

    const stageArray = [
        "Interview Scheduled",
        "Rejected",
        "Selected",
        "Offer Made",
        "Not Interested",
        "Hired",
        "No Response",
        "Future Lead",
        "Offer Declined"
    ]

    const stageOptions = stageArray.map((source) => ({
        label: source,
        value: source
    }));

    //navigate to previous page
    const goBack = () => {
        navigate(-1);
    };

    // const icons = {
    //     word: wordIcon,
    //     pdf: PdfIcon,
    //     docx: Docx,
    // };
    const icons = {
        'application/msword': wordIcon,
        'application/pdf': pdfIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
        'application/x-zip-compressed': zipIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
        'text/csv': csvIcon,
        'word': wordIcon,
        'pdf': pdfIcon,
        'xlsx': excelIcon,
        'zip': zipIcon,
        'docx': docxIcon,
        'csv': csvIcon,
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return dateString.split('T')[0];
    };

    return (
        <div className="admin__program__manage admin__program__details__section admin_audit ">
            <div className="go__back__btn flex justify-between   ">
                <div className="mt-1 cursor-pointer">
                    <ArrowBackIcon onClick={goBack} loading="lazy" />
                </div>
                <div >
                    <button
                        className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
                        onClick={() => handleEdit()}
                        disabled={!role_accesses}
                    >
                        Save
                    </button>
                </div>

            </div>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-2 add__candidate-leads__form ">
                <div className="">
                    <label className="block mb-1 font-semibold">First Name</label>
                    <input
                        type="text"
                        className="candidate-leads-name text-capitalize "
                        placeholder="First Name "
                        autoComplete="off"
                        value={formFields.first_name}
                        onChange={(e) =>
                            setFormFields({ ...formFields, first_name: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Last Name</label>
                    <input
                        type="text"
                        className="candidate-leads-name text-capitalize "
                        placeholder="Last Name "
                        autoComplete="off"
                        value={formFields.last_name}
                        onChange={(e) =>
                            setFormFields({ ...formFields, last_name: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Contact Info</label>
                    <input
                        type="text"
                        className="candidate-leads-name text-capitalize "
                        placeholder="Contact Info"
                        autoComplete="off"
                        value={formFields.contact_info}
                        onChange={(e) =>
                            setFormFields({ ...formFields, contact_info: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Email</label>
                    <input
                        type="Email"
                        className="candidate-leads-name"
                        placeholder="Email "
                        autoComplete="off"
                        value={formFields.email}
                        onChange={(e) =>
                            setFormFields({ ...formFields, email: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Applied Position</label>
                    <Select
                        options={positionOptions}
                        className="basic-single text-capitalize Employ_Country "
                        classNamePrefix="select"
                        placeholder="Applied Position"
                        name="selectedClient"
                        value={{ label: formFields.applied_position, value: formFields.applied_position }}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, applied_position: selectedOption.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Experience(years)</label>
                    <Select
                        options={experienceOptions}
                        className="basic-single text-capitalize Employ_Country "
                        classNamePrefix="select"
                        placeholder="Experience(years)"
                        name="selectedClient"
                        value={{ label: formFields.experience_year, value: formFields.experience_year }}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, experience_year: selectedOption.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Interview Date</label>
                    <input
                        type="date"
                        className="w-full !rounded-lg p-2 border border-gray-300"
                        value={formFields.interview_date ? formFields.interview_date : null}
                        onChange={(e) =>
                            setFormFields({ ...formFields, interview_date: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Interview Time</label>
                    <input
                        type="time"
                        className="candidate-leads-name"
                        placeholder="Interview Time "
                        autoComplete="off"
                        value={formFields.interview_time ? formFields.interview_time : null}
                        onChange={(e) =>
                            setFormFields({ ...formFields, interview_time: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Current Salary</label>
                    <input
                        type="number"
                        className="candidate-leads-name text-capitalize "
                        placeholder="Current Salary"
                        autoComplete="off"
                        value={formFields.current_salary}
                        onChange={(e) =>
                            setFormFields({ ...formFields, current_salary: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Expected Salary</label>
                    <input
                        type="number"
                        className="candidate-leads-name text-capitalize "
                        placeholder="Expected Salary"
                        autoComplete="off"
                        value={formFields.expected_salary}
                        onChange={(e) =>
                            setFormFields({ ...formFields, expected_salary: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Status</label>

                    <Select
                        options={statusOptions}
                        className="basic-single text-capitalize Employ_Country"
                        classNamePrefix="select"
                        placeholder="Status"
                        name="selectedClient"
                        value={formFields.status ? { label: formFields.status, value: formFields.status } : null}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, status: selectedOption.value })
                        }
                    />
                </div>

                <div>
                    <label className="block mb-1 font-semibold">Notice Period</label>
                    <Select
                        options={noticPeriodOptions}
                        className="basic-single text-capitalize Employ_Country candidate-option"
                        classNamePrefix="select"
                        placeholder="Notice Period"
                        name="selectedClient"
                        value={formFields.notice_period ? { label: formFields.notice_period, value: formFields.notice_period } : null}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, notice_period: selectedOption.value })
                        }
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Resume Source</label>
                    <Select
                        options={resumeSourceOptions}
                        className="basic-single text-capitalize candidate-option"
                        classNamePrefix="select"
                        placeholder="Resume Source"
                        name="selectedClient"
                        value={formFields.resume_source ? { label: formFields.resume_source, value: formFields.resume_source } : null}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, resume_source: selectedOption.value })
                        }
                    />
                </div>

                <div>
                    <label className="block mb-1 font-semibold">Stage</label>
                    <Select
                        options={stageOptions}
                        type="text"
                        className="basic-single candidate-option"
                        classNamePrefix="select"
                        value={formFields.stage ? { label: formFields.stage, value: formFields.stage } : null}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, stage: selectedOption.value })
                        }
                    />
                </div>


                {
                    resumeFile && formFields.resume ?

                        <div
                            className="flex flex-row justify-between gap-4  items-center border w-full p-2 border-gray-300 rounded-lg  text-center bg-[#FAFAFA]"
                        >
                            <div className="flex justify-start gap-8 w-[70%] px-2 items-center">
                                <div className="max-w-[50%]">
                                    <p className="text-[16px] text-start leading-4 font-semibold text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                        Resume
                                    </p>
                                </div>
                                <div className="">
                                    <img
                                        src={
                                            icons[resumeFileType] || formFields.resume ||
                                            "https://upload.wikimedia.org/wikipedia/commons/2/2d/File.svg"
                                        }
                                        alt={formFields.type}
                                        className="w-12 h-[50px]"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row justify-end gap-4  items-center w-full">
                                <img
                                    src={trash}
                                    className="text-gray-700 cursor-pointer hover:text-red-500 w-[22px] h-[22px]"
                                    alt="trash-icon"
                                    onClick={() => deleteResume(formFields)}
                                />
                                <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                                    <a href={formFields?.resume} download target="_blank">
                                        <img
                                            src={Group}
                                            className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                            alt="group-icon"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="flex flex-col">
                            <label className="modal-heading mb-2">Resume File (PDF only)</label>
                            <div className="flex items-center">
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                                    className={`w-full rounded-lg p-2 border border-gray-300 ${resumeFile ? 'hidden' : ''}`}
                                    onChange={handleFileChange}
                                />
                                {resumeFile && (
                                    <div className='relative  w-[290px] group'>
                                        <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                            onClick={removeFile}
                                        >
                                            <RxCross2 />
                                        </div>
                                        <div
                                            className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                        >
                                            <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                <div className="w-[40px] h-[40px]">
                                                    <img
                                                        src={
                                                            icons[resumeFile?.type] || URL.createObjectURL(resumeFile)
                                                        }
                                                        alt={resumeFile?.type}
                                                        className="w-[40px] h-[40px]"
                                                    />
                                                </div>
                                                <div className="w-[190px]">
                                                    <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                        {resumeFile.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                }

                <div>
                    <label className="block mb-1 font-semibold">Added Date</label>
                    <input
                        type="date"
                        className="w-full !rounded-lg p-2 border border-gray-300"
                        classNamePrefix="select"
                        value={startDate}
                        readOnly
                    />
                </div>
                <div>
                    <h6 className="block mb-1 font-semibold">Note</h6>
                    <textarea
                        className="candidate-leads-name"
                        placeholder="Enter Note"
                        name="note"
                        value={formFields?.note}
                        onChange={(e) =>
                            setFormFields({ ...formFields, note: e.target.value })
                        }
                        ref={noteRef}
                        style={{ overflow: "hidden" }}
                    />
                </div>

            </form>
        </div>
    );
};

export default CandidateLeadsDetails;
