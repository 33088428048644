import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { getRequest } from '../../../components/axiosClient';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { GET_MY_TEAM_REVIEW_BY_ID } from '../../../components/utils';

const MyTeamRating = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getReviewList();
    }, [])

    const handleNavigate = (month, year) => {
        navigate(`/my-team/my-team-task-details/${id}/rating/${month}/${year}`)
    }

    const getReviewList = async () => {
        setLoading(true);
        try {
            const API_URL = GET_MY_TEAM_REVIEW_BY_ID + `?user_id=${id}`
            const res = await getRequest(API_URL, null, navigate);
            if (res && res.data) {
                setData(res?.data?.data.userReviews);
            } else {
                setData([]);
                errorNotification(res.errormessage);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }


    const getAverageRating =(item)=>{
        const ratingList = item.map(i=>i?.rating);
        return ratingList.reduce((total, item)=>{
            return total + Number(item);
          },0)/ratingList.length;
    }


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-full flex flex-col px-4'>
            <>
                {data.length === 0 ? (
                    <div className="no__transcation">
                        <img src={noDataFound} alt={noDataFound} loading="lazy" />
                    </div>
                ) : (
                    <div className='grow overflow-y-auto'>
                        <div className="rounded-lg px-1 py-3">
                            <table className="table max-w-full">
                                <thead className="">
                                    <tr className="align-middle">
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium">
                                            Month
                                        </th>
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium">
                                            Average rating
                                        </th>
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium">
                                            Note
                                        </th>
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium text-right"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index} className="align-middle border-b-0">
                                            <td className="table_tr_style align-middle text-capitalize">
                                                {dayjs(`${item?.year}-${item?.month}`).format('MMMM, YYYY')}
                                            </td>
                                            <td className="table_tr_style align-middle">
                                                {getAverageRating(item?.user_review_ratings)}
                                            </td>
                                            <td className="table_tr_style align-middle truncate max-w-[40vw]">
                                                {item?.note || '--'}
                                            </td>
                                            <td className="border-b-0 !border-b-[#dee2e6] text-right ">
                                                <div className='flex gap-2 justify-end items-center'>
                                                    <button
                                                        className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                                                        onClick={() => handleNavigate(item?.month, item?.year)}
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};


export default MyTeamRating