import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { getRequest } from '../../../components/axiosClient';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { GET_EMPLOYEE_REVIEW } from '../../../components/utils';
// import MyTeamRatingView from './MyTeamRatingView';

export const EmployeeReview = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getReviewList();
    }, [])

    const handleNavigate = (month, year) => {
        navigate(`/employee-list/employee-details/${id}/rating/${month}/${year}`)
    }

    const getReviewList = async () => {
        setLoading(true);
        try {
            const API_URL = GET_EMPLOYEE_REVIEW + `?user_id=${id}`
            const res = await getRequest(API_URL, null, navigate);
            if (res && res.data && res.data.data) {
                setData(res?.data?.data);
            } else {
                setData([]);
                errorNotification(res.errormessage);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-full flex flex-col px-1'>
            <>
                {data?.length === 0 ? (
                    <div className="no__transcation">
                        <img src={noDataFound} alt={noDataFound} loading="lazy" />
                    </div>
                ) : (
                    <div className='grow overflow-y-auto'>
                        <div className="rounded-lg px-1 py-3">
                            <table className="table max-w-full">
                                <thead className="">
                                    <tr className="align-middle">
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium">
                                            Month
                                        </th>
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium">
                                            Average rating
                                        </th>
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium">
                                            Note
                                        </th>
                                        <th scope="col" className="!border-b-[#d3d3d3] font-medium text-right"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr key={index} className="align-middle border-b-0">
                                            <td className="table_tr_style align-middle text-capitalize">
                                                {dayjs(`${item?.year}-${item?.month}`).format('MMMM, YYYY')}
                                            </td>
                                            <td className="table_tr_style align-middle">
                                                {parseFloat(item?.average_rating).toFixed(1)}
                                            </td>
                                            <td className="table_tr_style align-middle truncate max-w-[40vw]">
                                                {item?.note || '--'}
                                            </td>
                                            <td className="border-b-0 !border-b-[#dee2e6] text-right ">
                                                <div className='flex gap-2 justify-end items-center'>
                                                    <button
                                                        className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                                                        onClick={() => handleNavigate(item?.month, item?.year)} // Pass data to the view
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </>

        </div >
    );
};