import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { deleteRequest, getRequest, postRequest } from "../../../components/axiosClient";
import DocumentData from "../../../components/document/DocumentData";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import { RxCross2 } from "react-icons/rx";
import {
  ADD_DOCUMENT_CATEGORY,
  DELETE_DOCUMENT_CATEGORY,
  GET_DOCUMENT_CATEGORY,
} from "../../../components/utils";
import Modal from "../Modal/Modal";

import "./Document.css";
import NewModal from "../Modal/NewModal";

const Document = () => {
  const [documentCategoryValue, setDocumentCategoryValue] = useState("");
  const [documentCategoriesList, setDocumentCategoriesList] = useState([]);
  const [listshow, setlistShow] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [deleteCategoryOpen, setDeleteCategoryOpen] = useState(false);
  const navigate = useNavigate();

  const role_accesses =
    JSON.parse(secureLocalStorage.getItem("role_accesses"))?.find(
      (access) => access.module.name === "Documents"
    )?.access_type !== "Read";

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  const addDocumentCategory = async (payload) => {
    try {
      const res = await postRequest(ADD_DOCUMENT_CATEGORY, payload, navigate);
      if (res.type === 1 && res.response.data.success) {
        setDocumentCategoryValue("");
        successNotification(res.response.data.message);
        setlistShow(false);
        getDocumentCategories();
      } else if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      } else {
        errorNotification(
          res.response.data.message || res.errormessage || res.errors
        );
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  const handleAddCompany = (e) => {
    e.preventDefault();
    let documentCategoryData = {
      name: documentCategoryValue,
    };

    addDocumentCategory(documentCategoryData);
  };

  const handleAdd = () => {
    setDocumentCategoryValue("");
    setlistShow(true);
  };

  useEffect(() => {
    getDocumentCategories();
  }, []);

  const getDocumentCategories = async () => {
    try {
      const res = await getRequest(GET_DOCUMENT_CATEGORY, null, navigate);
      if (res.type === 2) {
        errorNotification(res.errormessage);
      } else {
        setDocumentCategoriesList(res?.data?.data || []);
        if (res?.data?.data?.length > 0 && !activeTab) {
          setActiveTab(res?.data?.data[0]?.name);
        }
        const list = res?.data?.data?.map(item=>item?.name);
        if(!list.includes(activeTab)){
          setActiveTab(res?.data?.data[0]?.name);
        }
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  const removeCategory = async() => {
    try {
      const res = await deleteRequest(`${DELETE_DOCUMENT_CATEGORY}?category_id=${deleteCategoryId}`, null, navigate);
      if (res.type === 1) {
        getDocumentCategories();
        successNotification(res.response.data.message);        
      } else if (res.type === 2) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      errorNotification(error.message);
    }
    handleDeleteCategoryToggle();
  }

  const handleDeleteCategoryToggle = (id) => {
    id ? setDeleteCategoryId(id) : setDeleteCategoryId(null);
    setDeleteCategoryOpen(!deleteCategoryOpen);
  }

  return (
    <div className="category__section bg-[#fff] rounded-[12px] h-full ">
      <div className="category__subsection h-full flex flex-col">

        <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row justify-between">
          {role_accesses && (
            <button
              className="add__category m-3 mb-0"
              onClick={handleAdd}
              disabled={!role_accesses}
            >
              + Add Category
            </button>
          )}
        </div>

        <div className="cursor-pointer flex justify-start items-center m-3 gap-3">
          {documentCategoriesList.length > 0 && (
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              {documentCategoriesList.map((tab) => (
                <li key={tab.id} className="nav-item relative group">
                 {activeTab === tab.name && <div className='absolute rounded-full p-0.5 bg-white top-[-3px] right-[-3px] border-1 hidden group-hover:block cursor-pointer'
                    onClick={() => handleDeleteCategoryToggle(tab.id)}
                  >
                    <RxCross2  />
                  </div>}
                  <button
                    className={`nav-link m-2 nav-link-custom ${activeTab === tab.name ? "nav-link-custom-active" : ""
                      }`}
                    type="button"
                    onClick={() => handleTabChange(tab.name)}
                  >
                    {tab.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="m-3 mt-0 h-[610px] overflow-auto grow overflow-y-auto">
          <div className="tab-content h-full" id="pills-tabContent">
            {documentCategoriesList?.map((category) => (
              <div
                key={category.id}
                className={`tab-pane fade h-full ${activeTab === category.name ? "show active" : ""
                  }`}
                role="tabpanel"
              >
                {activeTab === category.name && <DocumentData categoryId={category?.id} />}
              </div>
            ))}
          </div>
        </div>

        <Modal
          className="category-modal "
          title="Add Document category"
          onClose={() => {
            setlistShow(false);
          }}
          show={listshow}
        >
          <div className="modal-body add__category__form">
            <div>
              <h6 className="modal-heading mt-2">Document category</h6>
              <input
                placeholder="add document category"
                className="category__name text-capitalize "
                type="text"
                value={documentCategoryValue}
                autoComplete="off"
                onChange={(e) => {
                  setDocumentCategoryValue(e.target.value);
                }}
              />
            </div>
            <div className="add__close__section mt-3">
              {role_accesses && (
                <button
                  type="button"
                  className="category__save__btn"
                  onClick={handleAddCompany}
                  disabled={!role_accesses}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </Modal>
      </div>
      
      <NewModal
        title="Are you Sure?"
        onClose={() => handleDeleteCategoryToggle()}
        show={deleteCategoryOpen}
      >
        <div className="modal-body add__cmp__form">
          <h6>Do you really want to delete this category?</h6>
          <div className="add__close__section">
            <button
              type="button"
              className="bg-[#F5F5F5] text-[#5D5D5D] w-[fit-content] px-4 py-2 rounded-[12px] me-3"
              onClick={() => handleDeleteCategoryToggle()}
            >
              No
            </button>
            <button
              type="button"
              className="bg-[#f47e53] text-[#fff] border-[1px] flex items-center  w-[fit-content] px-3 py-2 rounded-[12px] whitespace-nowrap"
              onClick={removeCategory}
            >
              Yes
            </button>
          </div>
        </div>
      </NewModal>
    </div>
  );
};

export default Document;
