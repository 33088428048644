// import React, { useState } from 'react';
// import Modal from "../Modal/Modal";
// import Select from "react-select";
// import { Col, Form, Row } from 'react-bootstrap';
// import bin from "../../../components/assets/bin.svg";
// import "./Milestone.css";


// const Milestone = ({ projectId }) => {
//     const [listShow, setListShow] = useState(false);
//     const [selectedCountry, setSelectedCountry] = useState(null);
//     const [tasks, setTasks] = useState([{ task: '', hours: '' }]);
//     const [errors, setErrors] = useState([]);

//     const taskss = [
//         {
//             milestone: "Onboarding & Authentication",
//             subTasks: [
//                 { name: "Home Page with add filter", hours: 20, Comment: "ashfsjh" },
//                 { name: "Product Selection", hours: 20, Comment: "ashfsjh" },
//                 { name: "Login", hours: 20, Comment: "ashfsjh" }
//             ]
//         },
//         {
//             milestone: "Onboarding & Authentication",
//             subTasks: [
//                 { name: "Home Page with add filter", hours: 20, Comment: "hsakjfks jsfakjs jasfj " },
//                 { name: "Product Selection", hours: 20, Comment: "skajf bajsfjsa sav " },
//                 { name: "Login", hours: 20, Comment: "sjbjsa asfjas nsafl ls " }
//             ]
//         },
//         {
//             milestone: "Onboarding & Authentication",
//             subTasks: [
//                 { name: "Home Page with add filter", hours: 20, Comment: "safnslk nlkkaf knaks aka " },
//                 { name: "Product Selection", hours: 20, Comment: "nslknacl nasnla kasnslk " },
//                 { name: "Login", hours: 20, Comment: "nasncsak n snkncsa kla knlafk lska  " }
//             ]
//         },
//     ];

//     const totalHours = taskss.reduce((total, task) => {
//         return total + task.subTasks.reduce((subTotal, subTask) => subTotal + subTask.hours, 0);
//     }, 0);

//     const countryArray = [
//         "Project-1", "Project-2", "Project-3", "Project-4", "Project-5",
//     ];

//     const countryOptions = countryArray.map((country) => ({
//         label: country,
//         value: country,
//     }));

//     const adjustTextareaHeight = (textarea) => {
//         textarea.style.height = 'auto';
//         textarea.style.height = `${textarea.scrollHeight}px`;
//     };

//     const handleTaskChange = (index, event) => {
//         const values = [...tasks];
//         const { name, value } = event.target;

//         if (name === 'hours') {
//             const formattedValue = formatTimeInput(value);
//             values[index][name] = formattedValue;
//             const isValid = /^([01]\d|2[0-3]):([0-5]\d)$/.test(formattedValue);
//             const newErrors = [...errors];
//             newErrors[index] = !isValid;
//             setErrors(newErrors);
//         } else {
//             values[index][name] = value;
//         }
//         setTasks(values);
//         adjustTextareaHeight(event.target);
//     };

//     const handleRemoveTask = (index) => {
//         const values = [...tasks];
//         values.splice(index, 1);
//         setTasks(values);

//         const newErrors = [...errors];
//         newErrors.splice(index, 1);
//         setErrors(newErrors);
//     };

//     const handleAddTask = () => {
//         setTasks([...tasks, { task: '', hours: '' }]);
//         setErrors([...errors, false]);
//     };

//     const formatTimeInput = (value) => {
//         const cleanedValue = value.replace(/[^0-9]/g, '');
//         if (cleanedValue.length <= 2) {
//             return cleanedValue;
//         }
//         if (cleanedValue.length <= 4) {
//             return `${cleanedValue.slice(0, 2)}:${cleanedValue.slice(2)}`;
//         }
//         return `${cleanedValue.slice(0, 2)}:${cleanedValue.slice(2, 4)}`;
//     };

//     const handleAdd = () => {
//         setListShow(true);
//     };

//     return (
//         <div className="p-4">
//             <div>
//                 <button
//                     className="client__save__btn mx-[11px]"
//                     onClick={handleAdd}
//                 >
//                     + Add Milestone
//                 </button>
//                 <div className="container mx-auto my-3">
//                     <table className="min-w-full bg-white border-0">
//                         <thead>
//                             <tr className='bg-[#F4F4F4]  '>
//                                 <th className="py-2 px-4 border-1 border-[#F4F4F4] text-[#6F767E]">Task</th>
//                                 <th className="py-2 px-4 border-1 border-[#F4F4F4] text-[#6F767E]">Hours</th>
//                                 <th className="py-2 px-4 border-1 border-[#F4F4F4] text-[#6F767E]">Comment</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {taskss.map((task, index) => (
//                                 <React.Fragment key={index}>
//                                     <tr>
//                                         <td className="py-2 px-4 border-1 border-[#F4F4F4]  text-base font-medium  " colSpan="3">
//                                             Milestone
//                                         </td>
//                                     </tr>
//                                     <tr>
//                                         <td className="py-2 px-4 border-1 border-[#F4F4F4]  text-base font-semibold ">{task.milestone}</td>
//                                         <td className="py-2 px-4 border-1 border-[#F4F4F4]  text-base"></td>
//                                         <td className="py-2 px-4 border-1 border-[#F4F4F4]  text-base"></td>
//                                     </tr>
//                                     {task.subTasks.map((subTask, subIndex) => (
//                                         <tr key={subIndex}>
//                                             <td className="py-2 px-4 border-1 border-[#F4F4F4]  pl-6 text-base text-[#6F767E]">{subTask.name}</td>
//                                             <td className="py-2 px-4 border-1 border-[#F4F4F4]  text-base text-[#6F767E]">{subTask.hours}</td>
//                                             <td className="py-2 px-4 border-1 border-[#F4F4F4]  text-base text-[#6F767E]">{subTask.Comment}</td>
//                                         </tr>
//                                     ))}
//                                 </React.Fragment>
//                             ))}
//                             <tr>
//                                 <td className="py-2 px-4 text-right"><strong>Total hours :</strong></td>
//                                 <td className="py-2 px-4 flex justify-start" colSpan="2"><strong>{totalHours}</strong></td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
//             </div>

//             <Modal
//                 className="client-modal"
//                 title="Add Task"
//                 onClose={() => {
//                     setListShow(false);
//                 }}
//                 show={listShow}
//             >
//                 <Form className="modal-body add__update__form "
//                 // onSubmit={handleSubmit}
//                 >
//                     <h6 className="modal-heading ">Project</h6>
//                     <Select
//                         // options={clientOptions}
//                         className="basic-single text-capitalize add_update"
//                         classNamePrefix="select"
//                         // value={selectedProject}
//                         placeholder="Select Project"
//                         name="name"
//                     // onChange={handleProjectChange}
//                     />

//                     {tasks.map((task, index) => (
//                         <Row key={index} className="my-3 row">
//                             <Col sm={10} md={11}>
//                                 {index === 0 && <Form.Label htmlFor="basic-url  text-left">Task</Form.Label>}
//                                 <textarea
//                                     key={index}
//                                     className="update__name "
//                                     placeholder="Enter task"
//                                     name="task"
//                                     rows={1}
//                                     value={task.task}
//                                     onChange={(event) => handleTaskChange(index, event)}
//                                     style={{ overflow: 'hidden' }}
//                                 />
//                             </Col>
//                             <Col sm={2} md={1} className={"d-flex  justify-between gap-3"}>

//                                 {index !== 0 && (
//                                     <div className='d-flex align-items-center mb-2'>
//                                         <span className='d-flex align-items-center'>
//                                             <img
//                                                 onClick={() => handleRemoveTask(index)}
//                                                 className="w-[15px] h-[18px] object-cover cursor-pointer"
//                                                 src={bin}
//                                                 alt="Delete"
//                                             />
//                                         </span>
//                                     </div>
//                                 )}
//                             </Col>

//                         </Row>
//                     ))}

//                     <div className="mt-4 add-new-client d-flex justify-end  ">
//                         <span className="Add_Task" onClick={handleAddTask}>
//                             + Add Task
//                         </span>
//                     </div>

//                     <div className="add__close__section sticky bottom-0 pb-4 w-full bg-white ">
//                         <button
//                             type="submit"
//                             className="update__save__btn"
//                         >
//                             Submit
//                         </button>
//                     </div>
//                 </Form>
//             </Modal>
//         </div>
//     );
// }

// export default Milestone;

import React from 'react'

const Milestone = () => {

    return (
        <p>Milestone</p>
    )
}

export default Milestone
