import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import Modal from '@mui/material/Modal';
import CustomModal from '../Modal/Modal';
import Select from "react-select";
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import AllTaskList from './AllTaskList';
import validator from 'validator';
import { getRequest, patchRequest, postRequest } from '../../../components/axiosClient';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { FaStop } from "react-icons/fa6";
import { ADD_MANUAL_TASK, GET_MISSED_TASK_TIMER, GET_MY_RUNNING_TASK_TIMER, GET_PROJECT_LIST_FOR_MY_TASK, SEND_MANUAL_TIMER_REQUESTS, SEND_TIMER_REGULATION_REQUEST, START_MY_TASK_TIMER, STOP_MY_TASK_TIMER, UPDATE_USER_TASK } from '../../../components/utils';
import MyCompleteTaskList from './MyCompleteTaskList';
import { AiOutlineClose } from 'react-icons/ai';
import MyTaskRequest from './MyTaskRequest';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { boundaryContext } from '../../../App';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');

const MyTask = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [taskModel, setTaskModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { myTaskActiveRoute, setMyTaskActiveRoute } = useContext(boundaryContext);

    const [activeTab, setActiveTab] = useState((myTaskActiveRoute && myTaskActiveRoute !== "") ? myTaskActiveRoute : "all-task");

    const [newTask, setNewTask] = useState({
        title: '',
        project_id: null
    });

    const [projectList, setProjectList] = useState([]);
    const [secondsElapsed, setSecondsElapsed] = useState(0);
    const [runningTask, setRunningTask] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [modalTaskDetails, setModalTaskDetails] = useState();
    const [requestTaskData, setRequestTaskData] = useState({
        task_id: null,
        startTime: null,
        endTime: null,
        taskDate: '',
        note: ''
    });
    const [modalTitle, setModalTitle] = useState('');
    const [forgetModal, setForgetModal] = useState(false);

    useEffect(() => {
        getRunningTask();
        getProjectList();
    }, [])

    const location = useLocation();
    const fromModal = location.state?.fromModal;

    useEffect(() => {
        if (fromModal) {
            setModalTitle("Requested for time Adjustment");
            setForgetModal(true);
            setTaskModel(true);
            handleModalData();
            navigate(location.pathname, { replace: true, state: { ...location.state, fromModal: false } });
        }
    }, [fromModal]);

    useEffect(() => {
        if (runningTask) {
            setStartTime(runningTask.start_time);
        }
    }, [runningTask])

    useEffect(() => {
        if (startTime) {
            const calculateElapsedTime = () => {
                const [hours, minutes, seconds] = startTime.split(':').map(Number);
                const start = dayjs().tz('Asia/Kolkata').startOf('day')
                    .add(hours, 'hour')
                    .add(minutes, 'minute')
                    .add(seconds, 'second');

                const now = dayjs().tz('Asia/Kolkata');
                if (now.diff(start, 'second') < 0) {
                    setStartTime(dayjs().format('HH:mm:ss'));
                    return now.diff(dayjs(), 'second'); // Adding 1 to include the current second
                }
                return now.diff(start, 'second'); // Adding 1 to include the current second
            };

            const intervalId = setInterval(() => {
                setSecondsElapsed(calculateElapsedTime());
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [startTime]);

    const getRunningTask = async () => {
        try {
            setLoading(true);
            const res = await getRequest(
                `${GET_MY_RUNNING_TASK_TIMER}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const isRunning = res.data.data.is_running;
                if (isRunning) {
                    const data = res.data.data.data;
                    setRunningTask(data);
                }
            } else {
                // errorNotification(res.errormessage);
                setRunningTask(null);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handlePauseTask = () => {
        if (!runningTask) {
            errorNotification("No running task to pause");
            return;
        }
        pauseTask(runningTask);
    }

    const pauseTask = async (prop) => {
        try {
            setLoading(true);
            const res = await postRequest(
                `${STOP_MY_TASK_TIMER}?task_timer_id=${prop.id}`,
                {},
                navigate
            );
            if (res.type === 1) {
                setSecondsElapsed(0);
                setRunningTask(null);
                setStartTime(null);
            } else {
                errorNotification(res.errormessage);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleStartTask = async (task, event) => {
        event.preventDefault();
        if (runningTask) {
            await pauseTask(runningTask);
        }
        startTask(task.id);
    }

    const startTask = async (id) => {
        try {
            setLoading(true);
            const res = await postRequest(
                `${START_MY_TASK_TIMER}?task_id=${id}`,
                {},
                navigate
            );
            if (res.type === 1) {
                // setSecondsElapsed(0);
                getRunningTask();

                // successNotification("Task added successfully");
            } else {
                errorNotification(res.errormessage);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleTaskComplete = async (id, is_completed) => {
        if (id == null || is_completed == null || id == undefined || is_completed == undefined) {
            return;
        }
        const payload = {
            task_id: id,
            is_completed: !is_completed
        }
        // setLoading(true);
        try {
            const res = await patchRequest(
                `${UPDATE_USER_TASK}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                // getTaskList();
            } else {
                // getTaskDetails();
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            console.error(error);
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    const handleRequestModal = (item) => {
        setTaskModel(true);
        setModalTitle("Send request to add manual hours");
        setModalTaskDetails(item);
        setRequestTaskData({
            ...requestTaskData,
            task_id: item.id
        });
    }

    const getProjectList = async () => {
        try {
            setLoading(true);
            const res = await getRequest(
                `${GET_PROJECT_LIST_FOR_MY_TASK}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const dataList = res.data.data.map((item) => {
                    return { value: item?.id, label: item?.name };
                });
                setProjectList(dataList);
            } else {
                errorNotification(res.errormessage);
                projectList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleAddTask = () => {
        if (validator.isEmpty(newTask.title)) {
            errorNotification("Task Title is required");
            return;
        } else if (!newTask.project_id) {
            errorNotification("Project is required");
            return;
        } else {
            setIsOpen(false);
            addManualTask(newTask);
        }
    }

    const addManualTask = async (Project) => {
        try {
            const payload = {};
            if (Project.project_id === "Manual") {
                payload["title"] = Project.title;
                payload["task_type"] = "Manual";  // Project,Manual
            } else {
                payload["title"] = Project.title;
                payload["task_type"] = "Project";  // Project,Manual
                payload["project_id"] = Project.project_id;
            }
            setLoading(true);
            const res = await postRequest(
                `${ADD_MANUAL_TASK}`,
                payload,
                navigate
            );
            if (res.type === 1) {
                successNotification("Task added successfully");
                setNewTask({ title: '', project_id: null });
            } else {
                errorNotification(res.errormessage);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const formatTime = (totalSeconds) => {
        return dayjs.duration(totalSeconds, 'seconds').format('HH:mm:ss');
    };

    const handleTabChange = (e) => {
        setMyTaskActiveRoute(e.target.name);
        setActiveTab(e.target.name);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    }

    function convertToISOFormat(dateString, timeString) {
        const combinedString = `${dateString} ${timeString}`;
        const isoString = dayjs(combinedString).toISOString();
        return isoString;
    }

    const checkDate = (startTime, date) => {
        const result = convertToISOFormat(date, startTime);
        const now = dayjs();
        return dayjs(result).isAfter(now);
    }

    const handleAddRequest = () => {
        if (requestTaskData.startTime === null) {
            errorNotification("Please select start time");
            return;
        } else if (requestTaskData.endTime === null) {
            errorNotification("Please select end time");
            return;
        } else if (requestTaskData.endTime < requestTaskData.startTime) {
            errorNotification("Start time must be greater than end time");
            return;
        } else if (requestTaskData.taskDate === null) {
            errorNotification("Please select task date");
            return;
        } else if (requestTaskData.note === '') {
            errorNotification("Note is required");
            return;
        } else if (checkDate(requestTaskData.startTime, requestTaskData.taskDate)) {
            errorNotification("Start time should not be in the future");
            return;
        } else if (checkDate(requestTaskData.endTime, requestTaskData.taskDate)) {
            errorNotification("End time should not be in the future");
            return;
        }
        if (forgetModal) {
            const updatedRequestData = {
                start_time: dayjs(requestTaskData.startTime, 'HH:mm').format('HH:mm:ss'),
                end_time: dayjs(requestTaskData.endTime, 'HH:mm').format('HH:mm:ss'),
                date: requestTaskData.taskDate,
                task_timer_id: requestTaskData.task_timer_id,
                note: requestTaskData.note
            };

            addForgetTimerRequest(updatedRequestData);
        } else {
            const updatedRequestData = {
                start_time: dayjs(requestTaskData.startTime, 'HH:mm').format('HH:mm:ss'),
                end_time: dayjs(requestTaskData.endTime, 'HH:mm').format('HH:mm:ss'),
                date: requestTaskData.taskDate,
                task_id: requestTaskData.task_id,
                note: requestTaskData.note
            };
            addRequestTask(updatedRequestData);
        }

    }

    const addForgetTimerRequest = async (data) => {
        try {
            setLoading(true);
            const res = await postRequest(
                `${SEND_TIMER_REGULATION_REQUEST}`,
                data,
                navigate
            );
            if (res.type === 1) {
                successNotification(res.response.data.message);
                setTaskModel(false);
                setRequestTaskData({
                    startTime: null,
                    endTime: null,
                    taskDate: null,
                    note: '',
                    task_id: null,
                    task_timer_id: null
                })
                setModalTaskDetails({
                    title: null
                })

            } else {
                errorNotification(res.errormessage);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }


    const addRequestTask = async (data) => {
        try {
            setLoading(true);
            const res = await postRequest(
                `${SEND_MANUAL_TIMER_REQUESTS}`,
                data,
                navigate
            );
            if (res.type === 1) {
                successNotification(res.response.data.message);
                setTaskModel(false);
                setRequestTaskData({
                    startTime: null,
                    endTime: null,
                    taskDate: null,
                    note: '',
                    task_id: null
                })

            } else {
                errorNotification(res.errormessage);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    };


    const handleModalData = async () => {
        try {
            let apiUrl;
            apiUrl = `${GET_MISSED_TASK_TIMER}`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const data = res.data.data;
                if (data.length > 0) {
                    setRequestTaskData({
                        task_timer_id: data[0].id,
                        startTime: dayjs(data[0].start_time, 'HH:mm:ss').format('HH:mm'),
                        taskDate: data[0].date,
                        endTime: null,
                        note: ''
                    })
                    setModalTaskDetails({
                        ...modalTaskDetails,
                        title: data[0].task.title
                    })
                }
            }
        }
        catch (error) {
            console.error("Error fetching tracker data:", error);
        }
    }
    const today = new Date().toISOString().split("T")[0];


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='p-4 bg-white h-full flex flex-col rounded-xl'>
            <div className='w-full flex flex-row justify-between items-center px-3 py-2 border rounded-xl mb-3'>
                {
                    runningTask ?
                        <>
                            <span>{runningTask?.task.title}</span>
                            <div className='flex flex-row gap-8 items-center pl-8 border-l-2'>
                                <span className='text-[#f47e53]'>{runningTask?.task.project?.name || "--"}</span>
                                <span className='text-2xl w-[100px]'>{formatTime(secondsElapsed)}</span>
                                <span className='flex flex-row items-center'>
                                    <button type="button" className='border p-2 rounded-lg mr-2 flex justify-center items-center gap-1 hover:!border-black w-[100px]'
                                        onClick={() => handlePauseTask()}
                                    >
                                        <FaStop /> <p className='text-base'>Stop</p>
                                    </button>
                                </span>
                            </div>
                        </>

                        :
                        <button className='border-0 text-[rgba(111, 118, 126, 1)] rounded-3xl py-2.5 px-[16px] font-medium bg-[#F4F4F4]'
                            onClick={() => handleOpenModal()}
                        >
                            + Add Task
                        </button>
                }

            </div>

            {/*  4 Tab view code  */}
            <div className='flex flex-col justify-start gap-4'>
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link font-medium !text-[#6F767E] ${activeTab === "all-task" ? "active" : ""}`}
                            id="pills-all-task-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-all-task"
                            type="button"
                            role="tab"
                            aria-controls="pills-all-task"
                            aria-selected="true"
                            onClick={(e) => handleTabChange(e)}
                            name="all-task"
                        >
                            All Tasks
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link font-medium !text-[#6F767E] ${activeTab === "Complete" ? "active" : ""}`}
                            id="pills-Complete-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Complete"
                            type="button"
                            role="tab"
                            aria-controls="pills-Complete"
                            aria-selected="false"
                            name="Complete"
                            onClick={(e) => handleTabChange(e)}
                        >
                            Completed
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link font-medium !text-[#6F767E] ${activeTab === "Requested" ? "active" : ""}`}
                            id="pills-Requested-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Requested"
                            type="button"
                            role="tab"
                            aria-controls="pills-Requested"
                            aria-selected="false"
                            name="Requested"
                            onClick={(e) => handleTabChange(e)}
                        >
                            Requested
                        </button>
                    </li>
                </ul>
            </div>

            <div className="grow overflow-y-auto  program_scroll">
                <div className="h-full tab-content" id="pills-tabContent">
                    <div
                        className={`h-full tab-pane fade ${activeTab === "all-task" ? "show active" : ""
                            }`}
                        id="pills-all-task"
                        role="tabpanel"
                        aria-labelledby="pills-all-task-tab"
                    >
                        <div className='h-full'>
                            {
                                activeTab === "all-task" &&
                                <AllTaskList onStart={handleStartTask}
                                    handlePause={handlePauseTask}
                                    runningTask={runningTask}
                                    handleRequestModal={handleRequestModal}
                                    projectList={projectList}
                                    handleTaskComplete={handleTaskComplete}
                                />
                            }
                        </div>
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "Complete" ? "show active" : ""
                            }`}
                        id="pills-Complete"
                        role="tabpanel"
                        aria-labelledby="pills-Complete-tab"
                    >
                        {activeTab === "Complete" &&
                            <MyCompleteTaskList handleTaskComplete={handleTaskComplete}
                                onStart={handleStartTask}
                                handlePause={handlePauseTask}
                                runningTask={runningTask}
                                handleRequestModal={handleRequestModal}

                            />}
                    </div>
                    <div
                        className={`h-full tab-pane fade ${activeTab === "Requested" ? "show active" : ""
                            }`}
                        id="pills-Requested"
                        role="tabpanel"
                        aria-labelledby="pills-Requested-tab"
                    >
                        {activeTab === "Requested" && <MyTaskRequest />}
                    </div>
                </div>
            </div>

            <CustomModal
                className="min-w-[60vw] task-modal"
                title="Add task"
                onClose={() => setIsOpen(false)}
                show={isOpen}
            >
                <div className='modal-body flex flex-col gap-4' >
                    <Row className=''>
                        <Col xs={12}>
                            <h6 className="mb-2">Task</h6>
                            <input
                                placeholder="Task"
                                className="h-10 w-full rounded-lg border p-[6px]"
                                type="text"
                                name="task"
                                value={newTask.title}
                                autoComplete="off"
                                onChange={(e) => {
                                    setNewTask({ ...newTask, title: e.target.value });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <h6 className="mb-2">Project</h6>
                            <Select className="project__name text-capitalize"
                                options={projectList}
                                value={projectList.find(option => option.value === newTask.project_id)}
                                onChange={(selectedOption) => {
                                    setNewTask({ ...newTask, project_id: selectedOption ? selectedOption.value : null })
                                }}
                            />
                        </Col>
                        <Col xs={8}>
                            <div className='flex flex-row justify-end items-end pl-4 h-full'>
                                <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                                    onClick={handleAddTask}
                                >Save</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CustomModal>

            <Modal
                open={taskModel}
                onClose={() => setTaskModel(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='h-full w-full flex flex-col justify-center items-center '>
                    <div className='bg-white w-[80%] lg:w-[1000px] rounded'>

                        <div className="modal-header sticky top-0 z-10 ">
                            <h4 className="modal-title">{modalTitle ? modalTitle : "title"}</h4>   <AiOutlineClose
                                type="button"
                                onClick={() => setTaskModel(false)}
                            />
                        </div>
                        <div className='bg-white flex flex-col gap-4 p-4 rounded' >
                            <Row className=''>
                                <Col xs={12}>
                                    <h6 className="mb-2">Task</h6>
                                    <input
                                        placeholder="Task"
                                        className="h-10 w-full rounded-lg border p-[6px]"
                                        type="text"
                                        name="task"
                                        readOnly
                                        value={modalTaskDetails?.title}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xs={4}>
                                    <h6 className="mb-2">Start Time</h6>
                                    <input
                                        placeholder="Task"
                                        className="h-10 w-full rounded-lg border p-[6px] text-capitalize"
                                        type="time"
                                        name="task"
                                        value={requestTaskData.startTime}
                                        autoComplete="off"
                                        max={requestTaskData.endTime || "23:59"}  // Set the max value to endTime or default to the end of the day
                                        onChange={(e) => {
                                            const startTime = e.target.value;
                                            setRequestTaskData((prevData) => ({
                                                ...prevData,
                                                startTime,
                                                // endTime: prevData.endTime && prevData.endTime < startTime ? "" : prevData.endTime // Reset endTime if it becomes invalid
                                            }));
                                        }}
                                        readOnly={setForgetModal}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <h6 className="mb-2">End Time</h6>
                                    <input
                                        placeholder="Task"
                                        className="h-10 w-full rounded-lg border p-[6px] text-capitalize"
                                        type="time"
                                        name="task"
                                        value={requestTaskData.endTime}
                                        autoComplete="off"
                                        min={requestTaskData.startTime || "00:00"}  // Set the min value to startTime or default to the start of the day
                                        onChange={(e) => {
                                            const endTime = e.target.value;
                                            setRequestTaskData((prevData) => ({
                                                ...prevData,
                                                endTime,
                                                // startTime: prevData.startTime && prevData.startTime > endTime ? "" : prevData.startTime // Reset startTime if it becomes invalid
                                            }));
                                        }}
                                    />
                                </Col>

                                <Col xs={4}>
                                    <h6 className="mb-2">Task Date</h6>
                                    <input
                                        placeholder="Task"
                                        className="h-10 w-full rounded-lg border p-[6px]"
                                        type="date"
                                        name="task"
                                        value={requestTaskData.taskDate}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setRequestTaskData({ ...requestTaskData, taskDate: e.target.value });
                                        }}
                                        max={today}
                                        readOnly={setForgetModal}
                                    />
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xs={12}>
                                    <h6 className="mb-2">Note</h6>
                                    <textarea
                                        placeholder="Enter Note"
                                        className="h-10 w-full rounded-lg border p-[6px] min-h-28"
                                        type="text"
                                        name="task"
                                        value={requestTaskData.note}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setRequestTaskData({ ...requestTaskData, note: e.target.value });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xs={12}>
                                    <div className='flex flex-row justify-end items-end pl-4 h-full'>
                                        <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                                            onClick={() => {
                                                handleAddRequest();
                                            }}
                                        >Send</button>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>

            </Modal>
            <style>
                {`
                .task-modal .modal-content{
                    overflow-y: unset
                }
            `}
            </style>
        </div>

    )
}
export default MyTask