import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GoGrabber } from "react-icons/go";
import { IoIosCheckmark } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import { patchRequest } from '../../../components/axiosClient';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { MY_TRAINING_UPDATE_TASK, MY_TRAINING_UPDATE_TASK_INDEX, TRAINEE_UPDATE_TASK, TRAINEE_UPDATE_TASK_INDEX } from '../../../components/utils';

const TrainingTaskListView = ({ projectLeadList, getTasks, handleTaskComplete, handleView, isTrainee, statusList }) => {

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState();
    const navigate = useNavigate();
    const rowRef = useRef();

    useEffect(() => {
        getProjectLeads();
    }, [projectLeadList, statusList]);


    const getProjectLeads = async () => {
        // const fetchedTasks = await fetchTasks();
            const data =  statusList?.reduce((acc, item) => {
                acc[item.id] = {
                    title: item.title,
                    items: projectLeadList?.filter(task => task?.task_status?.title === item?.title) || []
                };
                return acc;
            }, {});
            setRows(data);
    };

    const updateProjectLeadStatus = async (payload) => {
        setLoading(true);
        try {
            const res = await patchRequest(
                `${isTrainee ? MY_TRAINING_UPDATE_TASK : TRAINEE_UPDATE_TASK}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                return true;
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    const updateProjectLeadIndex = async (data) => {
        const payload = {
            tasks: data
        }
        setLoading(true);
        try {
            const res = await patchRequest(
                `${isTrainee ? MY_TRAINING_UPDATE_TASK_INDEX : TRAINEE_UPDATE_TASK_INDEX}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }

    }

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };

    const onDragEnd = async (result) => {

        if (!result.destination) return;

        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = rows[source.droppableId];
            const destColumn = rows[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setRows({
                ...rows,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
            const updatedColumnsData = {
                ...rows,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            }

            const updatedTask = {
                task_id: removed.id.toString(),
                status_id: statusList?.filter(task => task?.title == rows[destination.droppableId].title)[0]?.id,
            }
            const res = await updateProjectLeadStatus(updatedTask);
            if (res) {
                const transformedData = Object.keys(updatedColumnsData).map((key) => {
                    const statusObj = updatedColumnsData[key];
                    return {
                        title: statusObj.title || statusObj.items[0]?.task_status?.title, // Use title or extract from items
                        taskIds: statusObj.items.map((lead) => lead.id), // Create array of lead IDs
                    };
                });
                await updateProjectLeadIndex(transformedData);
                await getTasks();
            }
        } else {
            // Handle moving within the same column
            const column = rows[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setRows({
                ...rows,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
            const updatedColumnsData = {
                ...rows,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            }
            const transformedData = Object.keys(updatedColumnsData).map((key) => {
                const statusObj = updatedColumnsData[key];
                return {
                    title: statusObj.title || statusObj.items[0]?.task_status?.title, // Use title or extract from items
                    taskIds: statusObj.items.map((lead) => lead.id), // Create array of lead IDs
                };
            });
            await updateProjectLeadIndex(transformedData);
            await getTasks();
        }

    };

    return (
        <div className="px-2 h-[calc(100vh_-_270px)] xl:h-[calc(100vh_-_270px)] overflow-y-auto w-full min-w-[1000px] overflow-x-auto">
            {(projectLeadList?.length === 0 || (rows === null || rows === undefined)) ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="flex flex-col min-h-[500px] gap-2 w-full">
                        {/* Common Header */}
                        <div className="w-full grid grid-cols-8 border-b-[#EFEFEF] font-medium p-2" ref={rowRef}>
                            <div className="col-span-5">Task</div>
                            <div className="col-span-2">Due Date</div>
                            <div className="col-span-1">Priority</div>
                        </div>

                        {Object.entries(rows).map(([columnId, column]) => (
                            <Droppable key={columnId} droppableId={columnId}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <div

                                            className={`${column?.title === "In Progress"
                                                ? "text-[#db763b] bg-[#eeb08c3d] mt-3"
                                                : column?.title === "Completed"
                                                    ? "text-[#83BF6E] bg-[#83bf6e24] mt-3"
                                                    : column?.title === "To be verified"
                                                        ? "text-[#8E59FF] bg-[#8e59ff1a] mt-3"
                                                        : column?.title === "To do"
                                                            ? "text-[#6F767E] bg-[#6f767e2b]"
                                                            : "text-[#6F767E] bg-[#6f767e2b]"
                                                } px-[10px] py-[2px] rounded self-start ${column.items.length === 0 ? "mb-4" : ""
                                                }`}
                                        >
                                            {column.title}
                                        </div>

                                        <div className="w-full grid">
                                            {column.items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                                    {(provided, snapshot) => {
                                                        const draggableStyle = {
                                                            ...provided.draggableProps.style,
                                                            width: rowRef.current ? `${rowRef.current.offsetWidth}px` : 'auto',
                                                        };

                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={`grid grid-cols-8 px-2 py-1 ${snapshot.isDragging ? "shadow-lg" : ""} ${item?.member_removed == "true" ? 'bg-[#f7f7f7]' : 'bg-white'} ${item?.is_completed ? 'opacity-50' : 'opacity-100'} hover:bg-[#f7f7f7] cursor-pointer border-b border-[#EFEFEF] `}
                                                                style={draggableStyle}
                                                                {...provided.draggableProps}
                                                                onClick={() => {
                                                                    if (window.getSelection().toString() === '') {
                                                                        handleView(item.id);
                                                                    }
                                                                }}
                                                            >
                                                                {/* Task */}
                                                                <div className="col-span-5 flex items-center gap-2 w-full pr-4">
                                                                    <div
                                                                        className="drag-handle cursor-grab mr-1"
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <GoGrabber />
                                                                    </div>
                                                                    <div className={`rounded-full border flex items-center ${!item?.is_completed ? 'bg-white text-black' : 'bg-green-600 text-white'} cursor-pointer`}
                                                                        onClick={async (e) => {
                                                                            e.stopPropagation();
                                                                            await handleTaskComplete(item.id, item?.is_completed);
                                                                            await getTasks();
                                                                        }}
                                                                    >
                                                                        <IoIosCheckmark />
                                                                    </div>
                                                                    <div className="truncate">{item.title}</div>
                                                                </div>
                                                                <div className="col-span-2 pr-4 flex items-center">
                                                                    {item?.due_date ? dayjs(item.due_date).format("DD-MM-YYYY") : "--"}
                                                                </div>
                                                                <div className="col-span-1 flex items-center">
                                                                    {item?.priority ? (
                                                                        <span className="flex flex-row gap-1 items-center">
                                                                            {customIcons[item?.priority]} {item?.priority}
                                                                        </span>
                                                                    ) : '--'}
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                        </div>


                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </DragDropContext>
            )}
        </div>
    );
};


export default TrainingTaskListView