import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GET_PROJECT_DAILY_UPDATES, GET_PROJECT_MEMBER_DROPDOWN_LIST } from '../../../components/utils';
import { getRequest, patchRequest } from '../../../components/axiosClient';
import { useNavigate } from 'react-router-dom';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import dayjs from "dayjs";
import Select from "react-select";
import clock_active from "../../../components/assets/clock_active.svg"
import clock_inActive from "../../../components/assets/clock_inActive.svg"
import "./Update.css";
import secureLocalStorage from 'react-secure-storage';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import CommonDatePicker from '../../CommonDatePicker';

const Updates = ({ projectId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [pageInformation, setPageInformation] = useState({});
    const [memberList, setMemberList] = useState([]);
    const [selectedMember, setSelectedMember] = useState({ label: "All", value: "" });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();
    const [hasMoreData, setHasMoreData] = useState(true);
    const sheetRef = useRef();
    const [totalHours, setTotalHours] = useState(0);

    const getAllDailyUpdate = async () => {
        try {
            setLoading(true);
            const url = `${GET_PROJECT_DAILY_UPDATES}?${page ? "page=" + 1 + "&" : ""}${pageSize ? "limit=" + pageSize + "&" : ""}project_id=${projectId}&${startDate ? "start_date=" + startDate + "&" : ""}${endDate ? "end_date=" + endDate + "&" : ""}${selectedMember.value ? "user_id=" + selectedMember.value : ""}`;
            const res = await getRequest(url, null, navigate);
            if (res.type === 2) {
                errorNotification(res.errormessage);
                navigate("/404");
            }
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setData(res.data.data.page_data);
                setTotalHours(res.data.data.totalTrackerDuration);
                setPageInformation(res.data.data.page_information);
                setPage(2);
            } else {
                setHasMoreData(false);
                errorNotification("No data found");
            }
            setLoading(false);
        } catch (error) {
            errorNotification(error.message);
            setLoading(false);
        }
    };


    const fetchData = useCallback(async () => {
        if (isLoading || pageInformation.current_page >= pageInformation.last_page) return;

        setIsLoading(true);
        try {
            const url = `${GET_PROJECT_DAILY_UPDATES}?${page ? "page=" + page + "&" : ""}${pageSize ? "limit=" + pageSize + "&" : ""}project_id=${projectId}&${startDate ? "start_date=" + startDate + "&" : ""}${endDate ? "end_date=" + endDate + "&" : ""}${selectedMember.value ? "user_id=" + selectedMember.value : ""}`;
            const res = await getRequest(url, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data;
                setData((preData) => [...preData, ...newPageData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);
            }

            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false); // No more data available
            }

            setIsLoading(false);
        } catch (error) {
            errorNotification(error);
            setIsLoading(false);
        }
    }, [page, isLoading, pageInformation, pageSize, projectId, startDate, endDate, selectedMember, navigate]);

    useEffect(() => {
        const handleScroll = () => {
            if (sheetRef.current) {
                const { scrollTop, clientHeight, scrollHeight } = sheetRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 50) {
                    fetchData();
                }
            }
        };

        const divElement = sheetRef.current;
        if (divElement) {
            divElement.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (divElement) {
                divElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [fetchData, sheetRef]);

    const getProjectOptions = async () => {
        try {
            const url = `${GET_PROJECT_MEMBER_DROPDOWN_LIST}?project_id=${projectId}`;
            const res = await getRequest(url, null, navigate);
            if (res.data && Array.isArray(res.data.data)) {
                const options = res.data.data.map((project) => ({
                    label: `${project?.user?.first_name} ${project?.user?.last_name}`,
                    value: project?.user?.id,
                }));
                setMemberList([{ label: "All", value: "" }, ...options]);
            } else {
                setMemberList([]);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    };

    useEffect(() => {
        getProjectOptions();
    }, []);

    useEffect(() => {
        getAllDailyUpdate();
    }, [startDate, endDate, selectedMember]);

    const dataFormat = (date) => {
        const nowDate = dayjs();
        if (nowDate.diff(date, "hour") < 24) {
            return "Today";
        } else if (nowDate.diff(date, "day") === 1) {
            return "Yesterday";
        } else if (nowDate.diff(date, "day") > 1) {
            return `${dayjs(date).format("DD MMM YYYY")}`;
        }
    };

    const convertSecondsToHH = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='hunter_audit_dashboard h-full flex flex-col'>
            <div className="h-auto">
                <div className="px-3 flex flex-wrap space-x-2 justify-between">
                    <div className=' pt-[6px] mr-3'>
                        <div className='flex flex-row justify-start items-center gap-1'>
                            <p className='text-[#F37D53] text-base'>Total Hours : </p>
                            <img src={clock_inActive} />
                            {convertSecondsToHH(totalHours)}
                        </div>
                    </div>
                    <div className='flex flex-wrap space-x-2 justify-end'>
                        <div className="w-full sm:w-auto">
                            <Select
                                options={memberList}
                                className="basic-single Updates_name text-capitalize "
                                classNamePrefix="select"
                                placeholder="Members"
                                name="filterhighlow"
                                value={selectedMember}
                                onChange={(selectedOption) => setSelectedMember(selectedOption)}
                            />
                        </div>
                        <div className="w-full sm:w-auto">
                            <input
                                className="form-control w-full"
                                type="date"
                                value={startDate}
                                autoComplete="off"
                                onChange={(e) => setStartDate(e.target.value)}
                            // webkitdirectory
                            />
                            {/* <CommonDatePicker
                                value={startDate}
                                onChange={(value) => setStartDate(value)}
                            /> */}
                        </div>
                        <p className='mt-2'>To</p>
                        <div className="w-full sm:w-auto">
                            <input
                                className="form-control w-full"
                                type="date"
                                value={endDate}
                                autoComplete="off"
                                onChange={(e) => setEndDate(e.target.value)}
                            // webkitdirectory
                            />
                            {/* <CommonDatePicker
                                value={endDate}
                                onChange={(value) => setEndDate(value)}
                            /> */}
                        </div>
                    </div>

                </div>
            </div>


            <div className="daily_updates_section bg-white rounded-[12px] px-3 pb-3 pt-0 grow overflow-y-auto" ref={sheetRef}>
                {
                    data.map((item, index) => {

                        // Grouping tasks by employeeId
                        const groupedByEmployee = item.tasks.reduce((acc, task) => {
                            const employeeId = task.user.id;
                            if (!acc[employeeId]) {
                                acc[employeeId] = [];
                            }
                            acc[employeeId].push(task);
                            return acc;
                        }, {});

                        return (
                            <div key={index}>
                                <div className="daily_updates_date text-left text-lg mb-3 font-medium">
                                    {dataFormat(item?.date)}
                                </div>
                                {
                                    Object.keys(groupedByEmployee).map((employeeId, empIndex) => {
                                        let taskNumber = 1;
                                        let totalSeconds = groupedByEmployee[employeeId].reduce((acc, task) => acc + parseInt(task.duration), 0);

                                        const firstTask = groupedByEmployee[employeeId][0];
                                        const employeeName = `${firstTask?.user?.first_name} ${firstTask?.user?.last_name}`;

                                        return (
                                            <div className="rounded-lg" key={empIndex}>
                                                <table className="w-full border-transparent bg-transparent rounded-lg !overflow-hidden mb-4">
                                                    <thead>
                                                        <tr className="bg-[#F4F4F4]">
                                                            <th className={`w-[74%] font-medium px-4 py-2 border-t-2 border-b-2 border-l-2 border-[#F4F4F4] text-[#000] rounded-tl-lg `}>
                                                                {employeeName || "--"}
                                                            </th>
                                                            {/* <th className="w-1/4 px-4 py-2 border-t-2 border-b-2 border-[#F4F4F4] text-[#6F767E] font-medium">Project Name</th> */}
                                                            <th className={`w-[26%] font-medium px-4 py-2 border-t-2 border-b-2 border-r-2 border-[#F4F4F4] rounded-tr-lg  text-[#F37D53] `}>
                                                                <div className="flex flex-row justify-end items-center gap-1.5">
                                                                    <img src={clock_active} />
                                                                    <span className='w-[47px] flex justify-start'>
                                                                        {convertSecondsToHH(totalSeconds)}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-transparent">
                                                        {groupedByEmployee[employeeId]?.map((task, taskIndex) => {
                                                            let isLastRow = taskIndex === groupedByEmployee[employeeId].length - 1;
                                                            return (
                                                                <tr className="text-base bg-transparent" key={taskIndex + task.task.id}>
                                                                    <td className={`px-4 py-2.5 border-b-2 border-l-2 font-normal ${isLastRow ? 'rounded-bl-[8px]' : ''} border-[#F4F4F4] text-[#000]  `}>
                                                                        <div className="flex flex-row justify-start  items-start gap-2.5">
                                                                            <span>{taskNumber++}</span> <span>{task?.task?.title}</span>
                                                                        </div>
                                                                    </td>

                                                                    <td className="px-4 py-2 border-b-2 border-r-2  border-[#F4F4F4] ${isLastRow ? 'rounded-br-[8px]' : ''}">
                                                                        <div className='flex flex-row justify-end gap-10 items-center '>
                                                                            <div className="flex flex-row justify-start items-center gap-1.5">
                                                                                <img src={clock_inActive} />
                                                                                <span className='w-[47px] flex justify-start'>{convertSecondsToHH(task?.duration)}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    })
                }

                {
                    isLoading && <div>
                        <div className="flex justify-center items-center h-[100px]">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}

export default Updates;
