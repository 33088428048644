import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { CgDetailsMore } from "react-icons/cg";
import { TbListDetails } from "react-icons/tb";
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import EmployeeTimeSheetDetailView from './EmployeeTimeSheetDetailView';
import EmployeeTimeSheetNormalView from './EmployeeTimeSheetNormalView';

const EmployeeDailyUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [detailView, setDetailView] = useState(false);

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='my_team_time_sheet flex flex-col h-full px-3 pb-3'>
            <div className='flex flex-row justify-end px-3'>
                <Tooltip title={detailView ? 'Logs' : 'Timers'}>
                    <button onClick={() => setDetailView(!detailView)} className='border-1 border-[#cccccc] py-[7px] px-2 rounded-md'>
                        {detailView ? <TbListDetails /> : <CgDetailsMore />}
                    </button>
                </Tooltip>
            </div>

            <div className="grow overflow-y-auto">
                {
                    detailView ?
                        <EmployeeTimeSheetDetailView />
                        : <EmployeeTimeSheetNormalView />
                }
            </div>
        </div>
    );
}
export default EmployeeDailyUpdate;
