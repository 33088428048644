import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

const CommonDatePicker = ({ value, onChange }) => {

    const [dateValue, setDateValue] = useState();
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setDateValue(value);
    }, []);

console.log("dateValue", dateValue, value);


    const handleDateChange = (newValue) => {
        setDateValue(newValue);
        
        if (isValid === true && newValue && dayjs(newValue).isValid()) {
            const formattedDate =  dayjs(newValue).format('YYYY-MM-DD');
            onChange(formattedDate);
        }else{
            onChange(null);
        }
    };
    const handleDateError = (error) => {
        setIsValid(!error);
        if (error) {
            return;
        }
        if(dateValue && dayjs(dateValue).isValid()){
            const formattedDate = dayjs(dateValue).format('YYYY-MM-DD');
            onChange(formattedDate);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                slotProps={{
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                toolbarPlaceholder="Please enter date"
                className="h-[40px] date-picker rounded-lg w-full"
                id="date-picker"
                value={dateValue}
                onError={handleDateError}
                onChange={handleDateChange}
                format="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    )
}

export default CommonDatePicker